"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var images_1 = require("../../../../constants/images");
var map_constants_1 = require("../../../../constants/map_constants");
var array_helpers_1 = require("../../../../utils/array_helpers");
var heat_map_api_service_1 = require("../heat_map_api_service");
var fesCount = 0;
var ViewPolylineRoute = function (_a) {
    var assignedVisitData = _a.assignedVisitData, unassignedVisitData = _a.unassignedVisitData, errorMessage = _a.errorMessage, selectedFEId = _a.selectedFEId, setRoundColors = _a.setRoundColors;
    var mapRef = React.useRef(null);
    var _b = React.useState([]), roundinfoColors = _b[0], setRoundInfoColors = _b[1];
    var callAssignedVisits = function (map, ui) {
        var group = new H.map.Group();
        var singleFEVisitCount = 0;
        setRoundInfoColors([]);
        var selectedVisitList = (0, array_helpers_1.filterSelectedVisitList)(assignedVisitData, selectedFEId);
        console.log(selectedVisitList, 'selectedVisitList');
        selectedVisitList.map(function (element, index) {
            var lineString = new H.geo.LineString();
            var markerGroup = [];
            singleFEVisitCount++;
            fesCount = index >= 1 ? index - index : fesCount;
            element.visites &&
                element.visites.map(function (visitElementes, visitIndex) {
                    try {
                        var lat = fesCount >= 1
                            ? index - index
                            : fesCount == visitIndex
                                ? selectedVisitList[index].fe_current_location.latitude
                                : element.visites[visitIndex - 1].latitude;
                        var lng = fesCount >= 1
                            ? index - index
                            : fesCount == visitIndex
                                ? selectedVisitList[index].fe_current_location.longitude
                                : element.visites[visitIndex - 1].longitude;
                        console.log(lat, lng, 'latlonginfo');
                        var colorCode_1 = map_constants_1.colorCodes[singleFEVisitCount];
                        var startEndInfo = {
                            startPointInfo: fesCount >= 1
                                ? index - index
                                : fesCount == visitIndex
                                    ? selectedVisitList[index]
                                    : element.visites[visitIndex - 1],
                            endPointInfo: element.visites[visitIndex],
                        };
                        console.log(startEndInfo, 'locationInfo');
                        var styleLine = {
                            style: {
                                lineWidth: 6,
                                fillColor: 'white',
                                strokeColor: map_constants_1.colorCodes[singleFEVisitCount],
                                lineTailCap: 'arrow-tail',
                                lineDash: [1, 1],
                                lineHeadCap: 'arrow-head',
                            },
                            data: {
                                id: element.user_id,
                                lat: visitIndex == 0
                                    ? element.fe_current_location.latitude
                                    : element.visites[visitIndex].latitude,
                                lng: visitIndex == 0
                                    ? element.fe_current_location.longitude
                                    : element.visites[visitIndex].longitude,
                                scheduled_time: element.visites[visitIndex].scheduled_time,
                                fe_id: element.fe_code,
                                visit_type: visitIndex == 0
                                    ? element.visit_type
                                    : element.visites[visitIndex].visit_type,
                                feName: element.fe_name,
                                base_location_address: element.base_location_address,
                                startLocation: startEndInfo.startPointInfo.center_name,
                                endLocation: startEndInfo.endPointInfo.center_name,
                            },
                        };
                        setRoundInfoColors(function (prev) { return __spreadArray(__spreadArray([], prev, true), [
                            { color: "".concat(colorCode_1), fe_code: element.fe_code },
                        ], false); });
                        console.log(styleLine, 'colorCodes');
                        var myLocMarker = new H.map.Marker({
                            lat: lat,
                            lng: lng,
                        });
                        (0, heat_map_api_service_1.markerEvents)(myLocMarker, startEndInfo.startPointInfo, ui, false);
                        lineString.pushPoint({
                            lat: lat,
                            lng: lng,
                        });
                        markerGroup.push(myLocMarker);
                        group.addObjects(__spreadArray([], markerGroup, true));
                        map.addObject(group);
                        map.addObject(new H.map.Polyline(lineString, styleLine));
                        map.getViewModel().setLookAtData({
                            bounds: group.getBoundingBox(),
                        });
                    }
                    catch (e) {
                        console.log(e);
                    }
                });
        });
    };
    var callUnassignedVists = function (map, ui) {
        var lineString = new H.geo.LineString();
        var unAssignedIcon = new H.map.Icon(images_1.default.unassignedIcon, map_constants_1.MPA_ICONS_SIZE);
        unassignedVisitData.map(function (element, index) {
            try {
                var lat = element.latitude;
                var lng = element.longitude;
                console.log(lat, lng, 'latlonginfo');
                var startEndInfo = {
                    startPointInfo: element,
                    endPointInfo: element,
                };
                console.log(startEndInfo, 'locationInfo');
                var myLocMarker = new H.map.Marker({
                    lat: lat,
                    lng: lng,
                });
                myLocMarker.setIcon(unAssignedIcon);
                (0, heat_map_api_service_1.markerEvents)(myLocMarker, startEndInfo.startPointInfo, ui, true);
                map.addObject(myLocMarker);
                map.addObject(new H.map.Polyline(lineString));
            }
            catch (e) {
                console.log(e);
            }
        });
    };
    var addPolylineToMap = function (map, ui) {
        if (selectedFEId.length >= 1) {
            callAssignedVisits(map, ui);
        }
        if (unassignedVisitData && unassignedVisitData.length >= 1) {
            callUnassignedVists(map, ui);
        }
    };
    React.useLayoutEffect(function () {
        if (!mapRef.current)
            return;
        var platform = new H.service.Platform({
            apikey: process.env.HERE_MAPS_API_KEY,
        });
        var defaultLayers = platform.createDefaultLayers({ pois: true });
        var map = new H.Map(mapRef.current, defaultLayers.vector.normal.map.setMin(2), {
            center: { lat: 23.6913, lng: 85.2722 },
            zoom: 5,
            pixelRatio: window.devicePixelRatio || 1,
        });
        map.addLayer(defaultLayers.raster.terrain.map);
        var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        var ui = H.ui.UI.createDefault(map, defaultLayers);
        ui.getElement().style.zIndex = 999;
        addPolylineToMap(map, ui);
        console.log(ui, behavior);
        return function () {
            map.dispose();
        };
    }, [mapRef, assignedVisitData, unassignedVisitData, selectedFEId]);
    React.useEffect(function () {
        setRoundColors(roundinfoColors);
    }, [roundinfoColors]);
    return !errorMessage ? (React.createElement("div", { ref: mapRef, className: "profile-map-container" })) : (React.createElement("div", { className: "server-error" }, errorMessage && errorMessage.toString()));
};
exports.default = ViewPolylineRoute;
