"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var lodash_1 = require("../../../../utils/lodash");
var heat_map_api_service_1 = require("../../../super_admin/heat_maps/heat_map_api_service");
var fe_live_tracking_service_1 = require("../service/fe_live_tracking_service");
var AllFEMapView = function (_a) {
    var errorMessage = _a.errorMessage, feList = _a.feList;
    var mapRef = React.useRef(null);
    var plotMarkers = function (map, ui) {
        if (feList && feList.length > 0) {
            feList.map(function (val) {
                if (val.base_latitude &&
                    !lodash_1.default.isNaN(Number(val.base_latitude)) &&
                    val.base_longitude &&
                    !lodash_1.default.isNaN(Number(val.base_longitude))) {
                    var londonMarker = new H.map.Marker({
                        lat: Number(val.base_latitude),
                        lng: Number(val.base_longitude),
                    });
                    var startEndInfo = {
                        startPointInfo: val,
                    };
                    londonMarker.setIcon(heat_map_api_service_1.fe_marker);
                    (0, fe_live_tracking_service_1.FEMarkerEvents)(londonMarker, startEndInfo.startPointInfo, ui, true);
                    map.addObject(londonMarker);
                }
            });
        }
    };
    React.useLayoutEffect(function () {
        if (!mapRef.current)
            return;
        var platform = new H.service.Platform({
            apikey: process.env.HERE_MAPS_API_KEY,
        });
        var defaultLayers = platform.createDefaultLayers({ pois: true });
        var map = new H.Map(mapRef.current, defaultLayers.vector.normal.map.setMin(2), {
            center: { lat: 23.6913, lng: 85.2722 },
            zoom: 5,
            pixelRatio: window.devicePixelRatio || 1,
        });
        map.addLayer(defaultLayers.raster.terrain.map);
        var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        var ui = H.ui.UI.createDefault(map, defaultLayers);
        ui.getElement().style.zIndex = 999;
        console.log(ui, behavior);
        plotMarkers(map, ui);
        return function () {
            map.dispose();
        };
    }, [mapRef, feList]);
    return React.createElement("div", { ref: mapRef, className: "profile-map-container" });
};
exports.default = AllFEMapView;
