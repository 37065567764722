"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var React = require("react");
var react_router_1 = require("react-router");
var iconButton_component_1 = require("../../../../common/buttons/iconButton_component");
var tabs_component_1 = require("../../../../common/tabs/tabs_component");
var appConstants_1 = require("../../../../constants/appConstants");
var FECreationTabConstant_1 = require("../../../../constants/FECreationTabConstant");
var route_path_1 = require("../../../../constants/route_path");
var tabs_data_1 = require("../../../../constants/tabs_data");
var react_router_2 = require("react-router");
var menu_constants_1 = require("../../../../menuList/menu_constants");
var routes_list_1 = require("../../../../routes/routes_list");
var array_helpers_1 = require("../../../../utils/array_helpers");
var ETRF_apicalls_1 = require("../ETRF_apicalls");
var ETRFProfileDetails = function () {
    var nav = (0, react_router_1.useNavigate)();
    var _a = React.useState(false), isListReloaded = _a[0], setIsListReloaded = _a[1];
    var _b = React.useState([]), etrfDetails = _b[0], setETRFDetails = _b[1];
    var _c = React.useState(""), errorMessage = _c[0], setErrorMessage = _c[1];
    var ETRFRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.ETRFDocuments, routes_list_1.ETRF_SUB_ROUTES);
    var id = (0, react_router_2.useParams)().id;
    var BackClick = function () {
        nav("/".concat(route_path_1.default.PATHS.ETRFList));
    };
    var fetchETRFDetails = function () {
        var payload = { id: id };
        (0, ETRF_apicalls_1.getETRFDetails)(payload)
            .then(function (response) {
            setETRFDetails(response.data.etrf_data);
            setErrorMessage("");
        })
            .catch(function (err) {
            console.log(err);
            setErrorMessage(err.message);
            setETRFDetails([]);
        });
    };
    React.useEffect(function () {
        fetchETRFDetails();
    }, []);
    return (React.createElement("div", { className: "user-details-wrapper etrf-details-page" }, !errorMessage ?
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: "tool-bar-wrapper no-filter-toolbar" },
                React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                    React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: BackClick }),
                    React.createElement("h2", null, "e-TRF Documents"))),
            React.createElement(Grid_1.default, { container: true, xs: 12, className: "details-tab-wrapper" },
                React.createElement(Grid_1.default, { item: true, xs: 12 },
                    React.createElement("div", { className: "user-card displayflex user-details-card" },
                        React.createElement("div", { className: "user-details-wrapper displayflex " },
                            React.createElement("div", { className: "user-name-wrapper" },
                                React.createElement("p", { className: "user-name" }, etrfDetails._id
                                    ? etrfDetails._id
                                    : FECreationTabConstant_1.emptyDetails),
                                React.createElement("span", { className: "user-id" }, "".concat(etrfDetails.etrf_barcode_id
                                    ? etrfDetails.etrf_barcode_id
                                    : FECreationTabConstant_1.emptyDetails, " / ").concat(etrfDetails.city
                                    ? etrfDetails.city
                                    : FECreationTabConstant_1.emptyDetails)))))),
                React.createElement(Grid_1.default, { item: true, xs: 12 },
                    React.createElement(tabs_component_1.default, { tabList: tabs_data_1.TABS_DATA.ETRF_DETAILS }),
                    React.createElement(react_router_1.Routes, null, ETRFRoutes &&
                        ETRFRoutes.map(function (features) {
                            return (React.createElement(react_router_1.Route, { index: true, path: "".concat(features.path), key: features.path, element: React.createElement(appConstants_1.ReloadListContext.Provider, { value: {
                                        isListReloaded: isListReloaded,
                                        setIsListReloaded: setIsListReloaded,
                                    } },
                                    React.createElement(features.component, { etrfProfileDetails: etrfDetails })) }));
                        })))))
        :
            errorMessage));
};
exports.default = ETRFProfileDetails;
