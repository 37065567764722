"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Timeline_1 = require("@mui/lab/Timeline");
var TimelineConnector_1 = require("@mui/lab/TimelineConnector");
var TimelineContent_1 = require("@mui/lab/TimelineContent");
var TimelineDot_1 = require("@mui/lab/TimelineDot");
var TimelineItem_1 = require("@mui/lab/TimelineItem");
var TimelineSeparator_1 = require("@mui/lab/TimelineSeparator");
var Accordion_1 = require("@mui/material/Accordion");
var AccordionDetails_1 = require("@mui/material/AccordionDetails");
var AccordionSummary_1 = require("@mui/material/AccordionSummary");
var Drawer_1 = require("@mui/material/Drawer");
var Grid_1 = require("@mui/material/Grid");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_router_1 = require("react-router");
var button_component_1 = require("../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../common/buttons/iconButton_component");
var pop_up_1 = require("../../../common/modal/pop-up");
var profile_grid_1 = require("../../../common/profile_grid/profile_grid");
var profile_detaile_lable_1 = require("../../../constants/profile_detaile_lable");
var route_path_1 = require("../../../constants/route_path");
var array_helpers_1 = require("../../../utils/array_helpers");
require("../sample_traking/sample-tracking.scss");
var shipment_helper_1 = require("./shipment_helper");
var emptyDetails = '--';
var ShipmentTrackingDetails = function () {
    var master_barcode = (0, react_router_1.useParams)().master_barcode;
    var _a = React.useState([]), dataList = _a[0], setDataList = _a[1];
    var _b = React.useState(''), shipmentId = _b[0], setShipmentId = _b[1];
    var _c = React.useState(''), masterShipmentBarcode = _c[0], setMasterShipmentBarcode = _c[1];
    var nav = (0, react_router_1.useNavigate)();
    var _d = React.useState(false), open = _d[0], setOpen = _d[1];
    var _e = React.useState(false), imageModal = _e[0], setImageModal = _e[1];
    var _f = React.useState([]), shipmentDetails = _f[0], setShipmentDetails = _f[1];
    var _g = React.useState(false), shipmentTrackOpen = _g[0], setShipmentTrackopen = _g[1];
    var _h = React.useState([]), masterShipmentDetails = _h[0], setMasterShipmentDetails = _h[1];
    var _j = React.useState(''), imageUrl = _j[0], setImageUrl = _j[1];
    var handleOpen = function (url) {
        setImageUrl(url);
        setImageModal(true);
    };
    var handleClose = function () { return setImageModal(false); };
    var trackingMasterShipmentDrawerOpen = function () {
        setShipmentTrackopen(true);
        setOpen(true);
        fetchMasterShipmentJourney();
    };
    var trackingMasterShipmentDrawerClose = function (e) {
        setShipmentTrackopen(true);
        setOpen(false);
    };
    var handleDrawerOpen = function (value) {
        setOpen(true);
        setShipmentTrackopen(false);
        fetchShipmentJourney(value);
    };
    var handleDrawerClose = function () {
        setOpen(false);
    };
    var navigateTolist = function () {
        nav("/".concat(route_path_1.default.PATHS.shipment_tracking));
    };
    var fetchShipmentDetails = function (master_barcode) {
        var payload = {};
        payload.user_id = master_barcode;
        (0, shipment_helper_1.getShipmentDetails)(master_barcode)
            .then(function (response) {
            setDataList(response.data);
            setMasterShipmentBarcode(response.data.master_shipment_barcode);
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
        });
    };
    var fetchShipmentJourney = function (shipmentBarcode) {
        (0, shipment_helper_1.shipmentJourney)(shipmentBarcode)
            .then(function (response) {
            setShipmentDetails(response.data);
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
        });
    };
    var fetchMasterShipmentJourney = function () {
        (0, shipment_helper_1.masterShipmentJourney)(masterShipmentBarcode)
            .then(function (response) {
            setMasterShipmentDetails(response.data);
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
        });
    };
    React.useEffect(function () {
        fetchShipmentDetails(master_barcode);
    }, [master_barcode]);
    var latestEvents = function (event_type, shipmentType) {
        var event;
        event_type === 'Master shipment created' && shipmentType === 'MasterShipment'
            ? (event = 'Created')
            : event_type === 'Created'
                ? (event = 'Shipment Created')
                : event_type === 'Master shipment created'
                    ? (event = 'Inside Master Shipment')
                    : event_type === 'Picked by Connector'
                        ? (event = 'Handover To Connector')
                        : event_type === 'Hub_handover'
                            ? (event = 'Handover To Hub')
                            : event_type === 'Lab_handover'
                                ? (event = 'Handover To Lab')
                                : event_type === 'Shipment_received'
                                    ? (event = 'Received At Lab')
                                    : event_type === 'Hub_receiving'
                                        ? (event = 'Received From Hub')
                                        : '';
        return event;
    };
    var statusMessages = function (event) {
        var message;
        event.event_type === 'Created'
            ? ''
            : event.event_type === 'Master shipment created'
                ? event.to_user_name && event.to_user_id
                    ? (message = "Master Shipment Created by ".concat(event.to_user_name, " (FE ID ").concat(event.to_user_id, ")"))
                    : 'Box Created'
                : event.event_type === 'Picked by Connector'
                    ? (message = "Shipment Handed Over From ".concat(event.from_user_name, " (FE ID ").concat(event.from_user_id, ") to ").concat(event.to_user_name, " (FE ID ").concat(event.to_user_id, ")"))
                    : event.event_type === 'Lab_handover'
                        ? (message = "Shipment Handed Over By ".concat(event.from_user_name, " (FE ID ").concat(event.from_user_id, ") to ").concat(event === null || event === void 0 ? void 0 : event.to_user_id, ", ").concat(event === null || event === void 0 ? void 0 : event.to_lab_name))
                        : event.event_type === 'Hub_handover'
                            ? (message = "Shipment Handed Over From ".concat(event.from_user_name, " (FE ID ").concat(event.from_user_id, ") to ").concat(event === null || event === void 0 ? void 0 : event.to_user_id, ", ").concat(event === null || event === void 0 ? void 0 : event.to_hub_name, ", ").concat(event === null || event === void 0 ? void 0 : event.to_hub_city))
                            : event.event_type === 'Shipment_received'
                                ? (message = "Shipment Received At ".concat(event.to_user_id, ", ").concat(event === null || event === void 0 ? void 0 : event.to_lab_name))
                                : event.event_type === 'Hub_receiving'
                                    ? (message = "Shipment Received By ".concat(event.to_user_name, " (FE ID ").concat(event === null || event === void 0 ? void 0 : event.to_user_id, ")"))
                                    : '';
        return message;
    };
    var BasicInformation = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "sample-details-wrapper small-overflow-hide" },
                React.createElement(Grid_1.default, { container: true, spacing: 0, columns: { xs: 12 } },
                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                            React.createElement("h2", null, profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.basicInformation))),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.masterShipmentBarcode, value: dataList && dataList.master_shipment_barcode
                            ? dataList.master_shipment_barcode
                            : emptyDetails, className: "blue-text" }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.currentStatus, value: dataList && dataList.status ? dataList.status : emptyDetails }),
                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                            React.createElement("h2", null, profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.processData))),
                    React.createElement(Grid_1.default, { item: true, container: true, xs: 12, className: "lab-sample-images" },
                        React.createElement(Grid_1.default, { item: true, xs: 6 },
                            React.createElement("div", { className: "user-profile-wrapper" },
                                React.createElement("small", { className: "user-label" }, profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.shipmentPhoto),
                                React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: { xs: 9 } }, dataList.created_photo ? (React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: "image-item downloadable-image" },
                                    React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: "user-value ertf-doc-image", onClick: function () {
                                                handleOpen(dataList.created_photo);
                                            } },
                                            React.createElement("img", { src: dataList.created_photo, alt: "" }))))) : ('NO DOCUMENT UPLOADED')))),
                        React.createElement(Grid_1.default, { item: true, xs: 6 },
                            React.createElement("div", { className: "user-profile-wrapper" },
                                React.createElement("small", { className: "user-label" }, profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.connectorHandoverPhoto),
                                React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: { xs: 9 } }, dataList.connector_handover_photo ? (React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: "image-item downloadable-image" },
                                    React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: "user-value ertf-doc-image", onClick: function () {
                                                handleOpen(dataList.connector_handover_photo);
                                            } },
                                            React.createElement("img", { src: dataList.connector_handover_photo, alt: "" }))))) : ('NO DOCUMENT UPLOADED')))),
                        React.createElement(Grid_1.default, { item: true, xs: 6 },
                            React.createElement("div", { className: "user-profile-wrapper" },
                                React.createElement("small", { className: "user-label" }, profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.hubHandoverPhoto),
                                React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: { xs: 9 } }, dataList.hub_handover_photo ? (React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: "image-item downloadable-image" },
                                    React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: "user-value ertf-doc-image", onClick: function () {
                                                handleOpen(dataList.hub_handover_photo);
                                            } },
                                            React.createElement("img", { src: dataList.hub_handover_photo, alt: "" }))))) : ('NO DOCUMENT UPLOADED')))),
                        React.createElement(Grid_1.default, { item: true, xs: 6 },
                            React.createElement("div", { className: "user-profile-wrapper" },
                                React.createElement("small", { className: "user-label" }, profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.hubReceivingPhoto),
                                React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: { xs: 9 } }, dataList.hub_receiving_photo ? (React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: "image-item downloadable-image" },
                                    React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: "user-value ertf-doc-image", onClick: function () {
                                                handleOpen(dataList.hub_receiving_photo);
                                            } },
                                            React.createElement("img", { src: dataList.hub_receiving_photo, alt: "" }))))) : ('NO DOCUMENT UPLOADED')))),
                        React.createElement(Grid_1.default, { item: true, xs: 6 },
                            React.createElement("div", { className: "user-profile-wrapper" },
                                React.createElement("small", { className: "user-label" }, profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.labHandoverPhoto),
                                React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: { xs: 9 } }, dataList.lab_handover_photo ? (React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: "image-item downloadable-image" },
                                    React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: "user-value ertf-doc-image", onClick: function () {
                                                handleOpen(dataList.lab_handover_photo);
                                            } },
                                            React.createElement("img", { src: dataList.lab_handover_photo, alt: "" }))))) : ('NO DOCUMENT UPLOADED'))))),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.rlabCode, value: dataList && dataList.registration_lab
                            ? dataList.registration_lab
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.pLabCode, value: dataList && dataList.processing_lab
                            ? dataList.processing_lab
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.rLabState, value: dataList && dataList.registration_state
                            ? dataList.registration_state
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.pLabState, value: dataList && dataList.processing_lab_state
                            ? dataList.processing_lab_state
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.rLabCity, value: dataList && dataList.registration_city
                            ? dataList.registration_city
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.pLabCity, value: dataList && dataList.processing_lab_city
                            ? dataList.processing_lab_city
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.rLabName, value: dataList && dataList.registartion_lab_name
                            ? dataList.registartion_lab_name
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.pLabName, value: dataList && dataList.processing_lab_name
                            ? dataList.processing_lab_name
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.noOfBoxes, value: dataList && dataList.number_of_boxes
                            ? dataList.number_of_boxes
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.creationTime, value: dataList && dataList.created_date_time
                            ? (0, array_helpers_1.normaldateandTimeFormatte)(dataList.created_date_time)
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.pickupCutOffTime, value: dataList && dataList.pickup_cut_off_time
                            ? (0, array_helpers_1.normaldateandTimeFormatte)(dataList.pickup_cut_off_time)
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.DesignatedHittingTime, value: dataList && dataList.designated_hitting_time
                            ? (0, array_helpers_1.normaldateandTimeFormatte)(dataList.designated_hitting_time)
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.tat, value: dataList && dataList.tat ? dataList.tat : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.tatBreach, value: dataList && dataList.tat_breach === false
                            ? 'False'
                            : dataList.tat_breach === true
                                ? 'True'
                                : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.tatBreachedBy, value: dataList && dataList.tat_breached_by
                            ? dataList.tat_breached_by
                            : emptyDetails }),
                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 18 },
                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng section-heaidng" },
                            React.createElement("h2", null, profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.otherInfo))),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.pickUpFECode, value: dataList && dataList.pickup_fe_code
                            ? dataList.pickup_fe_code
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.pickupFEName, value: dataList && dataList.pickup_fe_name
                            ? dataList.pickup_fe_name
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.connectorFECode, value: dataList && dataList.connector_fe_code
                            ? dataList.connector_fe_code
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.connectorFEName, value: dataList && dataList.connector_fe_name
                            ? dataList.connector_fe_name
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.FEState, value: dataList && dataList.connector_fe_state
                            ? dataList.connector_fe_state
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.FECity, value: dataList && dataList.connector_fe_city
                            ? dataList.connector_fe_city
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.actualPickupTime, value: dataList && dataList.actual_pickup_time
                            ? (0, array_helpers_1.normaldateandTimeFormatte)(dataList.actual_pickup_time)
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.actualHubHandoverTime, value: dataList && dataList.actual_hub_handover_time
                            ? (0, array_helpers_1.normaldateandTimeFormatte)(dataList.actual_hub_handover_time)
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.actualpLabHandoverTime, value: dataList && dataList.actual_processing_lab_handover_time
                            ? (0, array_helpers_1.normaldateandTimeFormatte)(dataList.actual_processing_lab_handover_time)
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.pickupFELatLong, value: dataList && dataList.pickup_fe_latitude && dataList.pickup_fe_longitude
                            ? "".concat(dataList.pickup_fe_latitude, " ").concat(dataList.pickup_fe_longitude)
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.hubReceivingLatLong, value: dataList &&
                            dataList.hub_receiving_latitude &&
                            dataList.hub_receiving_longitude
                            ? "".concat(dataList.hub_receiving_latitude, " ").concat(dataList.hub_receiving_longitude)
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.labHandoverLatLong, value: dataList &&
                            dataList.lab_receiving_latitude &&
                            dataList.lab_receiving_longitude
                            ? "".concat(dataList.lab_receiving_latitude, " ").concat(dataList.lab_receiving_longitude)
                            : emptyDetails })))));
    };
    var ShipmentsTracking = function () {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "sample-details-wrapper accordian-container small-overflow-hide" },
                React.createElement(Grid_1.default, { container: true, spacing: 0, columns: { xs: 12 } },
                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                            React.createElement("h2", null,
                                " ",
                                profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.shipmentTracking)))), (_a = dataList === null || dataList === void 0 ? void 0 : dataList.shipments) === null || _a === void 0 ? void 0 :
                _a.map(function (e, i) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
                    console.log(e, 'iiiiii');
                    return (React.createElement(Accordion_1.default, { className: "custom-accordian" },
                        React.createElement("div", { className: "accordion-tab-wrapper " },
                            React.createElement("div", { className: "accordian-summary" },
                                React.createElement("div", { className: "label-values-wrapper" },
                                    React.createElement("small", { className: "item-label" }, profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.shipmentBarcodeNo),
                                    React.createElement("p", { className: "item-value" }, (e === null || e === void 0 ? void 0 : e.barcode) ? e.barcode : emptyDetails)),
                                React.createElement("div", { className: "label-values-wrapper" },
                                    React.createElement("small", { className: "item-label" }, profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.noOfSamples),
                                    React.createElement("p", { className: "item-value blue-text" }, (e === null || e === void 0 ? void 0 : e.number_of_samples) ? e.number_of_samples : emptyDetails)),
                                React.createElement("div", { className: "label-values-wrapper" },
                                    React.createElement("small", { className: "item-label" }, profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.shipmentCreationTime),
                                    React.createElement("p", { className: "item-value" }, (e === null || e === void 0 ? void 0 : e.shipment_creation_date)
                                        ? (0, array_helpers_1.shipmentNormaldateandTimeFormatte)(e.shipment_creation_date)
                                        : emptyDetails)),
                                React.createElement("div", { className: "accordion-btn", "aria-controls": "panel1a-content", id: "panel1a-header" },
                                    React.createElement(AccordionSummary_1.default, { className: "accordian-tab" },
                                        React.createElement("span", { className: "icon-right accordion-icon" })))),
                            React.createElement("div", { className: "accordion-item-details" },
                                React.createElement("div", { className: "bag-details-wrapper" },
                                    React.createElement("p", { className: "item-status" },
                                        "Current Status :",
                                        ' ',
                                        React.createElement("span", null, latestEvents((_a = e.latest_even) === null || _a === void 0 ? void 0 : _a.event, 'Shipment')),
                                        ' '),
                                    React.createElement("small", { className: "item-details" }, ((_b = e.latest_event) === null || _b === void 0 ? void 0 : _b.event) === 'Created'
                                        ? ''
                                        : ((_c = e.latest_event) === null || _c === void 0 ? void 0 : _c.event) === 'Master shipment created'
                                            ? "Master Shipment Created by ".concat(e.latest_event.from_user_name, " (FE ID ").concat(e.from_user_id, ")")
                                            : ((_d = e.latest_event) === null || _d === void 0 ? void 0 : _d.event) === 'Picked by Connector'
                                                ? "Shipment Handed Over From ".concat(e.latest_event.from_user_name, " (FE ID ").concat(e.latest_event.from_user_id, ") to ").concat(e.latest_event.to_user_name, " (FE ID ").concat(e.latest_event.to_user_id, ")")
                                                : ((_e = e.latest_event) === null || _e === void 0 ? void 0 : _e.event) === 'Lab_handover'
                                                    ? "Shipment Handed Over By ".concat(e.latest_event.from_user_name, " (FE ID ").concat(e.latest_event.from_user_id, ") to ").concat((_f = e.latest_event) === null || _f === void 0 ? void 0 : _f.to_user_id, ", ").concat((_g = e.latest_event) === null || _g === void 0 ? void 0 : _g.to_lab_name)
                                                    : ((_h = e.latest_event) === null || _h === void 0 ? void 0 : _h.event) === 'Hub_handover'
                                                        ? "Shipment Handed Over From ".concat(e.latest_event.from_user_name, " (FE ID ").concat(e.latest_event.from_user_id, ") to ").concat(e === null || e === void 0 ? void 0 : e.latest_event.to_user_id, ", ").concat(e === null || e === void 0 ? void 0 : e.latest_event.to_hub_name, ", ").concat(e === null || e === void 0 ? void 0 : e.latest_event.to_hub_city)
                                                        : ((_j = e.latest_event) === null || _j === void 0 ? void 0 : _j.event) === 'Shipment_received'
                                                            ? "Shipment Received At ".concat(e.latest_event.from_user_name, " (FE ID ").concat(e === null || e === void 0 ? void 0 : e.latest_event.to_lab_name, ")")
                                                            : ((_k = e.latest_event) === null || _k === void 0 ? void 0 : _k.event) === 'Hub_receiving'
                                                                ? "Shipment Received By ".concat(e.latest_event.to_user_name, " (FE ID ").concat(e === null || e === void 0 ? void 0 : e.latest_event.to_user_id)
                                                                : ''),
                                    React.createElement("small", { className: "item-time-stamp" }, (0, array_helpers_1.normaldateandTimeFormatte)((_l = e.latest_event) === null || _l === void 0 ? void 0 : _l.timestamp))),
                                React.createElement("div", { className: "track-link" },
                                    React.createElement(button_component_1.default, { onClickHandler: function () {
                                            setShipmentId(e.barcode);
                                            handleDrawerOpen(e === null || e === void 0 ? void 0 : e.barcode);
                                        }, buttonName: "Track", endIcon: "icon-right", color: "transparent btn-icon-right" })))),
                        React.createElement(AccordionDetails_1.default, { className: "custom-accordion-details" }, (_m = e.samples) === null || _m === void 0 ? void 0 : _m.map(function (each) {
                            return (React.createElement(Grid_1.default, { container: true, spacing: 0, columns: { xs: 12 }, className: "shipment-accordion" },
                                React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 4 },
                                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.labNo, value: each && each.lab_number ? each.lab_number : emptyDetails })),
                                React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 4 },
                                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.tubeType, value: each && each.tube_type ? each.tube_type : emptyDetails })),
                                React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 4 },
                                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.tubeTypeCount, value: each && each.tube_type_count
                                            ? each.tube_type_count
                                            : emptyDetails }))));
                        }))));
                }))));
    };
    var MasterShipmentJourney = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "drawer-content drawer-track-bag" },
                React.createElement("div", { className: "drawer-header" },
                    React.createElement("div", { className: "drawer-heading-wrapper" },
                        React.createElement("h2", null,
                            " ",
                            profile_detaile_lable_1.default.SAMPLE_BAG_HEADINGS[4]),
                        React.createElement("small", { className: "sub-heading" },
                            "Master Shipment :",
                            ' ',
                            dataList && dataList.master_shipment_barcode
                                ? dataList === null || dataList === void 0 ? void 0 : dataList.master_shipment_barcode
                                : emptyDetails)),
                    React.createElement("div", { onClick: handleDrawerClose, className: "drawer-close-btn" },
                        React.createElement("span", { className: "icon-close" }))),
                React.createElement("div", { className: "stepper-container timeline-wrapper" },
                    React.createElement(Timeline_1.default, { position: "right" }, masterShipmentDetails === null || masterShipmentDetails === void 0 ? void 0 : masterShipmentDetails.map(function (e) {
                        console.log(e, 'eee');
                        console.log('sample');
                        return (React.createElement(React.Fragment, null,
                            React.createElement(TimelineItem_1.default, null,
                                React.createElement(TimelineSeparator_1.default, null,
                                    React.createElement(TimelineDot_1.default, { className: "step-icon-wrapper " },
                                        React.createElement("span", { className: e.event_type === 'Lab_handover'
                                                ? 'icon-receipt step-icon'
                                                : e.event_type === 'Picked by Connector'
                                                    ? 'icon-Vector-25 step-icon'
                                                    : e.event_type === 'Hub_handover'
                                                        ? 'icon-Group-1 step-icon'
                                                        : e.event_type === 'Master shipment created'
                                                            ? 'icon-building step-icon'
                                                            : e.event_type === 'Created'
                                                                ? 'icon-building step-icon'
                                                                : (e === null || e === void 0 ? void 0 : e.event_type) === 'Shipment_received'
                                                                    ? 'icon-receipt step-icon'
                                                                    : (e === null || e === void 0 ? void 0 : e.event_type) === 'Received from hub'
                                                                        ? 'icon-directions_bike step-icon'
                                                                        : '' })),
                                    React.createElement(TimelineConnector_1.default, null)),
                                React.createElement(TimelineContent_1.default, { className: "step-label-content" },
                                    React.createElement("div", { className: "step-content" },
                                        React.createElement("p", { className: "step-label" },
                                            ' ',
                                            latestEvents(e === null || e === void 0 ? void 0 : e.event_type, 'MasterShipment')),
                                        React.createElement("div", { className: "step-details-wrapper" },
                                            React.createElement("p", { className: "step-desc" }, statusMessages(e)),
                                            React.createElement("p", { className: "step-time-stamp" }, (0, array_helpers_1.normaldateandTimeFormatte)(e === null || e === void 0 ? void 0 : e.timestamp))))))));
                    }))))));
    };
    var ShipmentJourney = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "drawer-content drawer-track-bag" },
                React.createElement("div", { className: "drawer-header" },
                    React.createElement("div", { className: "drawer-heading-wrapper" },
                        React.createElement("h2", null,
                            " ",
                            profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.shipmentTrackingDetails),
                        React.createElement("small", { className: "sub-heading" },
                            profile_detaile_lable_1.SHIPMENT_TRACKING_LABELS.shipmentBarcodeNo,
                            " :",
                            shipmentId)),
                    React.createElement("div", { onClick: trackingMasterShipmentDrawerClose, className: "drawer-close-btn" },
                        React.createElement("span", { className: "icon-close" }))),
                React.createElement("div", { className: "stepper-container timeline-wrapper" },
                    React.createElement(Timeline_1.default, { position: "right" }, shipmentDetails === null || shipmentDetails === void 0 ? void 0 : shipmentDetails.map(function (e) {
                        console.log(e.event_type, 'eee');
                        console.log(e, 'bag');
                        return (React.createElement(React.Fragment, null,
                            React.createElement(TimelineItem_1.default, null,
                                React.createElement(TimelineSeparator_1.default, null,
                                    React.createElement(TimelineDot_1.default, { className: "step-icon-wrapper " },
                                        React.createElement("span", { className: e.event_type === 'Lab_handover'
                                                ? 'icon-receipt step-icon'
                                                : e.event_type === 'Picked by Connector'
                                                    ? 'icon-Vector-25 step-icon'
                                                    : e.event_type === 'Hub_handover'
                                                        ? 'icon-Group-1 step-icon'
                                                        : e.event_type === 'Master shipment created'
                                                            ? 'icon-building step-icon'
                                                            : e.event_type === 'Created'
                                                                ? 'icon-building step-icon'
                                                                : (e === null || e === void 0 ? void 0 : e.event_type) === 'Shipment_received'
                                                                    ? 'icon-receipt step-icon'
                                                                    : (e === null || e === void 0 ? void 0 : e.event_type) === 'Hub_receiving'
                                                                        ? 'icon-directions_bike step-icon'
                                                                        : '' })),
                                    React.createElement(TimelineConnector_1.default, null)),
                                React.createElement(TimelineContent_1.default, { className: "step-label-content" },
                                    React.createElement("div", { className: "step-content" },
                                        React.createElement("p", { className: "step-label" },
                                            ' ',
                                            latestEvents(e === null || e === void 0 ? void 0 : e.event_type, 'Shipment')),
                                        React.createElement("div", { className: "step-details-wrapper" },
                                            React.createElement("p", { className: "step-desc" }, statusMessages(e)),
                                            React.createElement("p", { className: "step-time-stamp" }, (0, array_helpers_1.normaldateandTimeFormatte)(e === null || e === void 0 ? void 0 : e.timestamp))))))));
                    }))))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "page-wrapper" },
            React.createElement("div", { className: "tool-bar-wrapper no-filter-toolbar border-bottom" },
                React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                    React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: navigateTolist }),
                    React.createElement("h2", null, "Master Shipment Details")),
                React.createElement("div", { className: "page-control-wrapper" },
                    React.createElement(button_component_1.default, { buttonName: "Track Master Shipment", onClickHandler: trackingMasterShipmentDrawerOpen, color: "yellow-outline" }))),
            React.createElement("div", { className: "sample-tracking-wrapper scroll-xs" },
                React.createElement(Grid_1.default, { container: true, xs: 12, className: "details-tab-wrapper" },
                    React.createElement(Grid_1.default, { item: true, xs: 12, lg: 6 }, React.createElement(BasicInformation, null)),
                    React.createElement(Grid_1.default, { item: true, xs: 12, lg: 6 }, React.createElement(ShipmentsTracking, null)),
                    React.createElement("div", { className: "drawer-container" },
                        React.createElement(Drawer_1.default, { variant: "temporary", anchor: "right", open: open, className: "track-bag-container" }, !shipmentTrackOpen ? React.createElement(ShipmentJourney, null) : React.createElement(MasterShipmentJourney, null))))),
            React.createElement(pop_up_1.default, { open: imageModal },
                React.createElement("div", { className: "modal-header header-wrapper" },
                    React.createElement("h2", { className: "heading" }, "Image")),
                React.createElement("div", { className: "modal-body" },
                    React.createElement("div", { className: "image-conatiner" },
                        React.createElement("img", { src: imageUrl }))),
                React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                    React.createElement(button_component_1.default, { buttonName: "Close", color: "yellow", onClickHandler: handleClose }))))));
};
exports.default = ShipmentTrackingDetails;
