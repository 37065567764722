"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ROUTE_CONSTAMNTS = exports.PATHS = void 0;
exports.PATHS = {
    overView: '',
    fe: 'fe',
    activeList: 'active',
    vendorlist: 'vendor',
    cclist: 'cc',
    createNotification: 'createnotification',
    allFeList: 'list',
    draftedFeList: 'drafted',
    feExisted: 'exited',
    feRejected: 'rejected',
    feDetails: 'details',
    payroll: 'payroll',
    payrun: 'payrun',
    payroleHistory: 'history',
    viewpayrole: 'viewpayrole',
    leaveTraking: 'leave',
    trackFe: 'track',
    feKpis: 'kpis',
    feProfileDetails: 'profiledetails',
    feRegistration: 'New',
    feReplaceRegistration: 'Replace',
    feOnLeave: 'onleave',
    feApprovalPending: 'approvalpending',
    slotBlocking: 'slotblocking',
    editFE: 'edit',
    addFE: 'add',
    addSlot: 'addslot',
    invetoryPending: 'inventorypending',
    pagenotFound: 'pagenotfound',
    badRequest: 'badrequest',
    feInActiveList: 'inactive',
    vendorProfileDetials: 'profiledetails',
    userMasters: 'usermaster',
    feMasters: 'femaster',
    ccMasters: 'ccmaster',
    role_management: 'role',
    addNewRole: 'addrole',
    editRole: 'edit',
    userManagement: 'user',
    addUser: 'adduser',
    userProfileDetails: 'details',
    userDetials: 'profile',
    vendormanagement: 'vendor',
    createVendor: 'new',
    editVendor: 'editvendor',
    approvals: 'approvalmanagement',
    notificationManagement: 'notifications',
    audit: 'audit',
    addAudit: 'addAudit',
    createAudit: 'createAudit',
    levelOneApprovalList: 'levelonelist',
    approveProfileDetails: 'vendor-details',
    feLevelOneDetails: 'level1-details',
    feLevelTwoDetails: 'level2-details',
    levelOnePendingApproval: 'levelOnePendingApproval',
    levelTwoPendingApproval: 'levelTwoPendingApproval',
    reviewAudit: 'review',
    sampleTracking: 'sampletracking',
    masterbox_basicDeatils: 'masterbox',
    sampleTraking_basicDetails: 'basicdetails',
    auditDetails: 'auditdetails',
    assignAudit: 'AssignAudit',
    createUser: 'createUser',
    vendor: 'vendor',
    ETRFList: 'etrf',
    ETRF_Profile: 'profile',
    ETRF_details: 'etrf-details',
    controlTower: 'controlTower',
    controlCenter: 'controlCenter',
    autoRoutingDashboard: 'autoRoutingDashboard',
    sampleList: 'sampleList',
    controCenterFedetails: 'feDetails',
    controlDashboard: 'controldashboard',
    controlTowerRouting: 'ControlTowerRouting',
    reports: 'reports',
    cahCollectionReport: 'cahCollectionReport',
    baggingLifeCycle: 'baggingLifeCycle',
    autoRoutingReport: 'autoRoutingReport',
    cashPendingReport: 'cashPendingReport',
    clientSlotwiseProductivityReports: 'clientSlotwiseProductivity',
    feAttendanceReport: 'feAttendanceReport',
    successfulVisits: 'successfulVisits',
    nothingTopickup: 'nothingTopickup',
    outstationMasterboxReport: 'outstationMasterboxReport',
    rLabtoPLabIntercityReport: 'rLabtoPLabIntercityReport',
    otherDocuments: 'otherdoc',
    otherDocsProfile: 'otherDocsProfile',
    otherDocsDetails: 'otherDocsDetails',
    editAudit: 'editAudit',
    active_vendors: 'active',
    draft_vendors: 'draft',
    exit_vendors: 'exited',
    vendor_pending_approval: 'pendingapproval',
    vendor_level2: 'level2approval',
    vendor_reject: 'reject',
    reports2: 'reports2',
    reports3: 'reports3',
    mastersData: 'mastersData',
    masterbagging: 'bagging',
    centerCityMappingMasters: 'centerCityMappingMasters',
    feLabMasters: 'feLabMasters',
    labMaster: 'labMaster',
    dynamicRoutingMasters: 'dynamicRoutingMasters',
    preciousSamples: 'precious_samples',
    heatMaps: 'heatmaps',
    onDemandRequest: 'onDemandRequest',
    assignRequest: 'assignRequest',
    totalActiveClients: 'totalActiveclients',
    assignRoute: 'assignRoute',
    misReport: 'misreports',
    tab: 'tab',
    editslotFE: 'edit',
    ViewFeSlot: 'view',
    feRoute: 'feRoute',
    AddVisits: 'AddVisits',
    Visits: 'Visits',
    VisitsList: 'VisitsList',
    resetPswd: 'reset',
    forgotPswd: 'forgotPassword',
    VisitDetails: 'VisitDetails',
    SSO_LOGIN: 'sso',
    vendorLevelOneDetails: 'vendorlevel1-details',
    vendorLevelTwoDetails: 'vendorlevel2-details',
    cre: 'cre',
    creOnboardingInProgress: 'onboardingInProgress',
    creExit: 'exited',
    creNewApprovalPending: 'approvalPending',
    creInactive: 'inActive',
    creRejected: 'rejected',
    ax_cash_receieving: 'synclogs',
    cash_receiving: 'cashreceiving',
    lab_receieving: 'labreceiving',
    ax_cash_receieving_details: 'ax_cash_receieving_details',
    ROUTE_MAP: 'routemap',
    POLYLINE_MAP: 'polylinemap',
    MYREPORTS: 'myReports',
    userProfile: 'profile',
    ccworkinghourslist: 'ccworkinghourslist',
    addCCSlot: 'addCCSlot',
    changePassword: 'changePassword',
    route_planner: 'routeplanner',
    User_Logistics: 'UserLogistics',
    Create_User: 'CreateUser',
    approvals_route_planner: 'approvaleplanner',
    create_route_planner: 'edit',
    route_planner_details: 'detials',
    view_route_planner: 'view',
    route_planner_changes_list: 'list',
    route_planner_list: 'routePlanerList',
    route_planner_approved_details: 'approveddetails',
    master_box_tracking: 'masterBoxTracking',
    master_box_details: 'details',
    create_hub_slot: 'createHub',
    edit_hub_slot: 'editTimeSlot',
    LabMasterlist: 'LabMasterlist',
    AddLabMaster: 'addLabMaster',
    hubMasterList: 'hubMasterList',
    visitsReport: 'visitsReport',
    allReports: 'allReports',
    assignedCompletedReports: 'assignedCompletedReports',
    masterBoxtracking: 'masterbox',
    masterBoxDetails: 'BagDetails',
    eventPerformance: 'eventPerformance',
    unTaggedClientsToCC: 'untaggedclients',
    Masterboxtower: 'Masterboxtower',
    ShipmentTower: 'ShipmentTower',
    sso_ondemandRequest: 'sso',
    sso_ondemandRequest_create: 'create',
    region_create: 'CreateRegion',
    user_region: 'userRegion',
    region_mapping: 'regionMapping',
    region_listing: 'regionListing',
    shipment_tracking: 'shipmentTracking',
    shipment_details: 'shipmentDetails',
    intracity_tat_report: 'intracitytat',
    intracity_ontime_pickup: 'intracityontimepickup',
    intracity_labHandOver: 'intracitylabhandover',
    r_lab_to_p_lab: 'rlabtoplab',
    outStationMasterBox: 'outstationmasterbox',
    intracity_tat_filters: 'intracitytatfilters',
    intracity_tat_graph_list: 'intracitytatgraphlist',
    on_time_pickup: 'onTimePickFilters',
    on_time_pickup_list: 'onTimePickList',
    fe_traking_list: 'felist',
    fe_traking_details: 'details',
    shipment_list: 'ShipmentList',
    shipment_create: 'ShipmentCreate',
    shipment_edit: 'ShipmentEdit',
    shipment_view: 'ShipmentView',
    labHandoverTrend: 'labHandoverTrend',
};
var MENU_LABLE_AND_PATHS = [
    {
        id: 1,
        fieldName: 'Overview',
        path: '',
        description: 'icon',
        icon: 'icon-graph',
    },
    {
        id: 2,
        fieldName: 'FE Management',
        path: "".concat(exports.PATHS.fe),
        description: 'icon',
        icon: 'icon-search-user',
    },
    {
        id: 3,
        fieldName: 'Payroll',
        path: "".concat(exports.PATHS.payroll),
        description: 'icon',
        icon: 'icon-cash',
    },
    {
        id: 4,
        fieldName: 'Leave Tracking',
        path: "".concat(exports.PATHS.leaveTraking),
        description: 'icon',
        icon: 'icon-date',
    },
    {
        id: 5,
        fieldName: 'Roster Management',
        path: "".concat(exports.PATHS.slotBlocking),
        description: 'icon',
        icon: 'icon-slot',
    },
    {
        id: 6,
        fieldName: 'Role Management',
        path: "".concat(exports.PATHS.role_management),
        description: 'icon',
        icon: 'icon-search-user',
    },
    {
        id: 7,
        fieldName: 'User Management',
        path: "".concat(exports.PATHS.userManagement),
        description: 'icon',
        icon: 'icon-supervisor_account',
    },
    {
        id: 8,
        fieldName: 'Vendor Management',
        path: "".concat(exports.PATHS.vendormanagement),
        description: 'icon',
        icon: 'icon-business_center',
    },
    {
        id: 9,
        fieldName: 'Approvals',
        path: "".concat(exports.PATHS.approvals, "/").concat(exports.PATHS.fe, "/").concat(exports.PATHS.levelOnePendingApproval),
        description: 'icon',
        icon: 'icon-thumbs_up_down',
        submenu: [
            {
                id: 1,
                fieldName: 'FE',
                path: "".concat(exports.PATHS.approvals, "/").concat(exports.PATHS.fe, "/").concat(exports.PATHS.levelOnePendingApproval),
            },
            {
                id: 2,
                fieldName: 'Vendor',
                path: "".concat(exports.PATHS.approvals, "/").concat(exports.PATHS.vendorlist, "/").concat(exports.PATHS.levelOnePendingApproval),
            },
        ],
    },
    {
        id: 10,
        fieldName: 'Notification Management',
        path: "".concat(exports.PATHS.notificationManagement),
        description: 'icon',
        icon: 'icon-bell',
    },
    {
        id: 12,
        fieldName: 'Audit',
        path: "".concat(exports.PATHS.audit),
        description: 'icon',
        icon: 'icon-fact_check',
    },
    {
        id: 13,
        fieldName: 'Sample Tracking',
        path: "".concat(exports.PATHS.sampleTracking),
        description: 'icon',
        icon: 'icon-moped',
    },
    {
        id: 14,
        fieldName: 'ETRF Documents',
        path: "".concat(exports.PATHS.ETRFList),
        description: 'icon',
        icon: 'icon-create_new_folder',
    },
    {
        id: 15,
        fieldName: 'Control Tower',
        path: "".concat(exports.PATHS.controlTower),
        description: 'icon',
        icon: 'icon-routing',
    },
    {
        id: 16,
        fieldName: 'Reports',
        path: "".concat(exports.PATHS.reports),
        description: 'icon',
        icon: 'icon-text_snippet',
        submenu: [
            {
                id: 1,
                fieldName: 'Cash Collection Report',
                path: "".concat(exports.PATHS.cahCollectionReport),
            },
            {
                id: 2,
                fieldName: 'Cash Pending Report',
                path: "".concat(exports.PATHS.reports, "/").concat(exports.PATHS.cashPendingReport),
            },
            {
                id: 3,
                fieldName: 'Bagging LifeCycle',
                path: "".concat(exports.PATHS.reports, "/").concat(exports.PATHS.baggingLifeCycle),
            },
            {
                id: 4,
                fieldName: 'Auto Routing Report',
                path: "".concat(exports.PATHS.reports, "/").concat(exports.PATHS.autoRoutingReport),
            },
        ],
    },
    {
        id: 17,
        fieldName: 'Other Documents',
        path: "".concat(exports.PATHS.otherDocuments),
        description: 'icon',
        icon: 'icon-create_new_folder',
    },
    {
        id: 18,
        fieldName: 'Masters',
        path: "".concat(exports.PATHS.mastersData),
        description: 'icon',
        icon: 'icon-person_pin',
        submenu: [
            {
                id: 1,
                fieldName: 'Bagging ',
                path: "".concat(exports.PATHS.mastersData, "/").concat(exports.PATHS.masterbagging),
            },
            {
                id: 2,
                fieldName: 'Center city mapping',
                path: "".concat(exports.PATHS.mastersData, "/").concat(exports.PATHS.centerCityMappingMasters),
            },
            {
                id: 3,
                fieldName: 'Fe lab master',
                path: "".concat(exports.PATHS.mastersData, "/").concat(exports.PATHS.feLabMasters),
            },
            {
                id: 4,
                fieldName: 'Lab master',
                path: "".concat(exports.PATHS.mastersData, "/").concat(exports.PATHS.labMaster),
            },
            {
                id: 5,
                fieldName: 'Dynamic route master',
                path: "".concat(exports.PATHS.mastersData, "/").concat(exports.PATHS.dynamicRoutingMasters),
            },
        ],
    },
    {
        id: 19,
        fieldName: 'Precious Samples',
        path: "".concat(exports.PATHS.preciousSamples),
        description: 'icon',
        icon: 'icon-local_police',
    },
    {
        id: 20,
        fieldName: 'Heat Maps',
        path: "".concat(exports.PATHS.ROUTE_MAP),
        description: 'icon',
        icon: 'icon-my_location',
    },
    {
        id: 21,
        fieldName: 'MIS Reports',
        path: "".concat(exports.PATHS.misReport),
        description: 'icon',
        icon: 'icon-assignment',
    },
    {
        id: 22,
        fieldName: 'Visit Activity Data',
        path: "".concat(exports.PATHS.Visits),
        description: 'icon',
        icon: 'icon-pin_drop',
        submenu: [
            {
                id: 1,
                fieldName: 'Add Visits ',
                path: "".concat(exports.PATHS.Visits, "/").concat(exports.PATHS.AddVisits),
            },
            {
                id: 2,
                fieldName: 'Visits List',
                path: "".concat(exports.PATHS.Visits, "/").concat(exports.PATHS.VisitsList),
            },
        ],
    },
    {
        id: 23,
        fieldName: 'CRE',
        path: "".concat(exports.PATHS.cre),
        description: 'icon',
        icon: 'icon-search-user',
    },
    {
        id: 24,
        fieldName: 'myReports',
        path: "".concat(exports.PATHS.cre),
        description: 'icon',
        icon: 'icon-text_snippet',
    },
    {
        id: 25,
        fieldName: 'UserLogistics',
        path: "".concat(exports.PATHS.User_Logistics),
        description: 'icon',
        icon: 'icon-search-user',
    },
    {
        id: 26,
        fieldName: 'Masterbox Tower',
        path: "".concat(exports.PATHS.Masterboxtower),
        description: 'icon',
        icon: 'icon-routing',
    },
    {
        id: 27,
        fieldName: 'Shipment Tower',
        path: "".concat(exports.PATHS.ShipmentTower),
        description: 'icon',
        icon: 'icon-routing',
    },
];
var MENU_LABEL_AND_PATHS_RESTRICTED_BY_SSO = [
    {
        id: 13,
        fieldName: 'Sample Tracking',
        path: "".concat(exports.PATHS.sampleTracking),
        description: 'icon',
        icon: 'icon-moped',
    },
];
exports.ROUTE_CONSTAMNTS = {
    MENU_LABLE_AND_PATHS: MENU_LABLE_AND_PATHS,
    PATHS: exports.PATHS,
    MENU_LABEL_AND_PATHS_RESTRICTED_BY_SSO: MENU_LABEL_AND_PATHS_RESTRICTED_BY_SSO,
};
exports.default = exports.ROUTE_CONSTAMNTS;
