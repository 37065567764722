"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var Tab_1 = require("@mui/material/Tab");
var Tabs_1 = require("@mui/material/Tabs");
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var iconButton_component_1 = require("../../../common/buttons/iconButton_component");
var AssignUsersToAudit1_1 = require("./AssignUsersToAudit1");
var initialValues = {
    startDate: '',
    endDate: '',
    country: [],
    state: [],
    city: [],
    selectedAuditors: null,
    selectedAuditee: null,
    auditees: [],
    auditors: [],
};
var AssignAudit = function () {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var navigationHandler = function () {
        navigate(-1);
    };
    var assignUsersToAuditdetails = React.useState(initialValues)[0];
    var TabPanel = function (props) {
        var children = props.children, value = props.value, index = props.index, other = __rest(props, ["children", "value", "index"]);
        return (React.createElement("div", __assign({ role: "tabpanel", hidden: value !== index, id: "vertical-tabpanel-".concat(index), "aria-labelledby": "vertical-tab-".concat(index) }, other), value === index && (React.createElement("div", { className: "tab-pane-item" },
            React.createElement(AssignUsersToAudit1_1.default, { initialvalues: assignUsersToAuditdetails })))));
    };
    var a11yProps = function (index) {
        return {
            id: "vertical-tab-".concat(index),
            'aria-controls': "vertical-tabpanel-".concat(index),
        };
    };
    var handleChange = function (__event, newValue) {
        return;
    };
    return (React.createElement("div", { className: "on-boarding-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper border-bottom" },
            React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                React.createElement(iconButton_component_1.default, { className: "btn-retun", onClick: navigationHandler, icon: 'icon-left-arrow' }),
                React.createElement("h2", null, "Assign users"))),
        React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 } },
            React.createElement(Grid_1.default, { item: true, xs: 4, lg: 3 },
                React.createElement("div", { className: "vertical-tabs-wrapper tabs-aligner  audit-tabs" },
                    React.createElement(Tabs_1.default, { orientation: "vertical", variant: "scrollable", value: 0, onChange: handleChange, "aria-label": "Vertical tabs example" },
                        React.createElement(Tab_1.default, __assign({ key: Math.random(), label: "Assign Users" }, a11yProps(0), { className: 'tab-btn ', "aria-label": "Vertical tabs example", value: 0 }))))),
            React.createElement(Grid_1.default, { item: true, xs: 8, lg: 9 },
                React.createElement("div", { className: "scrollable-content form-wrapper" }, TabPanel({ value: 0, index: 0 }))))));
};
exports.default = React.memo(AssignAudit);
