"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableData = exports.SHIPMENT_ROUTE_LIST_COLUMNS = exports.INTRACITY_FE_TAT_LIST_COLUMNS = exports.INTRACITY_FE_ONTIME_PICKUP_LIST_COLUMNS = exports.Outstation_Master_Box_GRAPH_PENDIGN_COLUN = exports.Outstation_Master_Box_GRAPH_PENDIGN_RECIEVED_COLUN = void 0;
var moment = require("moment");
var api_service_1 = require("../api/api_service");
var avatar_component_1 = require("../common/avatar/avatar_component");
var button_component_1 = require("../common/buttons/button_component");
var edit_icon_1 = require("../common/buttons/edit_icon");
var icon_view_button_1 = require("../common/buttons/icon_view_button");
var multi_icon_button_1 = require("../common/buttons/multi_icon_button");
var precious_sample_icons_1 = require("../common/buttons/precious_sample_icons");
var status_buttons_1 = require("../common/buttons/status_buttons");
var view_1 = require("../common/buttons/view");
var view_edit_button_1 = require("../common/buttons/view_edit_button");
var working_hours_btns_1 = require("../common/buttons/working_hours_btns");
var checkbox_component_1 = require("../common/checkbox/checkbox_component");
var alert_modal_1 = require("../common/modal/alert_modal");
require("../common/tables/table.scss");
var tool_tip_1 = require("../common/tool_tip/tool_tip");
var EditIconButton_1 = require("../components/field_exicutive/fe_list/EditIconButton");
var fe_drafted_list_1 = require("../components/field_exicutive/fe_list/fe_drafted_list");
var dual_button_1 = require("../components/field_exicutive/leave_tracking/dual_button");
var update_payroll_deduction_1 = require("../components/field_exicutive/payroll/payroll_list/update_payroll_deduction");
var SlotsComponent_1 = require("../components/field_exicutive/slot_blocking/SlotsComponent");
var MasterBoxActionIconButtons_1 = require("../components/Master_box_tracking/MasterBoxActionIconButtons");
var NotificationActionIcon_1 = require("../components/Notification_management/NotificationActionIcon");
var SelectedFEInList_1 = require("../components/Notification_management/SelectedFEInList");
var RegionUpdateIcon_1 = require("../components/RegionMapping/RegionUpdateIcon");
var report_dashboard_service_1 = require("../components/shipment_reports/service/report_dashboard_service");
var AuditUsers_1 = require("../components/super_admin/audit/AuditUsers");
var audit_status_button_1 = require("../components/super_admin/audit/audit_status_button");
var Imagesview_1 = require("../components/super_admin/audit/questions/Imagesview");
var Assign_Button_1 = require("../components/super_admin/control_center/onDemand_Request/Assign_Button");
var assign_route_1 = require("../components/super_admin/control_dashboard/assign_route");
var auto_routing_action_button_1 = require("../components/super_admin/control_dashboard/auto_routing_action_button");
var cash_receiving_icons_1 = require("../components/super_admin/CRE/cre_list/cash_receiving_icons");
var intracity_tat_service_1 = require("../components/super_admin/Intracity_Graphs/Intracity_Tat/intracity_tat_service");
var hubMasterActionBtns_1 = require("../components/super_admin/masters_data/hub master/hubMasterActionBtns");
var DownloadLinkComponent_1 = require("../components/super_admin/MyReports/DownloadLinkComponent");
var ViewApprovalRoutePlanner_1 = require("../components/super_admin/route_planner/approvals/ViewApprovalRoutePlanner");
var view_commnets_1 = require("../components/super_admin/route_planner/new_route/view_commnets");
var view_route_planner_1 = require("../components/super_admin/route_planner/new_route/view_route_planner");
var UserListTooltip_1 = require("../components/super_admin/user_management/UserListTooltip");
var draft_vendors_1 = require("../components/super_admin/vendor_management/list/draft_vendors");
var reason_tooltip_1 = require("../components/super_admin/Visits/reason_tooltip");
var menu_constants_1 = require("../menuList/menu_constants");
var array_helpers_1 = require("../utils/array_helpers");
var appConstants_1 = require("./appConstants");
var _monthToNum = function (date) {
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var formattedDate = moment(date).format(appConstants_1.fixedDateFormatt).toString();
    var yearNumber = formattedDate.substring(6, 10);
    var monthNumber = formattedDate.substring(3, 5);
    var dayNumber = formattedDate.substring(0, 2);
    var result = parseInt(yearNumber) * 10000 +
        parseInt(monthNumber) * 100 +
        parseInt(dayNumber);
    return result;
};
var dateComparator = function (date1, date2) {
    var date1Number = _monthToNum(date1);
    var date2Number = _monthToNum(date2);
    if (date1Number === null && date2Number === null) {
        return 0;
    }
    if (date1Number === null) {
        return -1;
    }
    if (date2Number === null) {
        return 1;
    }
    return date1Number - date2Number;
};
var FE_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'first_name',
        unSortIcon: true,
        width: 400,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase().trim() === valueB.toLowerCase().trim()) {
                return 0;
            }
            return valueA.toLowerCase().trim() > valueB.toLowerCase().trim() ? 1 : -1;
        },
        cellRenderer: avatar_component_1.default,
        cellRendererSelector: function (params) {
            return {
                component: avatar_component_1.default,
                params: {
                    image: params.data.avatar,
                    className: 'avatar',
                    fName: params.data && params.data.first_name,
                    lName: params.data && params.data.last_name,
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE ID',
        field: 'fe_id',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_id); },
    },
    {
        lockPosition: 'left',
        headerName: 'MOBILE NUMBER',
        field: 'mobile',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.mobile); },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB STATE',
        field: 'state',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.state); },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CITY',
        field: 'city',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.city); },
    },
    {
        lockPosition: 'left',
        headerName: 'FE WORKING STATE',
        field: 'working_state',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab && params.data.working_state);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE WORKING CITY',
        field: 'working_city',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab && params.data.working_city);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab.lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab && params.data.lab.lab_code);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'BASE LOCATION',
        field: 'base_location_address',
        tooltipField: 'base_location_address',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.base_location_address);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'VENDOR NAME',
        field: 'vendor_name',
        tooltipField: 'vendor_name',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.vendor_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REPLACED FE ',
        field: 'is_replaced_fe',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.is_replaced_fe);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REPLACED FE NAME ',
        field: 'replacement_fe_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.replacement_fe_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REGIONAL MANAGER',
        field: 'regional_manager1',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.regional_manager1);
        },
    },
];
var NEW_FE_APPROVALCOLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'first_name',
        width: 400,
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: avatar_component_1.default,
        cellRendererSelector: function (params) {
            return {
                component: avatar_component_1.default,
                params: {
                    image: params.data.avatar,
                    className: 'avatar',
                    fName: params.data && params.data.first_name,
                    lName: params.data && params.data.last_name,
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'MOBILE NUMBER',
        field: 'mobile',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.mobile); },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB STATE',
        field: 'state',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.state); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CITY',
        field: 'city',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.city); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE WORKING STATE',
        field: 'working_state',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab && params.data.working_state);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE WORKING CITY',
        field: 'working_city',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab && params.data.working_city);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab.lab_code',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab && params.data.lab.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'BASE LOCATION',
        field: 'base_location_address',
        tooltipField: 'base_location_address',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.base_location_address);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'VENDOR NAME',
        field: 'vendor_name',
        tooltipField: 'vendor_name',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.vendor_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REGIONAL MANAGER',
        field: 'regional_manager1',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.regional_manager1);
        },
    },
];
var LEVEL_ONE_PENDING_APPROVAL_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'first_name',
        width: 400,
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRendererSelector: function (params) {
            return {
                params: {
                    fName: params.data && params.data.first_name,
                    lName: params.data && params.data.last_name,
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ONBOARD STATUS',
        field: 'onboard_status',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.onboard_status);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CREATED DATE/TIME',
        field: 'created_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateFormatter)(params.data.created_date_time);
        },
        comparator: dateComparator,
    },
    {
        lockPosition: 'left',
        headerName: 'UPDATED DATE/TIME',
        field: 'updated_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateFormatter)(params.data.updated_date_time);
        },
        comparator: dateComparator,
    },
];
var LEVEL_TWO_PENDING_APPROVAL_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'first_name',
        width: 400,
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRendererSelector: function (params) {
            return {
                params: {
                    fName: params.data && params.data.first_name,
                    lName: params.data && params.data.last_name,
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ONBOARD STATUS',
        field: 'onboard_status',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.onboard_status);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CREATED DATE/TIME',
        field: 'created_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateFormatter)(params.data.created_date_time);
        },
        comparator: dateComparator,
    },
    {
        lockPosition: 'left',
        headerName: 'UPDATED DATE/TIME',
        field: 'updated_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateFormatter)(params.data.updated_date_time);
        },
        comparator: dateComparator,
    },
];
var FE_EXIT_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'first_name',
        width: 400,
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: avatar_component_1.default,
        cellRendererSelector: function (params) {
            return {
                component: avatar_component_1.default,
                params: {
                    image: params.data.avatar,
                    className: 'avatar',
                    fName: params.data && params.data.first_name,
                    lName: params.data && params.data.last_name,
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE ID',
        field: 'fe_id',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_id); },
    },
    {
        lockPosition: 'left',
        headerName: 'MOBILE NUMBER',
        field: 'mobile',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.mobile); },
    },
    {
        lockPosition: 'left',
        headerName: 'EXIT REASON',
        field: 'reason_type',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.reason_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REASON FOR LEAVING',
        field: 'exit_reason',
        tooltipField: 'exit_reason',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.exit_reason);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAST DATE OF WORKING',
        field: 'date_of_exit',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateFormatter)(params.data.date_of_exit);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB STATE',
        field: 'state',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.state); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CITY',
        field: 'city',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.city); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE WORKING STATE',
        field: 'working_state',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab && params.data.working_state);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE WORKING CITY',
        field: 'working_city',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab && params.data.working_city);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab.lab_code',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab && params.data.lab.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'BASE LOCATION',
        field: 'base_location_address',
        tooltipField: 'base_location_address',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.base_location_address);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'VENDOR NAME',
        field: 'vendor_name',
        tooltipField: 'vendor_name',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.vendor_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REGIONAL MANAGER',
        field: 'regional_manager1',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.regional_manager1);
        },
    },
];
var FE_REJECT_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'first_name',
        width: 400,
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: avatar_component_1.default,
        cellRendererSelector: function (params) {
            return {
                component: avatar_component_1.default,
                params: {
                    image: params.data.avatar,
                    className: 'avatar',
                    fName: params.data && params.data.first_name,
                    lName: params.data && params.data.last_name,
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'MOBILE NUMBER',
        field: 'mobile',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.mobile); },
    },
    {
        lockPosition: 'left',
        headerName: 'REJECT REASON',
        field: 'reject_reason',
        tooltipField: 'reject_reason',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.reject_reason);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'COMMENTS',
        field: 'reject_comments',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.reject_comments);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REJECTED DATE',
        field: 'rejected_date',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateFormatter)(params.data.rejected_date);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB STATE',
        field: 'state',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.state); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CITY',
        field: 'city',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.city); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE WORKING STATE',
        field: 'working_state',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab && params.data.working_state);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE WORKING CITY',
        field: 'working_city',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab && params.data.working_city);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab.lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab && params.data.lab.lab_code);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'BASE LOCATION',
        field: 'base_location_address',
        tooltipField: 'base_location_address',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.base_location_address);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'VENDOR NAME',
        field: 'vendor_name',
        tooltipField: 'vendor_name',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.vendor_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REGIONAL MANAGER',
        field: 'regional_manager1',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.regional_manager1);
        },
    },
    {
        headerName: 'ACTION',
        cellRenderer: EditIconButton_1.default,
        sortable: false,
        pinned: 'right',
        cellRendererSelector: function (params) {
            return {
                component: EditIconButton_1.default,
                params: {
                    user_id: params.data.id,
                    reject_reason_id: params.data.reject_reason_id,
                },
            };
        },
    },
];
var FE_DRAFTED_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'first_name',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        width: 400,
        cellRenderer: avatar_component_1.default,
        cellRendererSelector: function (params) {
            return {
                component: avatar_component_1.default,
                params: {
                    image: params.data.avatar,
                    className: 'avatar',
                    fName: params.data && params.data.first_name,
                    lName: params.data && params.data.last_name,
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'VENDOR NAME',
        field: 'vendor_name',
        tooltipField: 'vendor_name',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        width: 400,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.vendor_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REGIONAL MANAGER',
        field: 'regional_manager1',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.regional_manager1);
        },
    },
    {
        headerName: 'ACTION',
        lockPosition: 'left',
        width: 660,
        sortable: false,
        cellRenderer: alert_modal_1.default,
        cellRendererSelector: function (params) {
            var feName = (0, array_helpers_1.concatFirstNameLastName)(params.data.first_name, params.data.last_name);
            var feManagementFeatures = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.feManagement);
            return {
                component: alert_modal_1.default,
                params: {
                    alertIcon: 'icon-warning',
                    icon: 'icon-delete_forever',
                    isIcondisabled: !feManagementFeatures[menu_constants_1.FEATURE_CONSTANTS.fEDeleteOnboardList],
                    modalTitle: "Are you sure  you want to delete ".concat(feName, "?"),
                    endPoint: api_service_1.api_service.api_urls.DELETE_DRAFTED_FE,
                    domain: process.env.VENDOR_BASE_URL,
                    payLoad: { user_id: params.data.id },
                    onClickHandler: function () {
                        (0, fe_drafted_list_1.deleteDraftedFE)(params.data.id);
                    },
                    onCloseHandler: fe_drafted_list_1.colseModalHandler,
                },
            };
        },
    },
];
var FE_PAYROLE_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'first_name',
        unSortIcon: true,
        cellRenderer: avatar_component_1.default,
        cellRendererSelector: function (params) {
            return {
                component: avatar_component_1.default,
                params: {
                    image: params.data.avatar,
                    className: 'avatar',
                    fName: params.data && params.data.first_name,
                    lName: params.data && params.data.last_name,
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE ID',
        field: 'fe_id',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'WORKING DAYS',
        field: 'workingdays',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'TOTAL KM',
        field: 'totalkm',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL AMOUNT',
        field: 'actualAmount',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'INCENTIVES',
        field: 'incentives',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'DEDUCTION',
        field: 'deduction',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'NET PAY',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'STATUS',
        field: 'status',
        cellRenderer: dual_button_1.default,
        cellRendererSelector: function (params) {
            return {
                component: dual_button_1.default,
                params: {
                    buttonName: params.data.status,
                    selectedFeDetails: params.data,
                    toDate: params.data.to_date,
                },
            };
        },
    },
];
var FE_PAYROLE_ROWS = [
    {
        id: 1,
        first_name: 'Revanth',
        feid: 'R2344',
        workingdays: 20,
        totalkm: 10,
        actualAmount: '20,000',
        incentives: 2000,
        deduction: 200,
        netpay: '20,00.00',
        status: 'active',
    },
    {
        id: 2,
        first_name: 'Samara',
        feid: 'R2344',
        workingdays: 10,
        totalkm: 15,
        actualAmount: '20,000',
        incentives: 2000,
        deduction: 200,
        netpay: '20,00.00',
        status: 'active',
    },
    {
        id: 3,
        first_name: 'Mohan',
        feid: 'R2344',
        workingdays: 20,
        totalkm: 10,
        actualAmount: '20,000',
        incentives: 2000,
        deduction: 200,
        netpay: '20,00.00',
        status: 'active',
    },
    {
        id: 4,
        first_name: 'Sathya',
        feid: 'R2344',
        workingdays: 4,
        totalkm: 5,
        actualAmount: '20,000',
        incentives: 2000,
        deduction: 200,
        netpay: '20,00.00',
        status: 'active',
    },
    {
        id: 5,
        first_name: 'Ramana',
        feid: 'R2344',
        workingdays: 20,
        totalkm: 11,
        actualAmount: '20,000',
        incentives: 2000,
        deduction: 200,
        netpay: '20,00.00',
        status: 'active',
    },
];
var FE_VIEW_PAYROLE_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'first_name',
        unSortIcon: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: true,
        cellRenderer: avatar_component_1.default,
        cellRendererSelector: function (params) {
            return {
                component: avatar_component_1.default,
                params: {
                    image: params.data.avatar,
                    fName: params.data && params.data.first_name,
                    lName: params.data && params.data.last_name,
                    className: 'avatar',
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE ID',
        field: 'feid',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'WORKING DAYS',
        field: 'workingdays',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'TOTAL KM',
        field: 'totalkm',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL AMOUNT',
        field: 'actualAmount',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'INCENTIVES1',
        field: 'incentives',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'INCENTIVES2',
        field: 'incentives',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'DEDUCTION',
        field: 'deduction',
        unSortIcon: true,
        cellRenderer: update_payroll_deduction_1.default,
        cellRendererSelector: function (params) {
            return {
                component: update_payroll_deduction_1.default,
                params: {
                    buttonName: 'Update',
                    selectedDetails: params.data,
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'NET PAY',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'STATUS',
        field: 'status',
        cellRenderer: button_component_1.default,
        cellRendererSelector: function (params) {
            return {
                component: button_component_1.default,
                params: { buttonName: 'Pending' },
            };
        },
    },
];
var CONTROL_TOWER_FEDETAILS_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'FE Name',
        field: 'first_name',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'MOBILE NUMBER',
        field: 'workingdays',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'EMPLOYMENT TYPE',
        field: 'totalkm',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'SERVICE TYPE',
        field: 'actualAmount',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'CITY',
        field: 'incentives',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'STATE',
        field: 'incentives',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'ORDERS ASSIGNED',
        field: 'incentives',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'FE CANCELLED ORDERS',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'CANCELLED',
        field: 'status',
        cellRenderer: button_component_1.default,
        cellRendererSelector: function (params) {
            return {
                component: button_component_1.default,
                params: { buttonName: 'Pending' },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'PENDING',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'PENDING (%)',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'DELAY (%)',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'SELF ORDERS',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'LOGGED IN STATUS',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'LEAVES',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'PRODUCTIVE DAYS',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'PRIME SLOTS',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'REGULAR SLOTS',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'PRIME ORDERS',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'REGULAR ORDERS',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'FIRST SLOT',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'IS FIRST SLOT ON TIME',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'AVERAGE RATING',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'TOTAL NO OF RATING',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'TOTAL NO OF +VE RATING',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: ' TOTAL NO OF -VE RATING',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'NPS SCORE',
        field: 'netpay',
        unSortIcon: true,
    },
];
var AUDITMODULE_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'AUDIT TITLE',
        field: 'name',
        tooltipField: 'name',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: checkbox_component_1.default,
        cellRendererSelector: function (params) {
            return {
                component: checkbox_component_1.default,
                params: {
                    checked: params.data.is_favourite,
                    label: params.data.name,
                    key: params.data.audit_id,
                    name: 'bookmark',
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'USERS(AUDITEE)',
        field: 'audities',
        sortable: false,
        cellRenderer: AuditUsers_1.default,
        cellRendererSelector: function (params) {
            return {
                component: AuditUsers_1.default,
                params: {
                    users: params.data.users,
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'AUDIT TYPE',
        field: 'audit_type',
        tooltipField: 'name',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) { return params.data && (0, array_helpers_1.isFieldValueEmpty)(params.data.audit_type); }
    },
    {
        lockPosition: 'left',
        headerName: 'STATUS',
        field: 'status',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: status_buttons_1.default,
        cellRendererSelector: function (params) {
            return {
                component: audit_status_button_1.default,
                params: {
                    status: params.data.status,
                },
                name: 'auditStatus',
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'QUESTIONS',
        field: 'questions_count',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (parseInt(valueA.toString()) === parseInt(valueB.toString())) {
                return 0;
            }
            return parseInt(valueA.toString()) > parseInt(valueB.toString()) ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'START DATE',
        field: 'start_date',
        unSortIcon: true,
        cellRenderer: function (params) { return params.data && (0, array_helpers_1.dateFormatter)(params.data.start_date); },
        comparator: dateComparator,
    },
    {
        lockPosition: 'left',
        headerName: 'END DATE',
        field: 'end_date',
        unSortIcon: true,
        cellRenderer: function (params) { return params.data && (0, array_helpers_1.dateFormatter)(params.data.end_date); },
        comparator: dateComparator,
    },
    {
        headerName: 'ACTION',
        cellRenderer: multi_icon_button_1.default,
        sortable: false,
        cellRendererSelector: function (params) {
            var auditFeatures = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.audit);
            return {
                component: multi_icon_button_1.default,
                params: {
                    cloneRowHandler: function (id) { return params.data.cloneHandler(id); },
                    audit_id: params.data.audit_id,
                    name: 'AuditActions',
                    status: params.data.status,
                    isDeleteDisable: !auditFeatures[menu_constants_1.FEATURE_CONSTANTS.auditDelete],
                    isEditDisable: !auditFeatures[menu_constants_1.FEATURE_CONSTANTS.auditDelete],
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CREATED BY',
        field: 'created_by',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.created_by);
        },
    },
];
var ASSIGN_USERSTO_AUDIT = [
    {
        lockPosition: 'left',
        headerName: 'AUDIT NAME',
        field: 'name',
        unSortIcon: true,
        headerCheckboxSelection: false,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
    },
    {
        lockPosition: 'left',
        headerName: 'AUDIT DESCRIPTION',
        field: 'description',
        sortable: false,
    },
    {
        lockPosition: 'left',
        headerName: 'NO OF QUESTIONS',
        field: 'questions_count',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'CREATION DATE',
        field: 'created_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateFormatter)(params.data.created_date_time);
        },
    },
];
var FE_LEAVE_TRACKING_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'first_name',
        width: 400,
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: avatar_component_1.default,
        cellRendererSelector: function (params) {
            return {
                component: avatar_component_1.default,
                params: {
                    image: params.data.avatar,
                    className: 'avatar',
                    fName: params.data && params.data.first_name.trim(),
                    lName: params.data && params.data.last_name.trim(),
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE ID',
        field: 'fe_id',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_id); },
    },
    {
        lockPosition: 'left',
        headerName: 'STATE',
        field: 'state',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.state); },
    },
    {
        lockPosition: 'left',
        headerName: 'CITY',
        field: 'city',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.city); },
    },
    {
        lockPosition: 'left',
        headerName: 'MOBILE NUMBER',
        field: 'mobile',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.mobile); },
    },
    {
        lockPosition: 'left',
        headerName: 'FROM DATE',
        field: 'from_date',
        unSortIcon: true,
        cellRenderer: function (params) { return params.data && (0, array_helpers_1.dateFormatter)(params.data.from_date); },
    },
    {
        lockPosition: 'left',
        headerName: 'TODATE',
        field: 'to_date',
        unSortIcon: true,
        cellRenderer: function (params) { return params.data && (0, array_helpers_1.dateFormatter)(params.data.to_date); },
    },
    {
        lockPosition: 'left',
        headerName: 'APPLIED DATE',
        field: 'applied_date',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateFormatter)(params.data.applied_date);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TOTAL DAYS',
        field: 'total_days',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.total_days);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REASON',
        field: 'reason',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.reason); },
    },
    {
        lockPosition: 'left',
        headerName: 'COMMENTS',
        field: 'comment',
        tooltipField: 'comment',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase().trim() === valueB.toLowerCase().trim()) {
                return 0;
            }
            return valueA.toLowerCase().trim() > valueB.toLowerCase().trim() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.comment.trim());
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STATUS',
        field: 'status',
        unSortIcon: true,
        pinned: 'right',
        cellRenderer: dual_button_1.default,
        cellRendererSelector: function (params) {
            return {
                component: dual_button_1.default,
                params: {
                    buttonName: params.data.status,
                    selectedFeDetails: params.data,
                    toDate: params.data.to_date,
                },
            };
        },
    },
];
var FE_ONLEAVE_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'first_name',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase().trim() === valueB.toLowerCase().trim()) {
                return 0;
            }
            return valueA.toLowerCase().trim() > valueB.toLowerCase().trim() ? 1 : -1;
        },
        cellRenderer: avatar_component_1.default,
        cellRendererSelector: function (params) {
            return {
                component: avatar_component_1.default,
                params: {
                    image: params.data.avatar,
                    className: 'avatar',
                    fName: params.data && params.data.first_name,
                    lName: params.data && params.data.last_name,
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE ID',
        field: 'fe_id',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_id); },
    },
    {
        lockPosition: 'left',
        headerName: 'CITY',
        field: 'city',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.city && params.data.city);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'MOBILE NUMBER',
        field: 'mobile',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.mobile); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA && valueA.toLowerCase() === valueB && valueB.toLowerCase()) {
                return 0;
            }
            return valueA && valueA.toLowerCase() > valueB && valueB.toLowerCase()
                ? 1
                : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FROM DATE',
        field: 'from_date',
        unSortIcon: true,
        cellRenderer: function (params) { return params.data && (0, array_helpers_1.dateFormatter)(params.data.from_date); },
    },
    {
        lockPosition: 'left',
        headerName: 'TODATE',
        field: 'to_date',
        unSortIcon: true,
        cellRenderer: function (params) { return params.data && (0, array_helpers_1.dateFormatter)(params.data.to_date); },
    },
    {
        lockPosition: 'left',
        headerName: 'APPLIED DATE',
        field: 'applied_date',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateFormatter)(params.data.applied_date);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TOTAL DAYS',
        field: 'total_days',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA === valueB) {
                return 0;
            }
            return valueA > valueB ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.total_days);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REASON',
        field: 'reason',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.reason); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'COMMENTS',
        field: 'comment',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.comment); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STATUS',
        field: 'status',
        pinned: 'right',
        unSortIcon: true,
        cellRenderer: dual_button_1.default,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRendererSelector: function (params) {
            return {
                component: dual_button_1.default,
                params: {
                    buttonName: params.data.status,
                    selectedFeDetails: params.data,
                },
            };
        },
    },
];
var date = new Date();
var FE_LEAVE_TRACKING_ROWS = [
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'B',
        FEID: 40,
        LOCATION: 'HYD',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 10,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'C',
        FEID: 30,
        LOCATION: 'HYD',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 30,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'D',
        FEID: 40,
        LOCATION: 'HYD',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'F',
        FEID: 20,
        LOCATION: 'HYD',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
    {
        first_name: 'A',
        FEID: 50,
        LOCATION: 'Bangaluru',
        MOBILENUMBER: '123456789',
        FROMDATE: date,
        TODATE: date,
        APPLIEDDATE: date,
        TOTALDAYS: 20,
        REASON: 'Sick',
        COMMENTS: 'NA',
        STATUS: 'STATUS',
    },
];
var KPI_LEAVES_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'FROM DATE',
        field: 'from_date',
        unSortIcon: true,
        cellRenderer: function (params) { return params.data && (0, array_helpers_1.dateFormatter)(params.data.from_date); },
    },
    {
        lockPosition: 'left',
        headerName: 'TODATE',
        field: 'to_date',
        unSortIcon: true,
        cellRenderer: function (params) { return params.data && (0, array_helpers_1.dateFormatter)(params.data.to_date); },
    },
    {
        lockPosition: 'left',
        headerName: 'DAYS',
        field: 'total_days',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'STATUS',
        field: 'status',
        unSortIcon: true,
    },
];
var KPI_LEAVES_ROWS = [
    {
        fromdate: date,
        todate: date,
        days: 1,
        status: 'Active',
    },
    {
        fromdate: date,
        todate: date,
        days: 2,
        status: 'Active',
    },
    {
        fromdate: date,
        todate: date,
        days: 3,
        status: 'Active',
    },
];
var ALL_DEDUTION_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'DATE FO DEDUCTION',
        field: 'dateOfDeduction',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateFormatter)(params.data.dateOfDeduction);
        },
        width: 200,
    },
    {
        lockPosition: 'left',
        headerName: 'DEDUCTION AMOUNT',
        field: 'daductionAmount',
        unSortIcon: true,
        width: 200,
    },
    {
        lockPosition: 'left',
        headerName: 'REASON',
        field: 'reason',
        unSortIcon: true,
        width: 200,
    },
    {
        lockPosition: 'left',
        headerName: 'OTHER COMMENTS',
        field: 'otherComments',
        unSortIcon: true,
        width: 200,
    },
    {
        lockPosition: 'left',
        headerName: 'OTHER COMMENTS',
        field: 'otherComments',
        unSortIcon: true,
        width: 200,
    },
    {
        lockPosition: 'left',
        headerName: 'OTHER COMMENTS',
        field: 'otherComments',
        unSortIcon: true,
        width: 200,
    },
    {
        lockPosition: 'left',
        headerName: 'OTHER COMMENTS',
        field: 'otherComments',
        unSortIcon: true,
        width: 200,
    },
];
var ALL_DEDUTION_ROWS = [
    {
        dateOfDeduction: date,
        daductionAmount: date,
        reason: 'Pertrol',
        otherComments: 'Bag Damaged By FE',
    },
    {
        dateOfDeduction: date,
        daductionAmount: date,
        reason: 'Pertrol',
        otherComments: 'Bag Damaged By FE',
    },
    {
        dateOfDeduction: date,
        daductionAmount: date,
        reason: 'Pertrol',
        otherComments: 'Bag Damaged By FE',
    },
    {
        dateOfDeduction: date,
        daductionAmount: date,
        reason: 'Pertrol',
        otherComments: 'Bag Damaged By FE',
    },
];
var SLOAT_BLOCKING_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'feName',
        tooltipField: 'feName',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.feName); },
    },
    {
        lockPosition: 'left',
        headerName: 'WEEK DAY(S)',
        field: 'weekdays',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.weekdays);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'SLOTS',
        field: 'slots',
        sortable: false,
        cellRenderer: SlotsComponent_1.default,
    },
    {
        lockPosition: 'left',
        headerName: 'CREATED BY',
        field: 'createdBy',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.createdBy);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'UPDATED DATE',
        field: 'updatedDate',
        unSortIcon: true,
        cellRenderer: function (params) { return params.data && (0, array_helpers_1.dateFormatter)(params.data.updatedDate); },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTION',
        sortable: false,
        cellRenderer: working_hours_btns_1.default,
        cellRendererSelector: function (params) {
            return {
                component: working_hours_btns_1.default,
                params: {
                    userData: params.data,
                },
            };
        },
    },
];
var CC_TIME_SLOT = [
    {
        lockPosition: 'left',
        headerName: 'WAREHOUSE CODE',
        field: 'warehouse_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.warehouse_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CENTER NAME',
        field: 'center_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'INVOICE CODE',
        field: 'invoice_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.invoice_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CENTER CITY',
        field: 'center_city',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_city);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CENTER STATE',
        field: 'center_state',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_state);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ROUTING ASSIGNMENT',
        field: 'routing_assignment',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.routing_assignment);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'HANDOVER SAMPLES TO LAB',
        field: 'can_handover_to_lab',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.trueFalseCoverter)(params.data && params.data.can_handover_to_lab);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CENTER TYPE',
        field: 'center_type',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'AX STATUS',
        field: 'ax_center_status',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.ax_center_status);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CREATED DATE TIME',
        field: 'created_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.created_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FIRST ROSTER UPDATED ON',
        field: 'first_roster_updated_on',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.first_roster_updated_on);
        },
    },
];
var FE_IN_ACTIVE_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'first_name',
        width: 400,
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: avatar_component_1.default,
        cellRendererSelector: function (params) {
            return {
                component: avatar_component_1.default,
                params: {
                    image: params.data.avatar,
                    className: 'avatar',
                    fName: params.data && params.data.first_name,
                    lName: params.data && params.data.last_name,
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE ID',
        field: 'fe_id',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_id); },
    },
    {
        lockPosition: 'left',
        headerName: 'MOBILE NUMBER',
        field: 'mobile',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.mobile); },
    },
    {
        lockPosition: 'left',
        headerName: 'IN-ACTIVE DATE',
        field: 'updated_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateFormatter)(params.data.updated_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB STATE',
        field: 'state',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.state); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CITY',
        field: 'city',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.city); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE WORKING STATE',
        field: 'working_state',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab && params.data.working_state);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE WORKING CITY',
        field: 'working_city',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab && params.data.working_city);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab.lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab && params.data.lab.lab_code);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'BASE LOCATION',
        field: 'base_location_address',
        tooltipField: 'base_location_address',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.base_location_address);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'VENDOR NAME',
        field: 'vendor_name',
        tooltipField: 'vendor_name',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.vendor_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REPLACED FE ',
        field: 'is_replaced_fe',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.is_replaced_fe);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REPLACED FE NAME ',
        field: 'replacement_fe_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.replacement_fe_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REGIONAL MANAGER',
        field: 'regional_manager1',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.regional_manager1);
        },
    },
];
var ROLE_LIST_COLUMN = [
    {
        lockPosition: 'left',
        headerName: 'ROLE NAME',
        field: 'name',
        width: 230,
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.name); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'DESCRIPTION',
        field: 'description',
        tooltipField: 'description',
        width: 230,
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.description);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CREATED ON',
        field: 'created_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateFormatter)(params.data.created_date_time);
        },
        width: 230,
    },
    {
        lockPosition: 'left',
        headerName: 'MODIFIED ON',
        field: 'modified_on',
        cellRenderer: function (params) { return params.data && (0, array_helpers_1.dateFormatter)(params.data.modified_on); },
        width: 230,
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'MODIFIED BY',
        field: 'modified_by',
        tooltipField: 'modified_by',
        unSortIcon: true,
        width: 230,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.modified_by);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        headerName: 'ACTION',
        lockPosition: 'left',
        width: 145,
        sortable: false,
        cellRenderer: multi_icon_button_1.default,
        cellRendererSelector: function (params) {
            var roleManagementFeatures = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.roleManagement);
            return {
                component: multi_icon_button_1.default,
                params: {
                    isDeleteDisable: !roleManagementFeatures[menu_constants_1.FEATURE_CONSTANTS.roleDelete],
                    isEditDisable: !roleManagementFeatures[menu_constants_1.FEATURE_CONSTANTS.roleEdit],
                    user_id: params.data.id,
                    userName: params.data.name,
                    endPoint: api_service_1.api_service.api_urls.ROLE_LIST,
                    domain: process.env.ONEX_VIEW_SUPER_ADMIN_BASE_URL,
                },
            };
        },
    },
];
var APPROVAL_ROWS = [
    {
        fname: 'Suresh',
        lname: 'Kumar',
    },
];
var VENDOR_COLUMNS = [
    {
        headerName: 'VENDOR NAME',
        field: 'name',
        tooltipField: 'name',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.name); },
        width: 230,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            console.log(valueA, '****', valueB);
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        headerName: 'AXAPTA CODE',
        field: 'axapta_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.axapta_code);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        headerName: 'CONTACT PERSON',
        field: 'contact_name',
        tooltipField: 'contact_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.contact_name);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        headerName: 'MOBILE NUMBER',
        field: 'contact_mobile',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.contact_mobile);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        headerName: 'E-MAIL',
        field: 'contact_email',
        tooltipField: 'contact_email',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.contact_email);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        headerName: 'OPERATION STATE',
        field: 'operating_places',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            console.log('valueA:', valueA, 'valueB:', valueB);
            if (valueA.length === 0 && valueB.length === 0) {
                return 0;
            }
            else {
                if (valueA[0] && valueB[0]) {
                    if (valueA[0].state_name === '' && valueB[0].state_name === '') {
                        return 0;
                    }
                    else if (valueA[0].state_name === '') {
                        return -1;
                    }
                    else if (valueB[0].state_name === '') {
                        return 1;
                    }
                    else if (valueA[0].state_name.toLowerCase() === valueB[0].state_name.toLowerCase()) {
                        return 0;
                    }
                    return valueA[0].state_name.toLowerCase() >
                        valueB[0].state_name.toLowerCase()
                        ? 1
                        : -1;
                }
                else if (valueA.length === 0) {
                    return -1;
                }
                else {
                    return 1;
                }
            }
        },
        cellRenderer: tool_tip_1.default,
        cellRendererSelector: function (params) {
            return {
                component: tool_tip_1.default,
                params: {
                    stateCityData: params.data.operating_places,
                    isState: 'state',
                },
            };
        },
    },
    {
        headerName: 'OPERATION CITY',
        field: 'operating_places',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            console.log('valueA:', valueA, 'valueB:', valueB[0]);
            if (valueA.length === 0 && valueB.length === 0) {
                return 0;
            }
            else {
                if (valueA[0] && valueB[0]) {
                    if (valueA[0].city_name === '' && valueB[0].city_name === '') {
                        return 0;
                    }
                    else if (valueA[0].city_name === '') {
                        return -1;
                    }
                    else if (valueB[0].city_name === '') {
                        return 1;
                    }
                    else if (valueA[0].city_name.toLowerCase() === valueB[0].city_name.toLowerCase()) {
                        return 0;
                    }
                    return valueA[0].city_name.toLowerCase() >
                        valueB[0].city_name.toLowerCase()
                        ? 1
                        : -1;
                }
                else if (valueA.length === 0) {
                    return -1;
                }
                else {
                    return 1;
                }
            }
        },
        cellRenderer: tool_tip_1.default,
        cellRendererSelector: function (params) {
            return {
                component: tool_tip_1.default,
                params: {
                    stateCityData: params.data.operating_places,
                    isState: 'city',
                },
            };
        },
    },
    {
        headerName: "ACTIVE FE'S",
        field: 'active_fes',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.active_fes);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
];
var VENDOR_ROWS = [
    {
        Vname: ' CONCORD LOGISTICS & SERVICES',
        ContactPerson: 'Rami Reddy',
        MobileNumber: '91910000xx',
        Email: 'Email',
        OperatingState: 'Telangana +2',
        OperatingCity: 'HYD',
        ActiveFEs: ' Hyderabad +7',
    },
    {
        Vname: ' CREDENT COLD CHAIN LOGISTICS PVT. LTD.',
        ContactPerson: 'Vara Prasad',
        MobileNumber: '91910000xx',
        Email: 'Email',
        OperatingState: 'Pan India',
        OperatingCity: 'Pan India',
        ActiveFEs: 'All',
    },
];
var SAMPLE_TRACKING_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'REFERENCE NO',
        field: 'reference_number',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.reference_number);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'BAG ID',
        field: 'bag_barcode',
        tooltipField: 'bag_barcode',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.bag_barcode);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB NUMBER',
        field: 'lab_number',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_number);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CREATED DATE TIME',
        field: 'created_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.created_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CENTRE CODE',
        field: 'centre_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.centre_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CENTRE NAME',
        field: 'center_name',
        tooltipField: 'center_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CURRENT STATUS',
        field: 'status',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.status); },
    },
    {
        lockPosition: 'left',
        headerName: 'CURRENT STATE',
        field: 'state',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data && params.data.state);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CURRENT CITY',
        field: 'city',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data && params.data.city);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CURRENT BRANCH',
        field: 'branch',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data && params.data.branch);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CURRENT USERNAME',
        field: 'current_user_name',
        tooltipField: 'current_user_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.current_user_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTIONS',
        field: 'route',
        pinned: 'right',
        cellRenderer: multi_icon_button_1.default,
        cellRendererSelector: function (params) {
            return {
                component: view_1.default,
            };
        },
    },
];
var USER_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'EMPLOYEE NAME',
        field: 'fullName',
        tooltipField: 'fullName',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fullName);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ROLE',
        field: 'role',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            console.log('valueA:', valueA, 'valueB:', valueB[0]);
            if (valueA.length === 0 && valueB.length === 0) {
                return 0;
            }
            else {
                if (valueA[0] && valueB[0]) {
                    if (valueA[0] === '' && valueB[0] === '') {
                        return 0;
                    }
                    else if (valueA[0] === '') {
                        return -1;
                    }
                    else if (valueB[0] === '') {
                        return 1;
                    }
                    else if (valueA[0].toLowerCase() === valueB[0].toLowerCase()) {
                        return 0;
                    }
                    return valueA[0].toLowerCase() > valueB[0].toLowerCase() ? 1 : -1;
                }
                else if (valueA.length === 0) {
                    return -1;
                }
                else {
                    return 1;
                }
            }
        },
        cellRenderer: UserListTooltip_1.default,
        cellRendererSelector: function (params) {
            return {
                component: UserListTooltip_1.default,
                params: {
                    tooltipData: params.data.role.split(','),
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'USER NAME',
        field: 'userName',
        tooltipField: 'userName',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.userName);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LOCATION',
        field: 'location',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            console.log('valueA:', valueA, 'valueB:', valueB[0]);
            if (valueA.length === 0 && valueB.length === 0) {
                return 0;
            }
            else {
                if (valueA[0] && valueB[0]) {
                    if (valueA[0] === '' && valueB[0] === '') {
                        return 0;
                    }
                    else if (valueA[0] === '') {
                        return -1;
                    }
                    else if (valueB[0] === '') {
                        return 1;
                    }
                    else if (valueA[0].toLowerCase() === valueB[0].toLowerCase()) {
                        return 0;
                    }
                    return valueA[0].toLowerCase() > valueB[0].toLowerCase() ? 1 : -1;
                }
                else if (valueA.length === 0) {
                    return -1;
                }
                else {
                    return 1;
                }
            }
        },
        cellRenderer: UserListTooltip_1.default,
        cellRendererSelector: function (params) {
            return {
                component: UserListTooltip_1.default,
                params: {
                    tooltipData: params.data.location.split(','),
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'BRANCH',
        field: 'fe_id',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_id); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'E-MAIL',
        field: 'email',
        tooltipField: 'email',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.email); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'MOBILE NUMBER',
        field: 'mobile',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.mobile); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
];
var ETRF_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'REFERENCE NO',
        field: '_id',
        unSortIcon: true,
        width: 300,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data._id); },
    },
    {
        lockPosition: 'left',
        headerName: 'BAR CODE',
        field: 'etrf_barcode_id',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.etrf_barcode_id);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'DURATION',
        field: 'duration',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.duration);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STATUS',
        field: 'status',
        unSortIcon: true,
        valueFormatter: array_helpers_1.convertETRFStatus,
    },
    {
        lockPosition: 'left',
        headerName: 'FIELD EXECUTIVE',
        field: 'fe_user',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_user); },
    },
    {
        lockPosition: 'left',
        headerName: 'STATE',
        field: 'state',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.state); },
    },
    {
        lockPosition: 'left',
        headerName: 'CITY',
        field: 'city',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.city); },
    },
    {
        lockPosition: 'left',
        headerName: 'CREATED ON',
        field: 'created_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.created_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'UPDATED ON',
        field: 'updated_on',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.updated_on);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CENTER CODE',
        field: 'centre_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.centre_code);
        },
    },
];
var ETRF_ROWS = [
    {
        reference_no: '581633/96705/16672770936',
        runsheet: 'LPL/EHF4_LPL/2022/04/07',
        duration: '8 days ago',
        status: 'Proceed',
        fe_id: 'EHF4_LPL/JITENDER',
        branch: 'East Delhi',
        created_on: '2022-11-01',
        updated_on: '2022/11/01',
        slot: 0,
        jobpriority: 0,
    },
    {
        reference_no: '581633/96705/16672770936',
        runsheet: 'LPL/EHF4_LPL/2022/04/07',
        duration: '8 days ago',
        status: 'Proceed',
        fe_id: 'EHF4_LPL/JITENDER',
        branch: 'East Delhi',
        created_on: '2022-11-01',
        updated_on: '2022/11/01',
        slot: 0,
        jobpriority: 0,
    },
    {
        reference_no: '581633/96705/16672770936',
        runsheet: 'LPL/EHF4_LPL/2022/04/07',
        duration: '8 days ago',
        status: 'Proceed',
        fe_id: 'EHF4_LPL/JITENDER',
        branch: 'East Delhi',
        created_on: '2022-11-01',
        updated_on: '2022/11/01',
        slot: 0,
        jobpriority: 0,
    },
    {
        reference_no: '581633/96705/16672770936',
        runsheet: 'LPL/EHF4_LPL/2022/04/07',
        duration: '8 days ago',
        status: 'Proceed',
        fe_id: 'EHF4_LPL/JITENDER',
        branch: 'East Delhi',
        created_on: '2022-11-01',
        updated_on: '2022/11/01',
        slot: 0,
        jobpriority: 0,
    },
    {
        reference_no: '581633/96705/16672770936',
        runsheet: 'LPL/EHF4_LPL/2022/04/07',
        duration: '8 days ago',
        status: 'Proceed',
        fe_id: 'EHF4_LPL/JITENDER',
        branch: 'East Delhi',
        created_on: '2022-11-01',
        updated_on: '2022/11/01',
        slot: 0,
        jobpriority: 0,
    },
];
var USER_LIST_ROW = [
    {
        employee_name: 'Rami Reddy',
        role: 'Super Admin',
        application: 'FE Management',
        user_id: 'User ID',
        location: 'HYD',
        email: 'EMAIL',
        mobile: '202-555-xxxx',
    },
];
var SUPER_ADMIN_APPROVAL_LIST_COLUMN = [
    {
        lockPosition: 'left',
        headerName: 'FE ID',
        field: 'fe_id',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_id); },
    },
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'name',
        tooltipField: 'name',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.name); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            console.log(valueA, '****', valueB);
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'MOBILE NUMBER',
        field: 'mobile',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.mobile); },
    },
    {
        lockPosition: 'left',
        headerName: 'FE JOINING TYPE',
        field: 'fe_joining_type',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_joining_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'USER TYPE',
        field: 'user_type',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.user_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REPLACED FE ',
        field: 'is_replaced_fe',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.is_replaced_fe);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REPLACED FE NAME ',
        field: 'replacement_fe_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.replacement_fe_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REPLACED FE ID',
        field: 'replacement_fe_id',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.replacement_fe_id);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REPLACED FE MOBILE',
        field: 'replacement_fe_mobile',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.replacement_fe_mobile);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CREATED ON',
        field: 'created_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateFormatter)(params.data.created_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'MODIFIED ON',
        field: 'updated_date_time',
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateFormatter)(params.data.updated_date_time);
        },
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'MODIFIED BY',
        field: 'vendor_name',
        tooltipField: 'vendor_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.vendor_name);
        },
    },
];
var VENDOR_DRAFTED_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'VENDOR NAME',
        field: 'name',
        tooltipField: 'name',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.name); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase().trim() === valueB.toLowerCase().trim()) {
                return 0;
            }
            return valueA.toLowerCase().trim() > valueB.toLowerCase().trim() ? 1 : -1;
        },
        width: 430,
    },
    {
        lockPosition: 'left',
        headerName: 'STATUS',
        field: 'onboard_status',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.onboard_status);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA && valueA.toLowerCase() === valueB && valueB.toLowerCase()) {
                return 0;
            }
            return valueA && valueA.toLowerCase() > valueB && valueB.toLowerCase()
                ? 1
                : -1;
        },
        width: 430,
    },
    {
        headerName: 'ACTION',
        lockPosition: 'left',
        width: 430,
        sortable: false,
        cellRenderer: alert_modal_1.default,
        cellRendererSelector: function (params) {
            var vendorManagementFeatures = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.vendorManagement);
            return {
                component: alert_modal_1.default,
                params: {
                    alertIcon: 'icon-warning',
                    icon: 'icon-delete_forever',
                    isIcondisabled: !vendorManagementFeatures[menu_constants_1.FEATURE_CONSTANTS.vendorDelete],
                    modalTitle: "Are you sure  you want to delete ".concat(params.data.name, "?"),
                    endPoint: api_service_1.api_service.api_urls.DELETE_VENDOR,
                    domain: process.env.ONE_TWO_LEVEL_APPROVAL_URL,
                    payLoad: { vendor_id: params.data.id },
                    onClickHandler: function () {
                        (0, draft_vendors_1.deleteDraftedVednor)(params.data.id);
                    },
                    onCloseHandler: draft_vendors_1.colseVendorModalHandler,
                },
            };
        },
    },
];
var VENDOR_EXIT_COLUMNS = [
    {
        headerName: 'VENDOR NAME',
        field: 'name',
        tooltipField: 'name',
        unSortIcon: true,
        width: 230,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.name); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        headerName: 'AXAPTA CODE',
        field: 'axapta_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.axapta_code);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        headerName: 'CONTACT PERSON',
        field: 'contact_name',
        tooltipField: 'contact_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.contact_name);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        headerName: 'MOBILE NUMBER',
        field: 'contact_mobile',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.contact_mobile);
        },
    },
    {
        headerName: 'E-MAIL',
        field: 'contact_email',
        tooltipField: 'contact_email',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.contact_email);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        headerName: 'OPERATION STATE',
        field: 'operating_places',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.length === 0 && valueB.length === 0) {
                return 0;
            }
            else {
                if (valueA[0] && valueB[0]) {
                    if (valueA[0].state_name === '' && valueB[0].state_name === '') {
                        return 0;
                    }
                    else if (valueA[0].state_name === '') {
                        return -1;
                    }
                    else if (valueB[0].state_name === '') {
                        return 1;
                    }
                    else if (valueA[0].state_name.toLowerCase() === valueB[0].state_name.toLowerCase()) {
                        return 0;
                    }
                    return valueA[0].state_name.toLowerCase() >
                        valueB[0].state_name.toLowerCase()
                        ? 1
                        : -1;
                }
                else if (valueA.length === 0) {
                    return -1;
                }
                else {
                    return 1;
                }
            }
        },
        cellRenderer: tool_tip_1.default,
        cellRendererSelector: function (params) {
            return {
                component: tool_tip_1.default,
                params: {
                    stateCityData: params.data.operating_places,
                    isState: 'state',
                },
            };
        },
    },
    {
        headerName: 'OPERATION CITY',
        field: 'operating_places',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            console.log('valueA:', valueA, 'valueB:', valueB[0]);
            if (valueA.length === 0 && valueB.length === 0) {
                return 0;
            }
            else {
                if (valueA[0] && valueB[0]) {
                    if (valueA[0].city_name === '' && valueB[0].city_name === '') {
                        return 0;
                    }
                    else if (valueA[0].city_name === '') {
                        return -1;
                    }
                    else if (valueB[0].city_name === '') {
                        return 1;
                    }
                    else if (valueA[0].city_name.toLowerCase() === valueB[0].city_name.toLowerCase()) {
                        return 0;
                    }
                    return valueA[0].city_name.toLowerCase() >
                        valueB[0].city_name.toLowerCase()
                        ? 1
                        : -1;
                }
                else if (valueA.length === 0) {
                    return -1;
                }
                else {
                    return 1;
                }
            }
        },
        cellRenderer: tool_tip_1.default,
        cellRendererSelector: function (params) {
            return {
                component: tool_tip_1.default,
                params: {
                    stateCityData: params.data.operating_places,
                    isState: 'city',
                },
            };
        },
    },
];
var CONTROL_CENTER_SAMPLELIST_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'SAMPLE ID',
        field: 'first_name',
        unSortIcon: true,
        cellRenderer: avatar_component_1.default,
        cellRendererSelector: function (params) {
            return {
                component: avatar_component_1.default,
                params: {
                    image: params.data.avatar,
                    fName: params.data && params.data.first_name,
                    lName: params.data && params.data.last_name,
                    className: 'avatar',
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'PATIENT NAME',
        field: 'feid',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'MOBILE NUMBER',
        field: 'workingdays',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'PATIENT TYPE',
        field: 'totalkm',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'SAMPLE AMOUNT',
        field: 'actualAmount',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'VISIT DATE',
        field: 'incentives',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'SLOT TIME',
        field: 'incentives',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'TASK STATUS',
        field: 'deduction',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'FE START TIME',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'FE REACHED TIME',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'FE NUMBER',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'CURRENT ADDRESS',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'TASK SOURCE',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'ASSIGNED FE',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'REMARKS',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'FE RATING',
        field: 'netpay',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'FE RATING COMMENT',
        field: 'status',
    },
    {
        headerName: 'ACTIONS',
        field: 'route',
        unSortIcon: true,
        cellRenderer: edit_icon_1.default,
        cellRendererSelector: function (params) {
            return {
                component: edit_icon_1.default,
            };
        },
    },
];
var CASH_COLLECTION_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'CASH ID',
        field: 'cash_id',
        tooltipField: 'cash_id',
        width: 350,
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.cash_id); },
    },
    {
        lockPosition: 'left',
        headerName: 'AX CODE',
        field: 'ax_code',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.ax_code); },
    },
    {
        lockPosition: 'left',
        headerName: 'CRE NAME',
        field: 'cre_name',
        tooltipField: 'cre_name',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.cre_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_code',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB NAME',
        field: 'lab_name',
        tooltipField: 'lab_name',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STATE',
        field: 'state',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.state); },
    },
    {
        lockPosition: 'left',
        headerName: 'CITY',
        field: 'city',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.city); },
    },
    {
        lockPosition: 'left',
        headerName: 'ZONE',
        field: 'zone',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.zone); },
    },
    {
        lockPosition: 'left',
        headerName: 'FE CODE',
        field: 'fe_id',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_id); },
    },
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'fe_name',
        tooltipField: 'fe_name',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_name); },
    },
    {
        lockPosition: 'left',
        headerName: 'STATUS',
        field: 'status',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.status); },
    },
    {
        lockPosition: 'left',
        headerName: 'INVOICE CODE',
        field: 'invoice_code',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.invoice_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT CODE',
        field: 'client_code',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.client_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT NAME',
        field: 'client_name',
        tooltipField: 'client_name',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.client_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CASH COLLECTED AMOUNT',
        field: 'cash_collected_amount',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.cash_collected_amount);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CASH COLLECTED DATE AND TIME',
        field: 'cash_collected_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.cash_collected_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CASH RECEIVED AMOUNT',
        field: 'cash_received_amount',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.cash_received_amount);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CASH RECEIVED DATE AND TIME',
        field: 'cash_received_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.cash_received_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CC HANDSHAKE DATE AND TIME',
        field: 'cc_handshake_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.cc_handshake_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CRE HANDSHAKE DATE AND TIME',
        field: 'cre_handshake_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.cre_handshake_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CASH POSTED IN AX DATE AND TIME',
        field: 'cash_posted_in_ax_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.cash_posted_in_ax_date_time);
        },
    },
];
var BAGGING_LIFECYCLE_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'REFERENCE NUMBER',
        field: 'reference_number',
        sortable: false,
        unSortIcon: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.reference_number);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB NUMBER',
        field: 'lab_number',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_number);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TUBE TYPE',
        field: 'tube_name',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.tube_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TUBE COLOUR CODE',
        field: 'tube_colour_code',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.tube_colour_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TUBE COLOUR',
        field: 'tube_color',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.tube_color);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'BAG ID',
        field: 'bag_barcode',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.bag_barcode);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'PRECIOUS SAMPLE',
        field: 'precious_sample',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.preciousSampleKey)(params.data && params.data.precious_sample);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CURRENT STATUS',
        field: 'status',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.status); },
    },
    {
        lockPosition: 'left',
        headerName: 'REGISTRATION TIME',
        field: 'registration_timestamp',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.registration_timestamp);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REGISTRATION STATUS AT THE BAGGING OF THE TIME',
        field: 'registration_at_the_time_of_bagging',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.registration_at_the_time_of_bagging);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CURRENT REGISTRATION STATUS',
        field: 'current_registration_status',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.current_registration_status);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REGISTRATION SOURCE',
        field: 'registration_source',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.registration_source);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'START PICKUP',
        field: 'fe_started_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.fe_started_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'SCHEDULED PICK-UP TIME',
        field: 'scheduled_pick_up_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.scheduled_pick_up_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REACHED',
        field: 'fe_reached_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data &&
                (0, array_helpers_1.dateTimeFormatterWithSeconds)(params.data.fe_reached_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT LATITUDE',
        field: 'reached_latitude',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.reached_latitude);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT LONGITUDE',
        field: 'reached_longitude',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.reached_longitude);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'BAG CREATION TIME',
        field: 'bag_creation_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.bag_creation_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'BAG CREATED BY',
        field: 'bag_created_by',
        tooltipField: 'bag_created_by',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.bag_created_by);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL PICK-UP TIME',
        field: 'actual_pick_up_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatterWithSeconds)(params.data.actual_pick_up_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TIME TAKEN IN PICK-UP',
        field: 'pickup_time_taken',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.pickup_time_taken);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'PICKUP DELAYED',
        field: 'is_pickup_delay',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.preciousSampleKey)(params.data && params.data.is_pickup_delay);
        },
        cellStyle: { textTransform: 'capitalize' },
    },
    {
        lockPosition: 'left',
        headerName: 'SCHEDULED HUB HANDOVER TIME',
        field: 'scheduled_hub_handover_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.scheduled_hub_handover_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL HUB HANDOVER TIME',
        unSortIcon: true,
        field: 'actual_hub_handover_time',
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.actual_hub_handover_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CONNECTOR FE CODE',
        field: 'connector_fe_code',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.connector_fe_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CONNECTOR FE NAME',
        field: 'connector_fe_name',
        tooltipField: 'connector_fe_name',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.connector_fe_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'SCHEDULED LAB HANDOVER TIME',
        field: 'scheduled_lab_handover_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.scheduled_lab_handover_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL LAB HANDOVER TIME',
        field: 'actual_lab_handover_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.actual_lab_handover_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB HANDOVER DELAYED',
        field: 'is_lab_handover_delay',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.processedkey)(params.data && params.data.is_lab_handover_delay);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'RECEIVED AT LAB TIME',
        field: 'lab_receiving_timestamp',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.lab_receiving_timestamp);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'NETWORK TYPE(FE TOWN/NON FE TOWN)',
        field: 'network_type',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.network_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ROUTE TYPE(FROM AX)',
        field: 'route_type',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.route_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'VISIT TYPE',
        field: 'visit_type',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.visit_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'VISIT ID',
        field: 'visit_ids',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) { return (0, array_helpers_1.arrayofData)(params.data && params.data.visit_ids); },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT CODE',
        field: 'client_code',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.client_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'INVOICE CODE',
        field: 'invoice_code',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.invoice_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT TYPE',
        field: 'center_type',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT NAME',
        field: 'center_name',
        tooltipField: 'center_name',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT STATE',
        field: 'center_state',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_state);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT CITY',
        field: 'center_city',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_city);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'PICKUP FE CODE',
        field: 'pickup_fe_code',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.pickup_fe_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'PICKUP FE NAME',
        field: 'pickup_fe_name',
        tooltipField: 'pickup_fe_name',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.pickup_fe_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'PICKUP FE STATE',
        field: 'pickup_fe_state',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.pickup_fe_state);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'PICKUP FE CITY',
        field: 'pickup_fe_city',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.pickup_fe_city);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'pickup_fe_lab_code',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.pickup_fe_lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB NAME',
        field: 'pickup_fe_lab_name',
        tooltipField: 'pickup_fe_lab_name',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.pickup_fe_lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ZONE',
        field: 'pickup_fe_zone',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.pickup_fe_zone);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'SAMPLE LAB CODE',
        field: 'sample_lab_code',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.sample_lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TIME SLOT',
        field: 'time_slot',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.time_slot);
        },
    },
];
var BAGGING_MASTER_COLUMNS = [
    {
        headerName: 'S.No',
        field: 'srno',
        unSortIcon: false,
        width: 80,
    },
    {
        headerName: 'Bag id',
        field: 'bag_id',
        unSortIcon: false,
        width: 230,
    },
    {
        headerName: 'Current Status',
        field: 'current_status',
        unSortIcon: false,
        width: 230,
    },
    {
        headerName: 'Executive Id',
        field: 'executive_id',
        unSortIcon: false,
        width: 230,
    },
    {
        headerName: 'Executive Name',
        field: 'executive_name',
        unSortIcon: false,
        width: 230,
    },
    {
        headerName: 'Date Time',
        field: 'date',
        unSortIcon: false,
        width: 230,
    },
    {
        headerName: 'Remarks',
        field: 'remarks',
        unSortIcon: false,
        width: 230,
    },
    {
        headerName: 'Action',
        field: 'action',
        unSortIcon: false,
        width: 230,
    },
];
var PRECIOUS_SAMPLE_MASTER_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'CPT-CODE',
        field: 'cpt_code',
        unSortIcon: true,
        width: 200,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TEST NO',
        field: 'test_no',
        tooltipField: 'test_no',
        unSortIcon: true,
        width: 200,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'SAMPLE TYPE',
        field: 'sample_type',
        width: 200,
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CONTAINER MAT CODE',
        field: 'container_mat_code',
        unSortIcon: true,
        width: 300,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TEST CAT CODE',
        field: 'test_cat_code',
        width: 200,
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTIONS',
        width: 200,
        sortable: false,
        cellRenderer: precious_sample_icons_1.default,
        cellRendererSelector: function (params) {
            var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.preciousSample);
            return {
                component: precious_sample_icons_1.default,
                params: {
                    userData: params.data,
                    isEditDisabled: features[menu_constants_1.FEATURE_CONSTANTS.editSample],
                    isDeleteDisabled: features[menu_constants_1.FEATURE_CONSTANTS.deleteSample],
                },
            };
        },
    },
];
var BAGGING_MASTER_ROW = [
    {
        srno: 1,
        bag_id: 'LP220800660915',
        current_status: 'NA',
        executive_id: 'NA',
        executive_name: 'NA',
        date: 'NA',
        remarks: 'NA',
        action: 'Details',
    },
    {
        srno: 2,
        bag_id: 'LP220800660915',
        current_status: 'NA',
        executive_id: 'NA',
        executive_name: 'NA',
        date: 'NA',
        remarks: 'NA',
        action: 'Details',
    },
    {
        srno: 3,
        bag_id: 'LP220800660915',
        current_status: 'NA',
        executive_id: 'NA',
        executive_name: 'NA',
        date: 'NA',
        remarks: 'NA',
        action: 'Details',
    },
];
var FE_LAB_MASTER_COLUMNS = [
    {
        headerName: 'S.No',
        field: 'srno',
        unSortIcon: false,
        width: 80,
    },
    {
        headerName: 'FE CODE',
        field: 'fe_code',
        unSortIcon: false,
        width: 230,
    },
    {
        headerName: 'FE NAME',
        field: 'fe_name',
        unSortIcon: false,
        width: 230,
    },
    {
        headerName: 'Reporting Lab Code',
        field: 'reporting_lab_code',
        unSortIcon: false,
        width: 230,
    },
    {
        headerName: 'State',
        field: 'state',
        unSortIcon: false,
        width: 230,
    },
    {
        headerName: ' City',
        field: 'city',
        unSortIcon: false,
        width: 230,
    },
    {
        headerName: 'IS Central Folder',
        field: 'is_central_folder',
        unSortIcon: false,
        width: 230,
    },
    {
        headerName: 'FE TYPE',
        field: 'fe_type',
        unSortIcon: false,
        width: 230,
    },
    {
        headerName: 'Average Km',
        field: 'average_km',
        unSortIcon: false,
        width: 230,
    },
    {
        headerName: 'ACTION',
        field: 'action',
        unSortIcon: false,
        width: 230,
    },
];
var FE_LAB_MASTER_ROWS = [
    {
        srno: 1,
        fe_code: 'lrl1285_lpl',
        fe_name: 'sk surabuddin',
        reporting_lab_code: 'L51',
        state: 'West Bengal',
        city: 'kolkata',
        is_central_folder: false,
        fe_type: 'FE',
        average_km: 100,
        action: 'Details',
    },
];
var LAB_MASTER_COLUMNS = [
    {
        headerName: 'S.No',
        field: 'srno',
        unSortIcon: false,
        width: 80,
    },
    {
        headerName: 'FE CODE',
        field: 'fe_code',
        unSortIcon: false,
        width: 230,
    },
    {
        headerName: 'Lab NAME',
        field: 'lab_name',
        unSortIcon: false,
        width: 230,
    },
    {
        headerName: 'Lab State',
        field: 'lab_state',
        unSortIcon: false,
        width: 230,
    },
    {
        headerName: 'Lab City',
        field: 'lab_city',
        unSortIcon: false,
        width: 230,
    },
    {
        headerName: 'ACTION',
        field: 'action',
        unSortIcon: false,
        width: 230,
    },
];
var LAB_MASTER_ROWS = [
    {
        srno: 1,
        fe_code: 'k20',
        lab_name: 'fortune hospital',
        lab_state: 'Uttar pradesh',
        lab_city: 'kanpur',
        action: 'Details',
    },
];
var DYNAMIC_ROUTE_MASTER_COLUMNS = [
    {
        headerName: 'S.No',
        field: 'srno',
        unSortIcon: false,
        width: 80,
    },
    {
        headerName: 'Unique Key',
        field: 'unique_key',
        unSortIcon: false,
        width: 230,
    },
    {
        headerName: 'Centre Code',
        field: 'centre_code',
        unSortIcon: false,
        width: 230,
    },
    {
        headerName: 'Centre Address',
        field: 'centre_address',
        unSortIcon: false,
        width: 230,
    },
    {
        headerName: 'Centre Contact',
        field: 'centre_contact',
        unSortIcon: false,
        width: 230,
    },
    {
        headerName: 'Centre Name',
        field: 'Centre_Name',
        unSortIcon: false,
        width: 230,
    },
    {
        headerName: 'ACTION',
        field: 'action',
        unSortIcon: false,
        width: 230,
    },
];
var DYNAMIC_ROUTE_MASTER_ROWS = [
    {
        srno: 1,
        unique_key: '1',
        centre_code: 'f140030',
        centre_address: 'mahadeva',
        centre_contact: 9560717119,
        Centre_Name: ' sangam collection centre',
        action: 'Details',
    },
];
var CASH_PENDING_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'CASH ID',
        field: 'cash_id',
        unSortIcon: false,
        sortable: false,
        width: 350,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.cash_id); },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_code',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB NAME',
        field: 'lab_name',
        tooltipField: 'lab_name',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STATE',
        field: 'state',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.state); },
    },
    {
        lockPosition: 'left',
        headerName: 'CITY',
        field: 'city',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.city); },
    },
    {
        lockPosition: 'left',
        headerName: 'ZONE',
        field: 'zone',
        sortable: false,
        unSortIcon: false,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.zone); },
    },
    {
        lockPosition: 'left',
        headerName: 'FE CODE',
        field: 'fe_id',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_id); },
    },
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'fe_name',
        tooltipField: 'fe_name',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_name); },
    },
    {
        lockPosition: 'left',
        headerName: 'STATUS',
        field: 'status',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.status); },
    },
    {
        lockPosition: 'left',
        headerName: 'INVOICE CODE',
        field: 'invoice_code',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.invoice_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT CODE',
        field: 'client_code',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.client_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT NAME',
        field: 'client_name',
        tooltipField: 'client_name',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.client_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CASH COLLECTED AMOUNT',
        field: 'cash_collected_amount',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.cash_collected_amount);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CASH COLLECTED DATE AND TIME',
        field: 'cash_collected_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.cash_collected_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CC HANDSHAKE DATE AND TIME',
        field: 'cc_handshake_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.cc_handshake_date_time);
        },
    },
];
var MIS_REPORTS_EVENT_PERFORMANCE = [
    {
        lockPosition: 'left',
        headerName: 'FE CODE',
        field: 'fe_code',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_code); },
    },
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'fe_name',
        tooltipField: 'fe_name',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_name); },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB NAME',
        field: 'lab_name',
        tooltipField: 'lab_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STATE',
        field: 'state',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.state); },
    },
    {
        lockPosition: 'left',
        headerName: 'CITY ',
        field: 'city',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.city); },
    },
    {
        lockPosition: 'left',
        headerName: 'VISIT DATE',
        field: 'visit_date',
        unSortIcon: true,
        cellRenderer: function (params) { return params.data && (0, array_helpers_1.dateFormatter)(params.data.visit_date); },
    },
    {
        lockPosition: 'left',
        headerName: 'SOURCE',
        field: 'source',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.source); },
    },
    {
        lockPosition: 'left',
        headerName: 'DESTINATION',
        field: 'destination',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.destination);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'KM RUN SOURCE TO DEST',
        field: 'distance',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.distance);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TOTAL TIME SOURCE TO DEST',
        field: 'time_taken',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.time_taken);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'WAITING TIME FOR NEXT VISIT',
        field: 'waiting_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.waiting_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TYPE',
        field: 'type',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.type); },
    },
];
var MIS_REPORTS_TAB = [
    {
        lockPosition: 'left',
        headerName: 'FE CODE',
        field: 'first_name',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'feid',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'workingdays',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'LAB NAME',
        field: 'totalkm',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'CITY ',
        field: 'actualAmount',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'STATE',
        field: 'incentives',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'UNIQUE CLIENTS',
        field: 'incentives',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'TOTAL SHARE',
        field: 'incentives',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'R1',
        field: 'incentives',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'R2',
        field: 'incentives',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'R3',
        field: 'incentives',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'R4',
        field: 'deduction',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'TOTAL CLIENTS',
        field: 'deduction',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'TOTAL KM',
        field: 'deduction',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'TOTAL TIME',
        field: 'deduction',
        unSortIcon: true,
    },
];
var UNTAGGED_CLIENTS_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'CLIENT WAREHOUSE CODE',
        field: 'warehouse_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.warehouse_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT INVOICE CODE',
        field: 'invoice_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.invoice_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT NAME',
        field: 'center_name',
        tooltipField: 'center_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT TYPE',
        field: 'center_type',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB NAME',
        field: 'lab_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STATE',
        field: 'center_state',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_state);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CITY',
        field: 'center_city',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_city);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CREATED DATE TIME',
        field: 'created_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.created_date_time);
        },
    },
];
var UNASSIGNED_DYNAMIC_PICKUPS = [
    {
        lockPosition: 'left',
        headerName: 'CLIENT W/H CODE',
        field: 'warehouse_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.warehouse_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT INVOICE CODE',
        field: 'invoice_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.invoice_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT NAME',
        field: 'center_name',
        tooltipField: 'center_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT TYPE',
        field: 'center_type',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB NAME',
        field: 'lab_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STATE',
        field: 'center_state',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_state);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CITY',
        field: 'center_city',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_city);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'Unassigned Slot',
        field: 'time_window_slot',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.time_window_slot);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTION',
        field: 'action',
        pinned: 'right',
        cellRenderer: assign_route_1.default,
        cellRendererSelector: function (params) {
            return {
                component: assign_route_1.default,
                params: {
                    visit_id: params.data.visit_id,
                    name: 'dynamic',
                },
            };
        },
    },
];
var UNASSIGNED_STATIC_PICKUPS = [
    {
        lockPosition: 'left',
        headerName: 'CLIENT W/H CODE',
        field: 'warehouse_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.warehouse_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT INVOICE CODE',
        field: 'invoice_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.invoice_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT NAME',
        field: 'center_name',
        tooltipField: 'center_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT TYPE',
        field: 'center_type',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB NAME',
        field: 'lab_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STATE',
        field: 'center_state',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_state);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CITY',
        field: 'center_city',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_city);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'Unassigned Slot',
        field: 'time_window_slot',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.time_window_slot);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTION',
        field: 'action',
        pinned: 'right',
        cellRenderer: assign_route_1.default,
        cellRendererSelector: function (params) {
            return {
                component: assign_route_1.default,
                params: {
                    visit_id: params.data.visit_id,
                    name: 'static',
                },
            };
        },
    },
];
var DELAYED_DYNAMIC_PICKUP_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'CLIENT WAREHOUSE CODE',
        field: 'center_code',
        unSortIcon: true,
        width: 250,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT INVOICE CODE',
        field: 'invoice_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.invoice_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT NAME',
        field: 'client_name',
        tooltipField: 'client_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.client_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT TYPE',
        field: 'client_type',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.client_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB NAME',
        field: 'lab_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STATE',
        field: 'state',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.state); },
    },
    {
        lockPosition: 'left',
        headerName: 'CITY',
        field: 'city',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.city); },
    },
    {
        lockPosition: 'left',
        headerName: 'SCHEDULED PICKUP TIME',
        field: 'scheduled_pickup_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.scheduled_pickup_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL PICKUP TIME',
        field: 'actual_pickup_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.actual_pickup_time);
        },
    },
];
var AT_RISK_PENDING_PICKUP_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'CLIENT WAREHOUSE CODE',
        field: 'warehouse_code',
        unSortIcon: true,
        width: 250,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.warehouse_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT INVOICE CODE',
        field: 'invoice_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.invoice_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT NAME',
        field: 'client_name',
        tooltipField: 'client_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.client_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT TYPE',
        field: 'client_type',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.client_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB NAME',
        field: 'lab_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STATE',
        field: 'state',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.state); },
    },
    {
        lockPosition: 'left',
        headerName: 'CITY',
        field: 'city',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.city); },
    },
    {
        lockPosition: 'left',
        headerName: 'SCHEDULED PICKUP TIME',
        field: 'scheduled_pickup_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.scheduled_pickup_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE CODE',
        field: 'fe_code',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_code); },
    },
];
var DYNAMIC_PICKUP_ROWS = [
    {
        warehouse_code: 'ABC',
        invoice_code: 'ABC',
        fe_code: 'FEBEE3',
        name: 'TEST',
        time_window: '',
        center_name: '',
        center_state: '',
        center_city: '',
        lab_code: '',
    },
    {
        warehouse_code: 'ABC',
        invoice_code: 'ABC',
        fe_code: 'FEBEE3',
        name: 'TEST',
        time_window: '',
        center_name: '',
        center_state: '',
        center_city: '',
        lab_code: '',
    },
];
var TOTAL_FE_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'FE CODE',
        field: 'fe_id',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_id); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'fe_name',
        tooltipField: 'fe_name',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_name); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE TYPE',
        field: 'fe_type',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_type); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ROUTE TYPE',
        field: 'route_type',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.route_type);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_id',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_id); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CENTER ADDRESS',
        field: 'center_address',
        tooltipField: 'center_address',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_address);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STATE',
        field: 'state',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.state); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CITY',
        field: 'city',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.city); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'VENDOR NAME',
        field: 'vendor_name',
        tooltipField: 'vendor_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.vendor_name);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE MOBILE NUMBER',
        field: 'mobile',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.mobile); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
];
var VISITS_LIST_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'FE ID',
        field: 'fe_id',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_id); },
    },
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'fe_name',
        tooltipField: 'fe_name',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_name); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE MOBILE',
        field: 'mobile',
        unSortIcon: false,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.mobile); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CENTER NAME',
        field: 'center_name',
        tooltipField: 'center_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_name);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'WAREHOUSE CODE',
        field: 'warehouse_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.warehouse_code);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'labCode',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.labCode); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'DATE',
        field: 'visit_date',
        unSortIcon: true,
        cellRenderer: function (params) { return params.data && (0, array_helpers_1.dateFormatter)(params.data.visit_date); },
    },
    {
        lockPosition: 'left',
        headerName: 'START TIME',
        field: 'scheduled_start_time',
        unSortIcon: false,
        sortable: false,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.scheduled_start_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'DISTANCE',
        field: 'distance',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.distance);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'VISIT STATUS',
        field: 'visitStatus',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.visitStatus);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'NOTHING TO PICKUP',
        field: 'nothing_to_pickup',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.nothing_to_pickup);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'VISIT TYPE',
        field: 'visitType',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.visitType);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ASSIGNMENT TYPE',
        field: 'assignment_type',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.assignment_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL PICKUP TIME',
        field: 'actual_pickup_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.actual_pickup_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL HUB HANDOVER TIME',
        field: 'actual_hub_handover_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.actual_hub_handover_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL LAB HANDOVER TIME',
        field: 'actual_lab_handover_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.actual_lab_handover_time);
        },
    },
];
var VENDOR_APPROVAL_LIST_COLUMN = [
    {
        lockPosition: 'left',
        headerName: 'VENDOR NAME',
        field: 'name',
        tooltipField: 'name',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.name); },
    },
    {
        lockPosition: 'left',
        headerName: 'AXAPTA CODE',
        field: 'axapta_code',
        width: 350,
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.axapta_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CREATED ON',
        field: 'created_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateFormatter)(params.data.created_date_time);
        },
        width: 350,
    },
    {
        lockPosition: 'left',
        headerName: 'MODIFIED ON',
        field: 'updated_date_time',
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateFormatter)(params.data.updated_date_time);
        },
        width: 350,
        unSortIcon: true,
    },
];
var CRE_ROWS = [
    {
        fe_id: 'FE890',
        first_name: 'LPL FE-3',
        mobile: 9999999999,
        state: 'HARYANA',
        exit_reason: 'Better offer',
        city: 'S02',
        date_of_exit: '2023-12-12T00:00:00.000Z',
        lab: {
            center_city: 'GURGAON',
            lab_code: 'S28',
        },
        base_latitude: null,
    },
    {
        fe_id: 'FE890',
        first_name: 'LPL FE-3',
        mobile: 9999999999,
        state: 'HARYANA',
        exit_reason: 'Better offer',
        city: 'S02',
        date_of_exit: '2023-12-12T00:00:00.000Z',
        lab: {
            center_city: 'GURGAON',
            lab_code: 'S28',
        },
        base_latitude: null,
    },
    {
        fe_id: 'FE890',
        first_name: 'LPL FE-3',
        mobile: 9999999999,
        state: 'HARYANA',
        exit_reason: 'Better offer',
        city: 'S02',
        date_of_exit: '2023-12-12T00:00:00.000Z',
        lab: {
            center_city: 'GURGAON',
            lab_code: 'S28',
        },
        base_latitude: null,
    },
    {
        fe_id: 'FE890',
        first_name: 'LPL FE-3',
        mobile: 9999999999,
        state: 'HARYANA',
        exit_reason: 'Better offer',
        city: 'S02',
        date_of_exit: '2023-12-12T00:00:00.000Z',
        lab: {
            center_city: 'GURGAON',
            lab_code: 'S28',
        },
        base_latitude: null,
    },
    {
        fe_id: 'FE890',
        first_name: 'LPL FE-3',
        mobile: 9999999999,
        state: 'HARYANA',
        exit_reason: 'Better offer',
        city: 'S02',
        date_of_exit: '2023-12-12T00:00:00.000Z',
        lab: {
            center_city: 'GURGAON',
            lab_code: 'S28',
        },
        base_latitude: null,
    },
    {
        fe_id: 'FE890',
        first_name: 'LPL FE-3',
        mobile: 9999999999,
        state: 'HARYANA',
        exit_reason: 'Better offer',
        city: 'S02',
        date_of_exit: '2023-12-12T00:00:00.000Z',
        lab: {
            center_city: 'GURGAON',
            lab_code: 'S28',
        },
        base_latitude: null,
    },
    {
        fe_id: 'FE890',
        first_name: 'LPL FE-3',
        mobile: 9999999999,
        state: 'HARYANA',
        exit_reason: 'Better offer',
        city: 'S02',
        date_of_exit: '2023-12-12T00:00:00.000Z',
        lab: {
            center_city: 'GURGAON',
            lab_code: 'S28',
        },
        base_latitude: null,
    },
];
var CRE_ACTIVE_LIST = [
    {
        lockPosition: 'left',
        headerName: 'CRE NAME',
        field: 'cre_name',
        tooltipField: 'cre_name',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmptyForCRMname)(params.data && params.data.cre_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'AX CODE',
        field: 'ax_code',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.ax_code); },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_code',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'MOBILE NUMBER',
        field: 'mobile',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.mobile); },
    },
    {
        lockPosition: 'left',
        headerName: 'E-MAIL',
        field: 'email',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.email); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        headerName: 'ACTIONS',
        width: 200,
        sortable: false,
        cellRenderer: cash_receiving_icons_1.default,
        cellRendererSelector: function (params) {
            return {
                component: cash_receiving_icons_1.default,
                params: {
                    userData: params.data,
                },
            };
        },
    },
];
var DYNAMIC_PICKUP_AVAILABLEFE_ROWS = [
    {
        lockPosition: 'left',
        headerName: 'FE CODE',
        field: 'fe_code',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_code); },
    },
    {
        lockPosition: 'left',
        headerName: 'NAME',
        field: 'fe_name',
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_name); },
    },
    {
        lockPosition: 'left',
        headerName: 'DISTANCE (kms)',
        field: 'distance',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.distance);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'MOBILE',
        field: 'mobile',
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.mobile); },
    },
];
var ONDEMAND_REQUEST_COLUMNS = [
    {
        lockPosition: 'left',
        width: 300,
        headerName: 'CC NAME',
        field: 'cc_name',
        tooltipField: 'cc_name',
    },
    {
        lockPosition: 'left',
        width: 300,
        headerName: 'Time Slot',
        field: 'time_slot',
    },
];
var MYREPORTS_LIST = [
    {
        lockPosition: 'left',
        headerName: 'FILE NAME',
        field: 'fileName',
        width: 350,
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fileName);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REPORT TYPE',
        field: 'report_type',
        width: 200,
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.report_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STATUS',
        field: 'status',
        width: 200,
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.status); },
    },
    {
        lockPosition: 'left',
        headerName: 'START DATE',
        field: 'start_date',
        unSortIcon: true,
        width: 200,
        cellRenderer: function (params) { return params.data && (0, array_helpers_1.dateFormatter)(params.data.start_date); },
    },
    {
        lockPosition: 'left',
        headerName: 'END DATE',
        field: 'end_date',
        unSortIcon: true,
        width: 200,
        cellRenderer: function (params) { return params.data && (0, array_helpers_1.dateFormatter)(params.data.end_date); },
    },
    {
        lockPosition: 'left',
        headerName: 'DOWNLOAD LINK',
        field: 'downloadLink',
        sortable: false,
        width: 660,
        cellRenderer: DownloadLinkComponent_1.default,
        cellRendererSelector: function (params) {
            return {
                component: DownloadLinkComponent_1.default,
                params: {
                    filePath: params.data.filePath,
                    reportID: params.data.id,
                },
            };
        },
    },
];
var ONDEMAND_PICKUP_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'first_name',
        width: 250,
        tooltipField: 'first_name',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.concatName)(params.data && params.data.first_name, params.data && params.data.last_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE ID',
        field: 'fe_id',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_id); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'MOBILE',
        field: 'mobile',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.mobile); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ETA',
        field: 'eta',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.eta); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CREATED BY',
        field: 'created_by',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.created_by);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'UPDATED BY',
        field: 'updated_by',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.updated_by);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT WAREHOUSE CODE',
        field: 'warehouse_code',
        width: 250,
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.warehouse_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT INVOICE CODE',
        field: 'invoice_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.invoice_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REQUEST RAISING TIME',
        field: 'request_raising_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.request_raising_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT NAME',
        field: 'center_name',
        tooltipField: 'center_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT TYPE',
        field: 'center_type',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT ROUTE TYPE',
        field: 'route_type',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.route_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB NAME',
        field: 'lab_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STATE',
        field: 'center_state',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_state);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CITY',
        field: 'center_city',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_city);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STATUS',
        field: 'visit_status',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.ondemandKey)(params); },
    },
];
var ONDEMAND_REJECTED_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'CLIENT WAREHOUSE CODE',
        field: 'warehouse_code',
        width: 250,
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.warehouse_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REQUEST RAISING TIME',
        field: 'request_raising_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.request_raising_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'VISIT ID',
        field: 'visit_id',
        tooltipField: 'visit_id',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.visit_id);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'VISIT DATE',
        field: 'visit_date',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.dateFormatter)(params.data.visit_date); },
    },
    {
        lockPosition: 'left',
        headerName: 'STATUS',
        field: 'visit_status',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.ondemandKey)(params); },
    },
    {
        lockPosition: 'left',
        headerName: 'VISIT TYPE',
        field: 'visit_type',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.ondemandvisittype)(params); },
    },
];
var ONDEMAND_UNASSIGNED_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'CLIENT WAREHOUSE CODE',
        field: 'warehouse_code',
        width: 250,
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.warehouse_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT INVOICE CODE',
        field: 'invoice_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.invoice_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REQUEST RAISING TIME',
        field: 'request_raising_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.request_raising_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT NAME',
        field: 'center_name',
        tooltipField: 'center_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT TYPE',
        field: 'center_type',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT ROUTE TYPE',
        field: 'route_type',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.route_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB NAME',
        field: 'lab_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STATE',
        field: 'center_state',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_state);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CITY',
        field: 'center_city',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_city);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STATUS',
        field: 'visit_status',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.ondemandKey)(params); },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTION',
        field: 'action',
        width: 250,
        pinned: 'right',
        cellRenderer: auto_routing_action_button_1.default,
        cellRendererSelector: function (params) {
            var _a, _b, _c, _d;
            return {
                component: auto_routing_action_button_1.default,
                params: {
                    visitId: (_a = params === null || params === void 0 ? void 0 : params.data) === null || _a === void 0 ? void 0 : _a.visit_id,
                    time_window: (_b = params === null || params === void 0 ? void 0 : params.data) === null || _b === void 0 ? void 0 : _b.request_raising_time,
                    center_name: (_c = params === null || params === void 0 ? void 0 : params.data) === null || _c === void 0 ? void 0 : _c.center_name,
                    warehouse_code: (_d = params === null || params === void 0 ? void 0 : params.data) === null || _d === void 0 ? void 0 : _d.warehouse_code,
                    data: params === null || params === void 0 ? void 0 : params.data,
                },
            };
        },
    },
];
var ONDEMAND_PROCESSING_PICKUPS_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'CLIENT WAREHOUSE CODE',
        field: 'warehouse_code',
        width: 250,
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.warehouse_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT INVOICE CODE',
        field: 'invoice_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.invoice_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'REQUEST RAISING TIME',
        field: 'request_raising_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.request_raising_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT NAME',
        field: 'center_name',
        tooltipField: 'center_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT TYPE',
        field: 'center_type',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT ROUTE TYPE',
        field: 'route_type',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.route_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB NAME',
        field: 'lab_name',
        unSortIcon: true,
        tooltipField: 'lab_name',
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STATE',
        field: 'center_state',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_state);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CITY',
        field: 'center_city',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_city);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STATUS',
        field: 'visit_status',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.ondemandKey)(params); },
    },
];
var CC_DISPLAY_SLOTS = [
    {
        lockPosition: 'left',
        headerName: 'ROUTE',
        field: 'route',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'TIME SLOT',
        field: 'time_slot',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'STATUS',
        field: 'status',
        unSortIcon: true,
    },
];
var CC_MODIFIED_SLOTS = [
    {
        route: 'R!',
        time_slot: '09-10',
        status: 'Active',
    },
    {
        route: 'R2',
        time_slot: '09-10',
        status: 'Active',
    },
    {
        route: 'R3',
        time_slot: '09-10',
        status: 'Active',
    },
    {
        route: 'R4',
        time_slot: '09-10',
        status: 'Active',
    },
    {
        route: 'R5',
        time_slot: '09-10',
        status: 'Active',
    },
];
var APPROVAL_ROUTE_PLANNER_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'ROUTE ID',
        field: 'route_id',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.route_id);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_code',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CREATED BY',
        field: 'created_by',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.created_by);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'INITIATED DATE & TIME',
        field: 'initiated_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.initiated_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'GENEREATED DATE & TIME',
        field: 'generated_date_time',
        unSortIcon: true,
        width: 250,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.generated_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ROUTE TYPE',
        field: 'route_assignment_type',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.route_assignment_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STATUS',
        field: 'status',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.status); },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTION',
        sortable: false,
        pinned: 'right',
        cellRenderer: ViewApprovalRoutePlanner_1.default,
        cellRendererSelector: function (params) {
            return {
                component: ViewApprovalRoutePlanner_1.default,
                params: {
                    routeDetails: params && params.data,
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'COMMENTS',
        sortable: false,
        field: 'rejection_comments',
        cellRenderer: view_commnets_1.default,
        cellRendererSelector: function (params) {
            return {
                component: view_commnets_1.default,
                params: {
                    rejection_comments: params.data && params.data.rejection_comments,
                    isModalOpen: true,
                },
            };
        },
    },
];
var ASSIGN_ONDEMAND_REQUEST = [
    {
        headerCheckboxSelection: false,
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: true,
        lockPosition: 'left',
        width: 320,
        headerName: 'FE NAME & CODE',
        field: 'fe_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.concatFirstNameLastName)(params.data && params.data.fe_name, params.data && params.data.fe_code);
        },
    },
    {
        lockPosition: 'left',
        width: 320,
        headerName: 'DISTANCE',
        field: 'distance',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.distance);
        },
    },
    {
        lockPosition: 'left',
        width: 320,
        headerName: 'MOBILE NUMBER',
        field: 'mobile',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.mobile); },
    },
    {
        lockPosition: 'left',
        headerName: 'WORKING STATE',
        width: 320,
        field: 'state',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.state); },
    },
    {
        lockPosition: 'left',
        headerName: 'WORKING CITY',
        width: 320,
        field: 'city',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.city); },
    },
];
var UNASSIGNED_DATA = [
    {
        lockPosition: 'left',
        headerName: 'CC NAME & CODE',
        field: 'centre_name',
        width: 250,
        tooltipField: 'centre_name',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'FE NAME & CODE',
        width: 250,
        field: 'fe_name',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'TIME SLOT',
        field: 'time_slot',
        width: 250,
        tooltipField: 'time_slot',
        unSortIcon: true,
    },
    {
        lockPosition: 'left',
        headerName: 'ACTION',
        field: 'action',
        width: 250,
        cellRenderer: Assign_Button_1.default,
        cellRendererSelector: function (params) {
            var _a, _b, _c;
            return {
                component: Assign_Button_1.default,
                params: {
                    visitId: (_a = params === null || params === void 0 ? void 0 : params.data) === null || _a === void 0 ? void 0 : _a.visit_id,
                    time_window: (_b = params === null || params === void 0 ? void 0 : params.data) === null || _b === void 0 ? void 0 : _b.time_window,
                    center_name: (_c = params === null || params === void 0 ? void 0 : params.data) === null || _c === void 0 ? void 0 : _c.center_name,
                },
            };
        },
    },
];
var ROUTE_PLANNER_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'ROUTE ID',
        field: 'route_id',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.route_id);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_code',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CREATED BY',
        field: 'created_by',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.created_by);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'INITIATED DATE & TIME',
        field: 'initiated_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.initiated_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'GENEREATED DATE & TIME',
        field: 'generated_date_time',
        unSortIcon: true,
        width: 250,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.generated_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'SPEED',
        field: 'speed',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA === valueB) {
                return 0;
            }
            return valueA > valueB ? 1 : -1;
        },
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.speed); },
    },
    {
        lockPosition: 'left',
        headerName: 'RADIUS',
        field: 'radius',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA === valueB) {
                return 0;
            }
            return valueA > valueB ? 1 : -1;
        },
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.radius); },
    },
    {
        lockPosition: 'left',
        headerName: 'ROUTE TYPE',
        field: 'route_assignment_type',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.route_assignment_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STATUS',
        field: 'status',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.status); },
    },
    {
        lockPosition: 'left',
        headerName: 'FAILED REASON',
        field: 'failed_reason',
        tooltipField: 'failed_reason',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.failed_reason);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTION',
        sortable: false,
        pinned: 'right',
        cellRenderer: view_route_planner_1.default,
        cellRendererSelector: function (params) {
            return {
                component: view_route_planner_1.default,
                params: {
                    routeDetails: params && params.data,
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'COMMENTS',
        sortable: false,
        field: 'rejection_comments',
        cellRenderer: view_commnets_1.default,
        cellRendererSelector: function (params) {
            return {
                component: view_commnets_1.default,
                params: {
                    rejection_comments: params.data && params.data.rejection_comments,
                    isModalOpen: true,
                },
            };
        },
    },
];
var WORKINGHOURS_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'first_name',
        width: 400,
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: avatar_component_1.default,
        cellRendererSelector: function (params) {
            return {
                component: avatar_component_1.default,
                params: {
                    image: params.data.avatar,
                    className: 'avatar',
                    fName: params.data && params.data.first_name,
                    lName: params.data && params.data.last_name,
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE ID',
        field: 'fe_id',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) { var _a; return (0, array_helpers_1.isFieldValueEmpty)(params.data && ((_a = params === null || params === void 0 ? void 0 : params.data) === null || _a === void 0 ? void 0 : _a.fe_id)); },
    },
    {
        lockPosition: 'left',
        headerName: 'MOBILE NUMBER',
        field: 'mobile',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.mobile); },
    },
    {
        lockPosition: 'left',
        headerName: 'STATE',
        field: 'state',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.state); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CITY',
        field: 'city',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.city); },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'WORKING CITY',
        field: 'lab.center_city',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab && params.data.lab.center_city);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab.lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab && params.data.lab.lab_code);
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTION',
        sortable: false,
        cellRenderer: working_hours_btns_1.default,
        cellRendererSelector: function (params) {
            return {
                component: working_hours_btns_1.default,
                params: {
                    data: params.data,
                },
            };
        },
    },
];
var USER_LOGISTICS_LIST = [
    {
        lockPosition: 'left',
        headerName: 'USER NAME',
        field: 'user_name',
        tooltipField: 'user_name',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase().trim() === valueB.toLowerCase().trim()) {
                return 0;
            }
            return valueA.toLowerCase().trim() > valueB.toLowerCase().trim() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.user_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'MOBILE',
        field: 'phone_number',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.phone_number);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'E-MAIL',
        field: 'email',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.email); },
    },
    {
        lockPosition: 'left',
        headerName: 'DOB',
        field: 'dob',
        unSortIcon: true,
        width: 200,
        cellRenderer: function (params) { return params.data && (0, array_helpers_1.dateFormatter)(params.data.dob); },
    },
    {
        lockPosition: 'left',
        headerName: 'ADDRESS',
        field: 'address',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.address.toUpperCase());
        },
    },
    {
        lockPosition: 'left',
        headerName: 'GENDER',
        field: 'gender',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.gender.toUpperCase());
        },
    },
];
var Lab_Master_Slots = [
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_code',
        tooltipField: 'lab_code',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB NAME',
        field: 'lab_name',
        tooltipField: 'lab_name',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'INVOICE CODE',
        field: 'invoice_code',
        tooltipField: 'invoice_code',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.invoice_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'WAREHOUSE CODE',
        field: 'warehouse_code',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.warehouse_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB STATE',
        field: 'lab_state',
        tooltipField: 'lab_state',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_state);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CITY',
        field: 'lab_city',
        tooltipField: 'lab_city',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_city);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'AX STATUS',
        field: 'ax_status',
        unSortIcon: true,
        valueFormatter: array_helpers_1.AxStatusKey,
    },
];
var HUB_MASTER_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'HUB CODE',
        field: 'hub_code',
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase().trim() === valueB.toLowerCase().trim()) {
                return 0;
            }
            return valueA.toLowerCase().trim() > valueB.toLowerCase().trim() ? 1 : -1;
        },
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.hub_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'HUB NAME',
        field: 'hub_name',
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase().trim() === valueB.toLowerCase().trim()) {
                return 0;
            }
            return valueA.toLowerCase().trim() > valueB.toLowerCase().trim() ? 1 : -1;
        },
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.hub_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'INVOICE CODE',
        field: 'invoice_code',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase().trim() === valueB.toLowerCase().trim()) {
                return 0;
            }
            return valueA.toLowerCase().trim() > valueB.toLowerCase().trim() ? 1 : -1;
        },
        cellRenderer: function (params) { var _a, _b; return (0, array_helpers_1.isFieldValueEmpty)(params.data && ((_b = (_a = params === null || params === void 0 ? void 0 : params.data) === null || _a === void 0 ? void 0 : _a.invoice_code) === null || _b === void 0 ? void 0 : _b.toUpperCase())); },
    },
    {
        lockPosition: 'left',
        headerName: 'HUB TYPE',
        field: 'hub_type',
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase().trim() === valueB.toLowerCase().trim()) {
                return 0;
            }
            return valueA.toLowerCase().trim() > valueB.toLowerCase().trim() ? 1 : -1;
        },
        unSortIcon: true,
        width: 250,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.hub_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'HUB STATE',
        field: 'hub_state',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.hub_state);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'HUB CITY',
        field: 'hub_city',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.hub_city);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTION',
        field: 'action',
        width: 250,
        cellRenderer: hubMasterActionBtns_1.default,
        cellRendererSelector: function (params) {
            return {
                component: hubMasterActionBtns_1.default,
                params: {
                    data: params === null || params === void 0 ? void 0 : params.data,
                },
            };
        },
    },
];
var MASTERBOX_TRACKING_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'MASTERBOX ID',
        field: 'id',
        tooltipField: 'id',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.id); },
    },
    {
        lockPosition: 'left',
        headerName: 'BAG ID',
        field: 'bag_id',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.bag_id); },
    },
    {
        lockPosition: 'left',
        headerName: 'CENTER CODE',
        field: 'center_code',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CENTER NAME',
        field: 'center_name',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CREATED BY (FE / CC)',
        field: 'created_by',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.created_by);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CURRENT STATUS',
        field: 'current_status',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.current_status);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CREATED DATE AND TIME',
        field: 'created_datetime',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.created_datetime);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CENTER STATE',
        field: 'center_state',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_state);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CENTER CITY',
        field: 'center_city',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_city);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_code',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        headerName: 'ACTIONS',
        cellRenderer: MasterBoxActionIconButtons_1.default,
        sortable: false,
        pinned: 'right',
        cellRendererSelector: function (params) {
            return {
                component: MasterBoxActionIconButtons_1.default,
                params: {
                    data: params === null || params === void 0 ? void 0 : params.data,
                },
            };
        },
    },
];
var ALL_VISITS_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'DATE',
        field: 'visit_date',
        unSortIcon: true,
        cellRenderer: function (params) { return params.data && (0, array_helpers_1.dateFormatter)(params.data.visit_date); },
    },
    {
        lockPosition: 'left',
        headerName: 'FE CODE',
        field: 'fe_id',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_id); },
    },
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'first_name',
        width: 250,
        tooltipField: 'first_name',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.concatName)(params.data && params.data.first_name, params.data && params.data.last_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'MOBILE',
        field: 'mobile',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.mobile); },
    },
    {
        lockPosition: 'left',
        headerName: 'FE STATE',
        field: 'fe_state',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_state);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE CITY',
        field: 'fe_city',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_city); },
    },
    {
        lockPosition: 'left',
        headerName: 'FE HUB',
        field: 'fe_hub',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_hub); },
    },
    {
        lockPosition: 'left',
        headerName: 'CENTRE WH CODE',
        field: 'warehouse_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.warehouse_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CENTRE NAME',
        field: 'center_name',
        tooltipField: 'center_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CENTRE STATE',
        field: 'center_state',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_state);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CENTRE CITY',
        field: 'center_city',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_city);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CENTRE HUB CODE',
        field: 'center_hub_code',
        tooltipField: 'center_hub_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_hub_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CENTRE HUB NAME',
        field: 'center_hub_name',
        tooltipField: 'center_hub_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_hub_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'NOTHING TO PICKUP',
        field: 'nothing_to_pickup',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.nothing_to_pickup);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ASSIGNED VISITS',
        field: 'total_assigned_visits',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.total_assigned_visits);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TIME WINDOW',
        field: 'time_window_slot',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.time_window_slot);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'VISIT STATUS',
        field: 'visit_status',
        unSortIcon: true,
        valueFormatter: array_helpers_1.convertVisitStatus,
    },
];
var ASSIGNED_COMPLETED_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'FE ID',
        field: 'fe_id',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_id); },
    },
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'first_name',
        width: 250,
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.concatFirstNameLastName)(params.data && params.data.first_name, params.data && params.data.last_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'MOBILE NO.',
        field: 'mobile',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.mobile); },
    },
    {
        lockPosition: 'left',
        headerName: 'TOTAL ASSIGNED VISITS',
        field: 'assigned_visits',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.assigned_visits);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'SUCCESSFUL VISITS',
        field: 'completed_visits',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.completed_visits);
        },
    },
];
var REGION_LISTING_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'REGION NAME',
        field: 'region_name',
        width: 300,
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.region_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'NO.OF LABS',
        field: 'labs_count',
        width: 300,
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.labs_count);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CREATED DATE & TIME',
        field: 'created_date_time',
        width: 300,
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.created_date_time);
        },
    },
    {
        headerName: 'ACTIONS',
        cellRenderer: RegionUpdateIcon_1.default,
        width: 200,
        sortable: false,
        cellRendererSelector: function (params) {
            return {
                component: RegionUpdateIcon_1.default,
                params: {
                    data: params === null || params === void 0 ? void 0 : params.data,
                    name: 'region',
                },
            };
        },
    },
];
var USER_REGION_LISTING_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'REGION NAME',
        field: 'region_name',
        width: 300,
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.region_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'NO.OF USERS',
        field: 'users_count',
        width: 300,
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.users_count);
        },
    },
    {
        headerName: 'ACTIONS',
        cellRenderer: RegionUpdateIcon_1.default,
        width: 500,
        sortable: false,
        cellRendererSelector: function (params) {
            return {
                component: RegionUpdateIcon_1.default,
                params: {
                    data: params === null || params === void 0 ? void 0 : params.data,
                    name: 'user',
                },
            };
        },
    },
];
var USER_MAPPING_ROWS = [
    {
        no_of_users: 1,
        region_name: 'NORTH',
    },
    {
        no_of_users: 2,
        region_name: 'SOUTH',
    },
    {
        no_of_users: 3,
        region_name: 'EAST',
    },
    {
        no_of_users: 4,
        region_name: 'WEST',
    },
    {
        no_of_users: 5,
        region_name: 'NORTH',
    },
];
var Region_List_ROWS = [
    {
        no_of_labs: 1,
        region_name: 'NORTH',
        created_datetime: '2023-05-16T07:26:33.488Z',
    },
    {
        no_of_labs: 2,
        region_name: 'EAST',
        created_datetime: '2023-05-16T07:26:33.488Z',
    },
    {
        no_of_labs: 3,
        region_name: 'WEST',
        created_datetime: '2023-05-16T07:26:33.488Z',
    },
    {
        no_of_labs: 4,
        region_name: 'EAST',
        created_datetime: '2023-05-16T07:26:33.488Z',
    },
    {
        no_of_labs: 5,
        region_name: 'SOUTH',
        created_datetime: '2023-05-16T07:26:33.488Z',
    },
];
var SHIPMENT_TRACKING_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'MASTER SHIPMENT',
        field: 'master_barcode',
        tooltipField: 'master_barcode',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.master_barcode);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'SHIPMENT BARCODE',
        field: 'barcode',
        tooltipField: 'barcode',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.barcode); },
    },
    {
        lockPosition: 'left',
        headerName: 'CURRENT STATUS',
        field: 'status',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.status); },
    },
    {
        lockPosition: 'left',
        headerName: 'R LAB',
        field: 'registration_lab',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.registration_lab);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'P LAB',
        field: 'processing_lab',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.processing_lab);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'SHIPMENT CREATION TIME',
        field: 'shipment_creation_date',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.shipmentDateTimeFormatter)(params.data.shipment_creation_date);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTIONS',
        field: 'route',
        cellRenderer: icon_view_button_1.default,
        cellRendererSelector: function (params) {
            return {
                component: icon_view_button_1.default,
                params: { masterShipmentId: params.data.master_barcode },
            };
        },
    },
];
var NOTIFICATION_LIST_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'NOTIFICATION TITLE',
        field: 'notificationTitle',
        tooltipField: 'notificationTitle',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.notificationTitle);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CATEGORY',
        field: 'category',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.category);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'USERS',
        field: 'users',
        unSortIcon: true,
        cellRenderer: SelectedFEInList_1.default,
        cellRendererSelector: function (params) {
            return {
                component: SelectedFEInList_1.default,
                params: {
                    group: params.data.users,
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CREATED DATE',
        field: 'createdDate',
        unSortIcon: true,
        cellRenderer: function (params) { return params.data && (0, array_helpers_1.dateFormatter)(params.data.createdDate); },
    },
    {
        lockPosition: 'left',
        headerName: 'START DATE',
        field: 'startDate',
        unSortIcon: true,
        cellRenderer: function (params) { return params.data && (0, array_helpers_1.dateFormatter)(params.data.startDate); },
    },
    {
        lockPosition: 'left',
        headerName: 'END DATE',
        field: 'endDate',
        unSortIcon: true,
        cellRenderer: function (params) { return params.data && (0, array_helpers_1.dateFormatter)(params.data.endDate); },
    },
    {
        lockPosition: 'left',
        headerName: 'EXECUTION DATE',
        field: 'executionDate',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateFormatter)(params.data.executionDate);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'EXECUTION TIME',
        field: 'execution_time',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.execution_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTION',
        sortable: false,
        cellRenderer: NotificationActionIcon_1.default,
        cellRendererSelector: function (params) {
            return {
                component: NotificationActionIcon_1.default,
                params: {
                    userData: params.data,
                },
            };
        },
    },
];
var FE_ATTENDANCE_REPORT_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'VENDOR NAME',
        field: 'vendor_name',
        tooltipField: 'vendor_name',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.vendor_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'fe_name',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_name); },
    },
    {
        lockPosition: 'left',
        headerName: 'DATE OF EXIT',
        field: 'date_of_exit',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateFormatter)(params.data.date_of_exit);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ONBOARDING STATUS',
        field: 'onboard_status',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.isFieldValueEmpty)(params.data.onboard_status);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'VISIT DATE',
        field: 'visit_date',
        unSortIcon: true,
        cellRenderer: function (params) { return params.data && (0, array_helpers_1.dateFormatter)(params.data.visit_date); },
    },
    {
        lockPosition: 'left',
        headerName: 'FE TYPE',
        field: 'fe_type',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_type); },
    },
    {
        lockPosition: 'left',
        headerName: 'FE ROUTE TYPE',
        field: 'fe_route_type',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_route_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'MOBILE',
        field: 'mobile',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.mobile); },
    },
    {
        lockPosition: 'left',
        headerName: 'FE ID',
        field: 'fe_code',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_code); },
    },
    {
        lockPosition: 'left',
        headerName: 'FE LAB CODE',
        field: 'fe_lab_code',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FE LAB NAME',
        tooltipField: 'fe_lab_name',
        field: 'fe_lab_name',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LOGIN TIME',
        field: 'login_time',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.login_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'SCHEDULE TIME',
        field: 'first_scheduled_visit_time',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.first_scheduled_visit_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL TIME',
        field: 'first_actual_visit_time',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.first_actual_visit_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'DEVIATION',
        field: 'deviation',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.deviation);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'KMS DRIVEN',
        field: 'kilometers',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isValueZero)(params.data && params.data.kilometers); },
    },
    {
        lockPosition: 'left',
        headerName: 'SCHEDULED KILOMETERS',
        field: 'scheduled_kms',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isValueZero)(params.data && params.data.scheduled_kms); },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL KILOMETERS',
        field: 'actual_kms',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isValueZero)(params.data && params.data.actual_kms); },
    },
];
var NOTHING_TOPICKUP_REPORT_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'CLIENT WAREHOUSE CODE',
        field: 'warehouse_code',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.warehouse_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT INVOICE CODE',
        field: 'invoice_code',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.invoice_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT NAME',
        field: 'center_name',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT TYPE',
        field: 'center_type',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_code',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TOTAL ASSIGNED ROUNDS',
        field: 'total_assigned_rounds',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.total_assigned_rounds);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TOTAL NOTHING PICKUP COUNT',
        field: 'total_nothing_pickup_count',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.total_nothing_pickup_count);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'PRODUCTIVITY',
        field: 'productivity',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.productivity);
        },
    },
];
var RLABTOPLAB_REPORT_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'MASTER BARCODE',
        field: 'master_barcode',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.master_barcode);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'BARCODE',
        field: 'barcode',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.barcode); },
    },
    {
        lockPosition: 'left',
        headerName: 'P LAB CODE',
        field: 'processing_lab',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.processing_lab);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'R LAB CODE',
        field: 'registration_lab',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.registration_lab);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'R LAB NAME',
        field: 'r_lab_name',
        tooltipField: 'r_lab_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.r_lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'R LAB STATE',
        field: 'r_lab_state',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.r_lab_state);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'R LAB CITY',
        field: 'r_lab_city',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.r_lab_city);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STARLIMS USER ID',
        field: 'starlims_user_id',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.starlims_user_id);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'SHIPMENT CREATION DATE & TIME',
        field: 'shipment_creation_date',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.shipment_creation_date);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'SHIPMENT CREATION INITIATION TIME',
        field: 'shipment_creation_initiation_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data &&
                (0, array_helpers_1.dateTimeFormatter)(params.data.shipment_creation_initiation_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'SHIPMENT CLOSURE DATE & TIME',
        field: 'shipment_closure_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.shipment_closure_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'UPDATED BY',
        field: 'updated_by',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.updated_by);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'UPDATED DATE & TIME',
        field: 'updated_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.updated_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LANE',
        field: 'lane',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lane); },
    },
    {
        lockPosition: 'left',
        headerName: 'LANE ROUTE',
        field: 'lane_route',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lane_route);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'SCHEDULED TAT',
        field: 'scheduled_tat',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.scheduled_tat);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'SCHEDULED P LAB NAME',
        field: 'scheduled_p_lab_name',
        tooltipField: 'scheduled_p_lab_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.scheduled_p_lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'SCHEDULED P LAB CITY',
        field: 'scheduled_p_lab_city',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.scheduled_p_lab_city);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL TAT',
        field: 'actual_tat',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.actual_tat);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL PICKUP DATE & TIME',
        field: 'actual_pickup_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.actual_pickup_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL P LAB CODE',
        field: 'actual_p_lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.isFieldValueEmpty)(params.data.actual_p_lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL P LAB NAME',
        field: 'actual_p_lab_name',
        tooltipField: 'actual_p_lab_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.actual_p_lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL P LAB CITY',
        field: 'actual_p_lab_city',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.actual_p_lab_city);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL HANDOVER DATE & TIME',
        field: 'actual_lab_handover_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.actual_lab_handover_time);
        },
    },
];
var Outstation_Master_Box_REPORT_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'FE NAME',
        field: 'created_by_name',
        tooltipField: 'created_by_name',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.created_by_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STATUS',
        field: 'status',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.status); },
    },
    {
        lockPosition: 'left',
        headerName: 'HANDOVER TIME',
        field: 'handover_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.handover_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CREATED DATE',
        field: 'created_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.created_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'BARCODE',
        field: 'barcode_id',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.barcode_id);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TYPE',
        field: 'created_by_type',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.created_by_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CREATED ID',
        field: 'created_by_id',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.created_by_id);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'OWNER ID',
        field: 'current_owner_id',
        tooltipField: 'current_owner_id',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.current_owner_id);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'OWNER TYPE',
        field: 'current_owner_type',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.current_owner_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'HUB LOCATION',
        field: 'from_location',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.from_location);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TAGGED LAB CODE',
        field: 'tagged_lab_code',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.tagged_lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TAGGED LAB NAME',
        field: 'tagged_lab_name',
        tooltipField: 'tagged_lab_name',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.tagged_lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB STATE',
        field: 'tagged_lab_state',
        tooltipField: 'tagged_lab_state',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.tagged_lab_state);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CITY',
        field: 'tagged_lab_city',
        tooltipField: 'tagged_lab_city',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.tagged_lab_city);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'MOVEMENT',
        field: 'movement_by',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.movement_by);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TO LOCATION',
        field: 'to_location',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.to_location);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'IN TRANSIT TIME',
        field: 'in_transit_time',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            var _a = valueA.split(':').map(Number), hoursA = _a[0], minutesA = _a[1], secondsA = _a[2];
            var _b = valueB.split(':').map(Number), hoursB = _b[0], minutesB = _b[1], secondsB = _b[2];
            if (hoursA !== hoursB) {
                return hoursA - hoursB;
            }
            if (minutesA !== minutesB) {
                return minutesA - minutesB;
            }
            return secondsA - secondsB;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.in_transit_time);
        },
    },
];
var RLABTOPLAB_METRICS_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'MASTERBOX ID',
        field: 'master_barcode',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.master_barcode);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'R LAB CODE',
        field: 'registration_lab',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.registration_lab);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'R LAB NAME',
        field: 'registration_lab_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.registration_lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'P LAB CODE',
        field: 'processing_lab',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.processing_lab);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'P LAB NAME',
        field: 'processing_lab_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.processing_lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CATEGORY',
        field: 'category',
        tooltipField: 'category',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.category);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CREATION DATE & TIME',
        field: 'created_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.created_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'PICKUP DATE & TIME',
        field: 'pickup_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.pickup_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'HANDOVER DATE & TIME',
        field: 'handover_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.handover_date_time);
        },
    },
];
var RLABTOPLAB_REPORT_GRPAH_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'MASTERBOX ID',
        field: 'master_barcode',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.master_barcode);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'R-LAB CODE',
        field: 'registration_lab',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.registration_lab);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'R-LAB NAME',
        field: 'r_lab_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.r_lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'P-LAB CODE',
        field: 'processing_lab',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.processing_lab);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'P-LAB NAME',
        field: 'scheduled_p_lab_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.scheduled_p_lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CATEGORY',
        field: 'category',
        tooltipField: 'category',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.category);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CREATION DATE & TIME',
        field: 'shipment_creation_date',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.shipment_creation_date);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'PICKUP DATE & TIME',
        field: 'actual_pickup_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.actual_pickup_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TAT BREACH BY',
        field: 'breach_by',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.breach_by);
        },
    },
];
var SUCCESSFULVISITS_REPORT_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'CLIENT WAREHOUSE CODE',
        field: 'warehouse_code',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.warehouse_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT INVOICE CODE',
        field: 'invoice_code',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.invoice_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT NAME',
        field: 'center_name',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT TYPE',
        field: 'center_type',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'lab_code',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TOTAL ASSIGNED ROUNDS',
        field: 'total_assigned_rounds',
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.total_assigned_rounds);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TOTAL NOTHING PICKUP COUNT',
        field: 'total_nothing_pickup_count',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.total_nothing_pickup_count);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'PRODUCTIVITY',
        field: 'productivity',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.productivity);
        },
    },
];
var ADD_VISIT_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'FILE NAME',
        field: 'original_file_name',
        tooltipField: 'original_file_name',
        width: 300,
        unSortIcon: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.original_file_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'UPLOAD DATE & TIME',
        field: 'created_date_time',
        unSortIcon: true,
        width: 300,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.created_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CREATED BY',
        field: 'created_by',
        tooltipField: 'created_by',
        unSortIcon: true,
        width: 300,
        comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
            if (valueA.toLowerCase() === valueB.toLowerCase()) {
                return 0;
            }
            return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
        },
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.created_by);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'PROCESSED',
        field: 'processed',
        tooltipField: 'processed',
        unSortIcon: true,
        width: 300,
        cellRenderer: function (params) { return params.data && (0, array_helpers_1.processedkey)(params.data.processed); },
    },
    {
        lockPosition: 'left',
        headerName: 'REASON',
        field: 'reason',
        tooltipField: 'reason',
        unSortIcon: true,
        width: 300,
        cellRenderer: reason_tooltip_1.default,
        cellRendererSelector: function (params) {
            return {
                component: reason_tooltip_1.default,
                params: {
                    data: params.data,
                },
            };
        },
    },
    {
        lockPosition: 'left',
        headerName: 'DOWNLOAD LINK',
        field: 'file.url',
        sortable: false,
        width: 660,
        cellRenderer: DownloadLinkComponent_1.default,
        cellRendererSelector: function (params) {
            var _a;
            return {
                component: DownloadLinkComponent_1.default,
                params: {
                    filePath: params.data && params.data.file && ((_a = params.data.file) === null || _a === void 0 ? void 0 : _a.name),
                    reportID: params.data._id,
                    name: 'visit',
                },
            };
        },
    },
];
exports.Outstation_Master_Box_GRAPH_PENDIGN_RECIEVED_COLUN = [
    {
        lockPosition: 'left',
        headerName: 'Masterbox ID',
        field: 'barcode_id',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.barcode_id);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'Hub Location',
        field: 'from_location',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.from_location);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'To Location',
        field: 'to_location',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.to_location);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'Created By',
        field: 'created_by_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.created_by_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'Created By Client/FE Code',
        field: 'created_by_id',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.created_by_id);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'Created By Client/FE Name',
        field: 'created_by_type',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.created_by_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'Movement By',
        field: 'movement_by',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.movement_by);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'Tagged Lab Code',
        field: 'tagged_lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.tagged_lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'Tagged Lab Name',
        field: 'tagged_lab_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.tagged_lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'Status',
        field: 'status',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.status); },
    },
    {
        lockPosition: 'left',
        headerName: 'Creation Date & Time',
        field: 'created_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data &&
                (0, report_dashboard_service_1.dateTimeFormatterOutstationDownload)(params.data.created_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'Handover Date & Time',
        field: 'handover_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data &&
                (0, report_dashboard_service_1.dateTimeFormatterOutstationDownload)(params.data.handover_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'Transit Time',
        field: 'in_transit_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.in_transit_time);
        },
    },
];
exports.Outstation_Master_Box_GRAPH_PENDIGN_COLUN = [
    {
        lockPosition: 'left',
        headerName: 'Masterbox ID',
        field: 'barcode_id',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.barcode_id);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'Hub Location',
        field: 'from_location',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.from_location);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'To Location',
        field: 'to_location',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.to_location);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'Created By',
        field: 'created_by_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.created_by_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'Created By Client/FE Code',
        field: 'created_by_id',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.created_by_id);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'Created By Client/FE Name',
        field: 'created_by_type',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.created_by_type);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'Movement By',
        field: 'movement_by',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.movement_by);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'Tagged Lab Code',
        field: 'tagged_lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.tagged_lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'Tagged Lab Name',
        field: 'tagged_lab_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.tagged_lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'Status',
        field: 'status',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.status); },
    },
    {
        lockPosition: 'left',
        headerName: 'Creation Date & Time',
        field: 'created_date_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data &&
                (0, report_dashboard_service_1.dateTimeFormatterOutstationDownload)(params.data.created_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'In Transit Since',
        field: 'in_transit_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.in_transit_time);
        },
    },
];
exports.INTRACITY_FE_ONTIME_PICKUP_LIST_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'LAB_CODE',
        field: 'lab_code',
        width: 300,
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'PICKUP FE CODE',
        field: 'fe_code',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_code); },
    },
    {
        lockPosition: 'left',
        headerName: ' PICKUP FE Name',
        field: 'fe_name',
        sortable: false,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_name); },
    },
    {
        lockPosition: 'left',
        headerName: 'DELAYED PICKUP',
        field: 'delayed_percentage',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.delayed_percentage);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'EARLY PICKUP',
        field: 'early_percentage',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.early_percentage);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ONTIME PICKUP',
        field: 'ontime_percentage',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.ontime_percentage);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'GRAND TOTAL',
        field: 'grandTotal_percentage',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.grandTotal_percentage);
        },
    },
];
exports.INTRACITY_FE_TAT_LIST_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'LAB_CODE',
        field: 'lab_code',
        width: 450,
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'PICKUP FE CODE',
        field: 'fe_code',
        width: 480,
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_code); },
    },
    {
        lockPosition: 'left',
        headerName: ' PICKUP FE Name',
        field: 'fe_name',
        sortable: false,
        width: 450,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.fe_name); },
    },
    {
        lockPosition: 'left',
        headerName: 'AVERAGE OF FE PICKUP O HANDOVER TAT',
        field: 'total_avg_pickupToHandOverTime',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.total_avg_pickupToHandOverTime);
        },
    },
];
exports.SHIPMENT_ROUTE_LIST_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'R LAB CODE',
        field: 'registration_lab',
        width: 300,
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.registration_lab);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'R LAB NAME',
        field: 'registration_lab_name',
        tooltipField: 'registration_lab_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.registration_lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: ' P LAB CODE',
        field: 'processing_lab',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.processing_lab);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'P LAB NAME',
        field: 'processing_lab_name',
        tooltipField: 'processing_lab_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.processing_lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'NO.OF(CUTOFFS)',
        field: 'no_of_cut_off',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.no_of_cut_off);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTION',
        sortable: false,
        cellRenderer: view_edit_button_1.default,
        cellRendererSelector: function (params) {
            var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.Shipment_Route_Master);
            return {
                component: view_edit_button_1.default,
                params: {
                    userData: params.data,
                    isViewDisabled: features[menu_constants_1.FEATURE_CONSTANTS.Shipment_List],
                    isEditDisabled: features[menu_constants_1.FEATURE_CONSTANTS.Shipment_Edit],
                },
            };
        },
    },
];
var INTRACITY_TAT_FE_LIST_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'pickup_fe_lab_code',
        width: 350,
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.pickup_fe_lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'PICKUP FE CODE',
        field: 'pickup_fe_code',
        width: 350,
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.pickup_fe_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: ' PICKUP FE Name',
        width: 350,
        field: 'pickup_fe_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.pickup_fe_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'AVERAGE OF FE TAT',
        field: 'avg_tat',
        width: 450,
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.avg_tat); },
    },
];
var INTRACITY_TAT_SAMPLE_LIST_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'LAB NO',
        field: 'lab_number',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_number);
        },
    },
    {
        lockPosition: 'left',
        headerName: ' BAG ID',
        field: 'bag_barcode',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.bag_barcode);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'FROM EVENT TIME',
        field: 'actual_pick_up_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.actual_pick_up_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TO EVENT TIME',
        field: 'registration_timestamp',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.registration_timestamp);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'PICKUP FE CODE',
        field: 'pickup_fe_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.pickup_fe_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'PICKUP FE NAME',
        field: 'pickup_fe_name',
        tooltipField: 'pickup_fe_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.pickup_fe_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT CODE',
        field: 'client_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.client_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CENTRE NAME',
        field: 'center_name',
        tooltipField: 'center_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'sample_lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.sample_lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'DELAYED BY(IN HH:MM)',
        field: 'delayed_by',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.delayed_by);
        },
    },
];
var ON_TIME_PICKUP_LIST_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'LAB NO',
        field: 'lab_number',
        sortable: true,
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lab_number);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'BAG ID',
        field: 'bag_barcode',
        unSortIcon: true,
        sortable: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.bag_barcode);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CURRENT STATUS',
        field: 'status',
        unSortIcon: true,
        sortable: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.status); },
    },
    {
        lockPosition: 'left',
        headerName: 'SCHEDULE PICKUP TIME',
        field: 'scheduled_pick_up_time',
        unSortIcon: true,
        sortable: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.scheduled_pick_up_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL PICKUP TIME',
        field: 'actual_pick_up_time',
        unSortIcon: true,
        sortable: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.actual_pick_up_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'PICKUP FE CODE',
        field: 'pickup_fe_code',
        unSortIcon: true,
        sortable: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.pickup_fe_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'PICKUP FE  NAME',
        field: 'pickup_fe_name',
        unSortIcon: true,
        sortable: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.pickup_fe_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT CODE',
        field: 'client_code',
        unSortIcon: true,
        sortable: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.client_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'CLIENT NAME',
        field: 'center_name',
        unSortIcon: true,
        sortable: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.center_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'sample_lab_code',
        unSortIcon: true,
        sortable: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.sample_lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'DELAY BY(IN MINS)',
        field: 'delay_by',
        unSortIcon: true,
        sortable: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.delay_by);
        },
    },
];
var ONTIME_PICKUP_FE_LIST_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'LAB CODE',
        field: 'pickup_fe_lab_code',
        width: 350,
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.pickup_fe_lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'PICKUP FE CODE',
        field: 'pickup_fe_code',
        width: 300,
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.pickup_fe_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: ' PICKUP FE Name',
        width: 350,
        field: 'pickup_fe_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.pickup_fe_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'DELAYED PICKUP',
        field: 'delayPercentage',
        width: 300,
        unSortIcon: true,
        cellRenderer: function (params) {
            var _a;
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && (0, intracity_tat_service_1.convertFloatToPercentage)((_a = params.data) === null || _a === void 0 ? void 0 : _a.delayPercentage));
        },
    },
    {
        lockPosition: 'left',
        headerName: 'EARLY PICKUP',
        field: 'earlyPercentage',
        width: 300,
        unSortIcon: true,
        cellRenderer: function (params) {
            var _a;
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && (0, intracity_tat_service_1.convertFloatToPercentage)((_a = params.data) === null || _a === void 0 ? void 0 : _a.earlyPercentage));
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ON TIME PICKUP',
        field: 'ontimePercentage',
        width: 300,
        unSortIcon: true,
        cellRenderer: function (params) {
            var _a;
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && (0, intracity_tat_service_1.convertFloatToPercentage)((_a = params.data) === null || _a === void 0 ? void 0 : _a.ontimePercentage));
        },
    },
    {
        lockPosition: 'left',
        headerName: 'GRAND TOTAL',
        field: 'total_percentage',
        width: 300,
        unSortIcon: true,
        cellRenderer: function (params) {
            var _a;
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && (0, intracity_tat_service_1.convertFloatToPercentage)((_a = params.data) === null || _a === void 0 ? void 0 : _a.total_percentage));
        },
    },
];
var AUDIT_ANSWER_LIST_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'AUDITEE ID',
        field: 'auditee_id',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.auditee_id);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'AUDITEE NAME',
        field: 'auditee_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.auditee_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: ' AUDITEE LAB CODE',
        field: 'auditee_lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.auditee_lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'AUDITOR ID',
        field: 'auditor_id',
        unSortIcon: true,
        cellRenderer: function (params) { var _a; return (0, array_helpers_1.isFieldValueEmpty)(params.data && ((_a = params.data) === null || _a === void 0 ? void 0 : _a.auditor_id)); },
    },
    {
        lockPosition: 'left',
        headerName: 'AUDITOR NAME',
        field: 'auditor_name',
        unSortIcon: true,
        cellRenderer: function (params) { var _a; return (0, array_helpers_1.isFieldValueEmpty)(params.data && ((_a = params.data) === null || _a === void 0 ? void 0 : _a.auditor_name)); },
    },
    {
        lockPosition: 'left',
        headerName: 'AUDITOR MOBILE',
        field: 'auditor_mobile',
        unSortIcon: true,
        cellRenderer: function (params) { var _a; return (0, array_helpers_1.isFieldValueEmpty)(params.data && ((_a = params.data) === null || _a === void 0 ? void 0 : _a.auditor_mobile)); },
    },
    {
        lockPosition: 'left',
        headerName: 'COMPLETED DATE TIME',
        field: 'completed_date_time',
        unSortIcon: true,
        sortable: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.dateTimeFormatter)(params.data.completed_date_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ANSWERS',
        field: 'answers',
        tooltipField: 'answers',
        unSortIcon: true,
        cellRenderer: function (params) { var _a; return (0, array_helpers_1.isFieldValueEmpty)(params.data && ((_a = params.data) === null || _a === void 0 ? void 0 : _a.answers)); },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTION',
        sortable: false,
        cellRenderer: Imagesview_1.default,
        cellRendererSelector: function (params) {
            var _a;
            return {
                component: Imagesview_1.default,
                params: {
                    userData: (_a = params.data) === null || _a === void 0 ? void 0 : _a.image,
                },
            };
        },
    },
];
var DASHBOARD_GRAPHS_RLABTOPLAB_REPORT_COLUMNS = [
    {
        lockPosition: 'left',
        headerName: 'MASTER BARCODE',
        field: 'master_barcode',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.master_barcode);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'BARCODE',
        field: 'barcode',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.barcode); },
    },
    {
        lockPosition: 'left',
        headerName: 'R LAB CODE',
        field: 'r_lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.r_lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'R LAB NAME',
        field: 'r_lab_name',
        tooltipField: 'r_lab_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.r_lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'R LAB CITY',
        field: 'r_lab_city',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.r_lab_city);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'STATE',
        field: 'r_lab_state',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.r_lab_state);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'SCHEDULED P LAB CODE',
        field: 'scheduled_p_lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.scheduled_p_lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'SCHEDULED P LAB NAME',
        field: 'scheduled_p_lab_name',
        tooltipField: 'scheduled_p_lab_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.scheduled_p_lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'SCHEDULED P LAB CITY',
        field: 'scheduled_p_lab_city',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.isFieldValueEmpty)(params.data.scheduled_p_lab_city);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'SCHEDULED P LAB STATE',
        field: 'scheduled_p_lab_state',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.isFieldValueEmpty)(params.data.scheduled_p_lab_state);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL P LAB CODE',
        field: 'actual_p_lab_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.isFieldValueEmpty)(params.data.actual_p_lab_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL P LAB NAME',
        field: 'actual_p_lab_name',
        tooltipField: 'actual_p_lab_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.isFieldValueEmpty)(params.data.actual_p_lab_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL P LAB CITY',
        field: 'actual_p_lab_city',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.isFieldValueEmpty)(params.data.actual_p_lab_city);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL P LAB STATE',
        field: 'actual_p_lab_state',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.isFieldValueEmpty)(params.data.actual_p_lab_state);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'SHIPMENT CREATION DATE',
        field: 'shipment_creation_date',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.dateTimeFormatter)(params.data && params.data.shipment_creation_date);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL PICKUP TIME',
        field: 'actual_pickup_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.dateTimeFormatter)(params.data && params.data.actual_pickup_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL PICKUP FECODE',
        field: 'actual_pickup_fe_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.isFieldValueEmpty)(params.data.actual_pickup_fe_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL PICKUP FE NAME',
        field: 'actual_pickup_fe_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.isFieldValueEmpty)(params.data.actual_pickup_fe_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL LAB HANDOVER TIME',
        field: 'actual_lab_handover_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.dateTimeFormatter)(params.data && params.data.actual_lab_handover_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL LAB HANDOVER FECODE',
        field: 'actual_handover_fe_code',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.isFieldValueEmpty)(params.data.actual_handover_fe_code);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL LAB HANDOVER FE NAME',
        field: 'actual_handover_fe_name',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.isFieldValueEmpty)(params.data.actual_handover_fe_name);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL LAB RECEIVING TIME',
        field: 'shipment_closure_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.dateTimeFormatter)(params.data && params.data.shipment_closure_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'LANE',
        field: 'lane',
        unSortIcon: true,
        cellRenderer: function (params) { return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.lane); },
    },
    {
        lockPosition: 'left',
        headerName: 'LANE ROUTE',
        field: 'lane_route',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.isFieldValueEmpty)(params.data.lane_route);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'PICKUP CUTOFF TIME',
        field: 'pickup_cutoff_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.pickup_cutoff_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'DESIGNATED HITTING TIME',
        field: 'designated_hitting_time',
        unSortIcon: true,
        cellRenderer: function (params) {
            return (0, array_helpers_1.isFieldValueEmpty)(params.data && params.data.designated_hitting_time);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TAT IN DAYS',
        field: 'tat',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && params.data.tat >= 0 ? params.data.tat : '--';
        },
    },
    {
        lockPosition: 'left',
        headerName: 'SCHEDULED TAT',
        field: 'scheduled_tat',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.isFieldValueEmpty)(params.data.scheduled_tat);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'ACTUAL TAT',
        field: 'actual_tat',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.isFieldValueEmpty)(params.data.actual_tat);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'TAT BREACHED',
        field: 'tat_breached',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.preciousSampleKey)(params.data.tat_breached);
        },
    },
    {
        lockPosition: 'left',
        headerName: 'BREACH BY',
        field: 'breach_by',
        unSortIcon: true,
        cellRenderer: function (params) {
            return params.data && (0, array_helpers_1.isFieldValueEmpty)(params.data.breach_by);
        },
    },
];
exports.TableData = {
    VENDOR_ROWS: VENDOR_ROWS,
    USER_LOGISTICS_LIST: USER_LOGISTICS_LIST,
    VENDOR_COLUMNS: VENDOR_COLUMNS,
    USER_LIST_ROW: USER_LIST_ROW,
    USER_COLUMNS: USER_COLUMNS,
    APPROVAL_ROWS: APPROVAL_ROWS,
    FE_COLUMNS: FE_COLUMNS,
    FE_PAYROLE_ROWS: FE_PAYROLE_ROWS,
    FE_PAYROLE_COLUMNS: FE_PAYROLE_COLUMNS,
    FE_LEAVE_TRACKING_COLUMNS: FE_LEAVE_TRACKING_COLUMNS,
    FE_LEAVE_TRACKING_ROWS: FE_LEAVE_TRACKING_ROWS,
    FE_VIEW_PAYROLE_COLUMNS: FE_VIEW_PAYROLE_COLUMNS,
    KPI_LEAVES_COLUMNS: KPI_LEAVES_COLUMNS,
    KPI_LEAVES_ROWS: KPI_LEAVES_ROWS,
    ALL_DEDUTION_COLUMNS: ALL_DEDUTION_COLUMNS,
    ALL_DEDUTION_ROWS: ALL_DEDUTION_ROWS,
    FE_DRAFTED_COLUMNS: FE_DRAFTED_COLUMNS,
    FE_ONLEAVE_COLUMNS: FE_ONLEAVE_COLUMNS,
    FE_EXIT_COLUMNS: FE_EXIT_COLUMNS,
    SLOAT_BLOCKING_COLUMNS: SLOAT_BLOCKING_COLUMNS,
    FE_IN_ACTIVE_COLUMNS: FE_IN_ACTIVE_COLUMNS,
    NEW_FE_APPROVALCOLUMNS: NEW_FE_APPROVALCOLUMNS,
    LEVEL_ONE_PENDING_APPROVAL_COLUMNS: LEVEL_ONE_PENDING_APPROVAL_COLUMNS,
    LEVEL_TWO_PENDING_APPROVAL_COLUMNS: LEVEL_TWO_PENDING_APPROVAL_COLUMNS,
    ROLE_LIST_COLUMN: ROLE_LIST_COLUMN,
    SUPER_ADMIN_APPROVAL_LIST_COLUMN: SUPER_ADMIN_APPROVAL_LIST_COLUMN,
    VENDOR_DRAFTED_COLUMNS: VENDOR_DRAFTED_COLUMNS,
    VENDOR_EXIT_COLUMNS: VENDOR_EXIT_COLUMNS,
    AUDITMODULE_COLUMNS: AUDITMODULE_COLUMNS,
    ASSIGN_USERSTO_AUDIT: ASSIGN_USERSTO_AUDIT,
    SAMPLE_TRACKING_COLUMNS: SAMPLE_TRACKING_COLUMNS,
    ETRF_COLUMNS: ETRF_COLUMNS,
    ETRF_ROWS: ETRF_ROWS,
    CONTROL_TOWER_FEDETAILS_COLUMNS: CONTROL_TOWER_FEDETAILS_COLUMNS,
    CONTROL_CENTER_SAMPLELIST_COLUMNS: CONTROL_CENTER_SAMPLELIST_COLUMNS,
    FE_REJECT_COLUMNS: FE_REJECT_COLUMNS,
    CASH_COLLECTION_COLUMNS: CASH_COLLECTION_COLUMNS,
    BAGGING_LIFECYCLE_COLUMNS: BAGGING_LIFECYCLE_COLUMNS,
    PRECIOUS_SAMPLE_MASTER_COLUMNS: PRECIOUS_SAMPLE_MASTER_COLUMNS,
    BAGGING_MASTER_ROW: BAGGING_MASTER_ROW,
    BAGGING_MASTER_COLUMNS: BAGGING_MASTER_COLUMNS,
    FE_LAB_MASTER_COLUMNS: FE_LAB_MASTER_COLUMNS,
    FE_LAB_MASTER_ROWS: FE_LAB_MASTER_ROWS,
    LAB_MASTER_COLUMNS: LAB_MASTER_COLUMNS,
    LAB_MASTER_ROWS: LAB_MASTER_ROWS,
    DYNAMIC_ROUTE_MASTER_COLUMNS: DYNAMIC_ROUTE_MASTER_COLUMNS,
    DYNAMIC_ROUTE_MASTER_ROWS: DYNAMIC_ROUTE_MASTER_ROWS,
    CASH_PENDING_COLUMNS: CASH_PENDING_COLUMNS,
    DELAYED_DYNAMIC_PICKUP_COLUMNS: DELAYED_DYNAMIC_PICKUP_COLUMNS,
    TOTAL_FE_COLUMNS: TOTAL_FE_COLUMNS,
    MIS_REPORTS_EVENT_PERFORMANCE: MIS_REPORTS_EVENT_PERFORMANCE,
    MIS_REPORTS_TAB: MIS_REPORTS_TAB,
    VISITS_LIST_COLUMNS: VISITS_LIST_COLUMNS,
    VENDOR_APPROVAL_LIST_COLUMN: VENDOR_APPROVAL_LIST_COLUMN,
    CRE_ROWS: CRE_ROWS,
    CRE_ACTIVE_LIST: CRE_ACTIVE_LIST,
    DYNAMIC_PICKUP_ROWS: DYNAMIC_PICKUP_ROWS,
    DYNAMIC_PICKUP_AVAILABLEFE_ROWS: DYNAMIC_PICKUP_AVAILABLEFE_ROWS,
    ONDEMAND_REQUEST_COLUMNS: ONDEMAND_REQUEST_COLUMNS,
    MYREPORTS_LIST: MYREPORTS_LIST,
    ONDEMAND_PICKUP_COLUMNS: ONDEMAND_PICKUP_COLUMNS,
    CC_TIME_SLOT: CC_TIME_SLOT,
    CC_DISPLAY_SLOTS: CC_DISPLAY_SLOTS,
    CC_MODIFIED_SLOTS: CC_MODIFIED_SLOTS,
    ROUTE_PLANNER_COLUMNS: ROUTE_PLANNER_COLUMNS,
    ASSIGN_ONDEMAND_REQUEST: ASSIGN_ONDEMAND_REQUEST,
    UNASSIGNED_DATA: UNASSIGNED_DATA,
    WORKINGHOURS_COLUMNS: WORKINGHOURS_COLUMNS,
    APPROVAL_ROUTE_PLANNER_COLUMNS: APPROVAL_ROUTE_PLANNER_COLUMNS,
    Lab_Master_Slots: Lab_Master_Slots,
    UNTAGGED_CLIENTS_COLUMNS: UNTAGGED_CLIENTS_COLUMNS,
    UNASSIGNED_DYNAMIC_PICKUPS: UNASSIGNED_DYNAMIC_PICKUPS,
    AT_RISK_PENDING_PICKUP_COLUMNS: AT_RISK_PENDING_PICKUP_COLUMNS,
    HUB_MASTER_COLUMNS: HUB_MASTER_COLUMNS,
    UNASSIGNED_STATIC_PICKUPS: UNASSIGNED_STATIC_PICKUPS,
    ONDEMAND_UNASSIGNED_COLUMNS: ONDEMAND_UNASSIGNED_COLUMNS,
    ONDEMAND_REJECTED_COLUMNS: ONDEMAND_REJECTED_COLUMNS,
    MASTERBOX_TRACKING_COLUMNS: MASTERBOX_TRACKING_COLUMNS,
    ALL_VISITS_COLUMNS: ALL_VISITS_COLUMNS,
    ASSIGNED_COMPLETED_COLUMNS: ASSIGNED_COMPLETED_COLUMNS,
    SHIPMENT_TRACKING_COLUMNS: SHIPMENT_TRACKING_COLUMNS,
    NOTIFICATION_LIST_COLUMNS: NOTIFICATION_LIST_COLUMNS,
    ONDEMAND_PROCESSING_PICKUPS_COLUMNS: ONDEMAND_PROCESSING_PICKUPS_COLUMNS,
    FE_ATTENDANCE_REPORT_COLUMNS: FE_ATTENDANCE_REPORT_COLUMNS,
    RLABTOPLAB_REPORT_COLUMNS: RLABTOPLAB_REPORT_COLUMNS,
    NOTHING_TOPICKUP_REPORT_COLUMNS: NOTHING_TOPICKUP_REPORT_COLUMNS,
    Outstation_Master_Box_REPORT_COLUMNS: Outstation_Master_Box_REPORT_COLUMNS,
    RLABTOPLAB_METRICS_COLUMNS: RLABTOPLAB_METRICS_COLUMNS,
    RLABTOPLAB_REPORT_GRPAH_COLUMNS: RLABTOPLAB_REPORT_GRPAH_COLUMNS,
    SUCCESSFULVISITS_REPORT_COLUMNS: SUCCESSFULVISITS_REPORT_COLUMNS,
    ADD_VISIT_COLUMNS: ADD_VISIT_COLUMNS,
    Outstation_Master_Box_GRAPH_PENDIGN_COLUN: exports.Outstation_Master_Box_GRAPH_PENDIGN_COLUN,
    Outstation_Master_Box_GRAPH_PENDIGN_RECIEVED_COLUN: exports.Outstation_Master_Box_GRAPH_PENDIGN_RECIEVED_COLUN,
    REGION_LISTING_COLUMNS: REGION_LISTING_COLUMNS,
    USER_REGION_LISTING_COLUMNS: USER_REGION_LISTING_COLUMNS,
    USER_MAPPING_ROWS: USER_MAPPING_ROWS,
    Region_List_ROWS: Region_List_ROWS,
    INTRACITY_FE_TAT_LIST_COLUMNS: exports.INTRACITY_FE_TAT_LIST_COLUMNS,
    INTRACITY_FE_ONTIME_PICKUP_LIST_COLUMNS: exports.INTRACITY_FE_ONTIME_PICKUP_LIST_COLUMNS,
    SHIPMENT_ROUTE_LIST_COLUMNS: exports.SHIPMENT_ROUTE_LIST_COLUMNS,
    INTRACITY_TAT_FE_LIST_COLUMNS: INTRACITY_TAT_FE_LIST_COLUMNS,
    ON_TIME_PICKUP_LIST_COLUMNS: ON_TIME_PICKUP_LIST_COLUMNS,
    INTRACITY_TAT_SAMPLE_LIST_COLUMNS: INTRACITY_TAT_SAMPLE_LIST_COLUMNS,
    ONTIME_PICKUP_FE_LIST_COLUMNS: ONTIME_PICKUP_FE_LIST_COLUMNS,
    AUDIT_ANSWER_LIST_COLUMNS: AUDIT_ANSWER_LIST_COLUMNS,
    DASHBOARD_GRAPHS_RLABTOPLAB_REPORT_COLUMNS: DASHBOARD_GRAPHS_RLABTOPLAB_REPORT_COLUMNS,
};
exports.default = exports.TableData;
