"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var formik_1 = require("formik");
var jwt_decode_1 = require("jwt-decode");
var React = require("react");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var universal_cookie_1 = require("universal-cookie");
var config_action_1 = require("../../actions/config_action");
var user_action_1 = require("../../actions/user_action");
var api_service_1 = require("../../api/api_service");
var button_component_1 = require("../../common/buttons/button_component");
var text_filed_component_1 = require("../../common/text_filed/text_filed_component");
var appConstants_1 = require("../../constants/appConstants");
var formFieldConstants_1 = require("../../constants/formFieldConstants");
var schema_1 = require("../../constants/schema");
var user_reducer_1 = require("../../reducers/user_reducer");
var array_helpers_1 = require("../../utils/array_helpers");
var generic_api_calls_1 = require("../generic_api_calls");
var lodash_1 = require("./../../utils/lodash");
require("./LoginComponent.scss");
var LoginComponent = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var nav = (0, react_router_1.useNavigate)();
    var _a = React.useState(''), apiErrorMessage = _a[0], setApiErrorMessage = _a[1];
    var _b = React.useState(''), captchaUUID = _b[0], setCaptchaUUID = _b[1];
    var _c = React.useState(''), captchaImage = _c[0], setCaptchaImage = _c[1];
    var _d = React.useState(false), captchShouldBeReloaded = _d[0], setCaptchShouldBeReloaded = _d[1];
    var pathVariables = (0, react_router_1.useParams)();
    var cookies = new universal_cookie_1.default();
    var submitHandler = function (payload) {
        var endPoint = pathVariables['*']
            ? api_service_1.api_service.api_urls.SSO_LOGIN
            : api_service_1.api_service.api_urls.LOGIN_BY_PASSWORD;
        var loginPayload = {
            username: payload.username,
            password: payload.password,
            captchaCode: payload.captchaCode,
            captchaUuid: captchaUUID,
            loginType: 'byUserName',
        };
        if (pathVariables['*']) {
            loginPayload = {
                loginType: 'bySSO',
                aesToken: pathVariables['*'],
            };
            dispatch(user_action_1.default.setLogout());
            cookies.remove('cookies_jwt_token');
            cookies.remove('cookies_refresh_token');
        }
        api_service_1.api_service
            .post({
            endPoint: endPoint,
            payLoad: loginPayload,
            showLoader: true,
            domain: process.env.AUTHENTICATION_SERVER_URL,
            customHeaders: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        })
            .then(function (res) { return __awaiter(void 0, void 0, void 0, function () {
            var decoded_token_1, decoded_refresh_token_1;
            return __generator(this, function (_a) {
                if (res.data.status === 200) {
                    dispatch(user_action_1.default.setLoginStatus({
                        loginStatus: false,
                        jwt_token: res.data.data.token,
                        refresh_token: res.data.data.refreshToken,
                        code: res.data.data.code,
                        firstName: res.data.data.firstName,
                        lastName: res.data.data.lastName,
                        id: res.data.data.id,
                        is_sso_user: res.data.data.is_sso_user,
                    }));
                    decoded_token_1 = (0, jwt_decode_1.default)(res.data.data.token);
                    decoded_refresh_token_1 = (0, jwt_decode_1.default)(res.data.data.refreshToken);
                    cookies.set('cookies_jwt_token', res.data.data.token, {
                        expires: new Date(decoded_token_1.exp * 1000),
                        secure: true,
                        httpOnly: false,
                        sameSite: true,
                    });
                    cookies.set('cookies_refresh_token', res.data.data.refreshToken, {
                        expires: new Date(decoded_refresh_token_1.exp * 1000),
                        secure: true,
                        httpOnly: false,
                        sameSite: true,
                    });
                    (0, generic_api_calls_1.saveToken)()
                        .then(function (tokenRes) {
                        if (tokenRes.statusCode == 200) {
                            (0, generic_api_calls_1.fetchConfigData)(res.data.data.token)
                                .then(function (values) {
                                var configRes = values;
                                var feTypesResponse = (0, array_helpers_1.convertFE_Types_From_Config)(configRes[0].fe_types_and_remarks);
                                var inventoryResponse = (0, array_helpers_1.convertInventoryoptionFromConfig)(configRes[0].inventories);
                                var genderResponse = (0, array_helpers_1.convertGenderOptionFromConfig)(configRes[0].gender);
                                var tenureResponse = (0, array_helpers_1.covertTenureDropdownOptionsFromConfig)(configRes[0].tenure);
                                var companyResponse = (0, array_helpers_1.covertCompanyDropdownOptionsFromConfig)(configRes[0].company);
                                var usersListResponse = (0, array_helpers_1.convertUserListDropdownOptionsFormConfig)(configRes[0].user_type);
                                var exitReasonResponse = (0, array_helpers_1.convertLabelAndValueToConfig)(configRes[0].fe_exit_reasons_list);
                                var exitVoluntorilyReasonResponse = (0, array_helpers_1.convertLabelAndValueToConfig)(configRes[0].voluntarily_list);
                                var exitInVoluntorilyReasonResponse = (0, array_helpers_1.convertLabelAndValueToConfig)(configRes[0].involuntarily_list);
                                var rejectReasonResposne = (0, array_helpers_1.convertRejectTypelistFromResponse)(configRes[0].fe_reject_types);
                                var onboardingResponse = (0, array_helpers_1.convertLabelAndValueToConfig)(configRes[0].onboard_status);
                                var vehicleTypeResponse = (0, array_helpers_1.convertVehicleTypeDropdownOptionFromConfig)(configRes[0].vehicle_type);
                                var zonesResponse = (0, array_helpers_1.convertZonesDropdwonOptionFromConfig)(configRes[0].zones);
                                var proofOfAddressType = (0, array_helpers_1.convertGenderOptionFromConfig)(configRes[0].address_proof_type);
                                var vendor_entity = (0, array_helpers_1.convertGenderOptionFromConfig)(configRes[0].vendor_entity_type);
                                var vendor = (0, array_helpers_1.convertGenderOptionFromConfig)(configRes[0].vendor);
                                var vendor_contract_types = (0, array_helpers_1.convertGenderOptionFromConfig)(configRes[0].vendor_contract_type);
                                var vendor_duration = (0, array_helpers_1.convertGenderOptionFromConfig)(configRes[0].vendor_duration);
                                var user_types = (0, array_helpers_1.convertGenderOptionFromConfig)(configRes[0].user_types);
                                var sample_status = (0, array_helpers_1.convertGenderOptionFromConfig)(configRes[0].sample_status);
                                var auditStatusResposne = (0, array_helpers_1.convertAuditStatusListFromRes)(configRes[0].audit_status);
                                var routeAssignmentTypeRes = (0, array_helpers_1.convertRouteAssignmentTypeFromRes)(configRes[0].fe_route_assignment_type);
                                var features = res.data.data.features;
                                var menuList = features.filter(function (feature) { return feature.isMenuItem === true; });
                                var uniqueMenuList = lodash_1.default.uniqBy(menuList, 'moduleName');
                                var featuresList = (0, array_helpers_1.getFeaturesList)(uniqueMenuList, features);
                                var reportStatusResponse = (0, array_helpers_1.convertReportStatusDropdownOptionFromConfig)(configRes[0].report_status);
                                var reportTypesList = (0, array_helpers_1.convertReportTypeDropdownOptionFromConfig)(configRes[0].report_type);
                                var cash_status = (0, array_helpers_1.covnertCashReportStatusFromConfig)(configRes[0].cash_status);
                                var center_type = (0, array_helpers_1.convertGenderOptionFromConfig)(configRes[0].center_type);
                                var visit_status = (0, array_helpers_1.convertVisitStatusFromConfig)(configRes[0].visit_status);
                                var visit_type = (0, array_helpers_1.convertVisitTypesFromConfig)(configRes[0].visit_type);
                                var routePlannerStatusTypes = (0, array_helpers_1.convertVisitTypesFromConfig)(configRes[0].route_plan_status);
                                var hub_type = (0, array_helpers_1.convertVisitTypesFromConfig)(configRes[0].hub_type);
                                var master_box_status = (0, array_helpers_1.covertMasterBoxStatusFromConfig)(configRes[0].bag_status);
                                var etrf_status = (0, array_helpers_1.convertVisitTypesFromConfig)(configRes[0].etrf_status);
                                var sso_user_data = configRes[0].user_details;
                                var shipmentStatus = (0, array_helpers_1.convertVisitTypesFromConfig)(configRes[0].shipment_status);
                                var notification_category = (0, array_helpers_1.convertNotificationCategoriesFromRes)(configRes[0].notification_categories);
                                var notification_occurrence = (0, array_helpers_1.convertNotificationCategoriesFromRes)(configRes[0].notification_frequency);
                                var routing_assignments = (0, array_helpers_1.convertRouteAssignmentTypeFromRes)(configRes[0].routing_assignment_type);
                                var audit_types = (0, array_helpers_1.convertRouteAssignmentTypeFromRes)(configRes[0].audit_type);
                                dispatch((0, config_action_1.fetch_types)(feTypesResponse));
                                dispatch((0, config_action_1.fetch_inventories)(inventoryResponse));
                                dispatch((0, config_action_1.fetch_tensure)(tenureResponse));
                                dispatch((0, config_action_1.fetch_company)(companyResponse));
                                dispatch((0, config_action_1.fetch_exit_reson_list)(exitReasonResponse));
                                dispatch((0, config_action_1.fetch_voluntorily_exit_reson_list)(exitVoluntorilyReasonResponse));
                                dispatch((0, config_action_1.fetch_in_voluntorily_exit_reson_list)(exitInVoluntorilyReasonResponse));
                                dispatch((0, config_action_1.fetch_reject_reason_list)(rejectReasonResposne));
                                dispatch((0, config_action_1.fetch_onboarding_status)(onboardingResponse));
                                dispatch((0, config_action_1.fetch_vehicleTypes)(vehicleTypeResponse));
                                dispatch((0, config_action_1.fetch_genderTypes)(genderResponse));
                                dispatch((0, config_action_1.fetch_zoneTypes)(zonesResponse));
                                dispatch((0, config_action_1.fetch_address_proof_types)(proofOfAddressType));
                                dispatch((0, config_action_1.fetch_userTypes)(usersListResponse));
                                dispatch((0, config_action_1.fetch_countries)(appConstants_1.countryDropdownList));
                                dispatch((0, config_action_1.fetch_AuditStatusList)(auditStatusResposne));
                                dispatch((0, config_action_1.fetch_vendor)(vendor));
                                dispatch((0, config_action_1.fetch_entity_types)(vendor_entity));
                                dispatch((0, config_action_1.fetch_vendor_duration)(vendor_duration));
                                dispatch((0, config_action_1.fetch_vendor_contract_types)(vendor_contract_types));
                                dispatch((0, config_action_1.fetch_user_type)(user_types));
                                dispatch((0, config_action_1.fetch_SampleStatusList)(sample_status));
                                dispatch((0, config_action_1.FETCH_ROUTE_ASSIGNMENT_TYPE)(routeAssignmentTypeRes));
                                dispatch((0, config_action_1.menu_list)(uniqueMenuList));
                                dispatch((0, config_action_1.features_list)(featuresList));
                                dispatch((0, config_action_1.fetch_report_status_types)(reportStatusResponse));
                                dispatch((0, config_action_1.fetch_report_types)(reportTypesList));
                                dispatch((0, config_action_1.fetch_cash_status)(cash_status));
                                dispatch((0, config_action_1.fetch_centre_types)(center_type));
                                dispatch((0, config_action_1.fetch_route_plan_center_types)(configRes[0].route_plan_center_types));
                                dispatch((0, config_action_1.fetch_route_plan_data_types)(configRes[0].route_plan_data_types));
                                dispatch((0, config_action_1.fetch_visit_status_types)(visit_status));
                                dispatch((0, config_action_1.fetch_visit_types)(visit_type));
                                dispatch((0, config_action_1.fetch_route_planner_types)(routePlannerStatusTypes));
                                dispatch((0, config_action_1.hub_types)(hub_type));
                                dispatch((0, config_action_1.fetch_masterbox_status)(master_box_status));
                                dispatch((0, config_action_1.fetch_etrf_status)(etrf_status));
                                dispatch((0, config_action_1.fetch_sso_user_details)(sso_user_data));
                                dispatch((0, config_action_1.fetch_notification_category)(notification_category));
                                dispatch((0, config_action_1.fetch_notification_occurrence)(notification_occurrence));
                                dispatch((0, config_action_1.shipment_status)(shipmentStatus));
                                dispatch((0, config_action_1.fetch_notification_category)(notification_category));
                                dispatch((0, config_action_1.fetch_notification_occurrence)(notification_occurrence));
                                dispatch((0, config_action_1.fetch_route_assignments)(routing_assignments));
                                dispatch((0, config_action_1.fetch_audit_types)(audit_types));
                                nav('/');
                                dispatch(user_action_1.default.setLoginStatus({
                                    loginStatus: true,
                                    jwt_token: res.data.data.token,
                                    refresh_token: res.data.data.refreshToken,
                                    code: res.data.data.code,
                                    firstName: res.data.data.firstName,
                                    lastName: res.data.data.lastName,
                                    id: res.data.data.id,
                                    is_sso_user: res.data.data.is_sso_user,
                                    loginType: loginPayload.loginType === 'bySSO'
                                        ? user_reducer_1.LOGIN_TYPE.LOGIN_BY_SSO
                                        : user_reducer_1.LOGIN_TYPE.LOGIN_BY_PASSWORD,
                                }));
                                cookies.set('cookies_jwt_token', res.data.data.token, {
                                    expires: new Date(decoded_token_1.exp * 1000),
                                });
                                cookies.set('cookies_refresh_token', res.data.data.refreshToken, {
                                    expires: new Date(decoded_refresh_token_1.exp * 1000),
                                });
                            })
                                .catch(function (err) {
                                setApiErrorMessage(appConstants_1.CONFIGAPIFAIL);
                                fetchCaptchaImage();
                            });
                        }
                    })
                        .catch(function (err) {
                        return err;
                    });
                }
                else {
                    setApiErrorMessage(appConstants_1.LOGINAPIFAIL);
                    fetchCaptchaImage();
                }
                return [2];
            });
        }); })
            .catch(function (err) {
            setApiErrorMessage(appConstants_1.LOGINAPIFAIL);
            fetchCaptchaImage();
        });
    };
    var fetchCaptchaImage = function () {
        var authServer = process.env.AUTHENTICATION_SERVER_URL.replace('/auth', '');
        api_service_1.api_service
            .get({
            endPoint: api_service_1.api_service.api_urls.GET_CAPTCHA,
            showLoader: true,
            domain: authServer,
            customHeaders: {
                'X-Requested-With': 'XMLHttpRequest',
                'x-api-key': process.env.CAPTCHA_X_API_KEY,
            },
        })
            .then(function (response) {
            if (response.status === 200) {
                var responseData = response && response.data && response.data.data
                    ? response.data.data
                    : undefined;
                setCaptchaUUID(responseData.captchaUuid);
                setCaptchaImage(responseData.captchaImgBase64Format);
            }
            else {
                setApiErrorMessage(response.data && response.data.message ? response.data.message : undefined);
            }
        })
            .catch(function (error) {
            console.log('error', error);
        });
    };
    var forgotPassword = function () {
        nav('forgotPassword');
    };
    React.useEffect(function () {
        if (pathVariables['*']) {
            submitHandler({
                username: '',
                password: '',
                captchaCode: '',
            });
        }
    }, [pathVariables]);
    React.useEffect(function () {
        fetchCaptchaImage();
    }, [captchShouldBeReloaded]);
    return (React.createElement("div", { className: "login-wrapper" },
        React.createElement("div", { className: "login-container" },
            React.createElement("div", { className: "brand-logo" },
                React.createElement("img", { src: require('../../../assets/images/logo.svg'), alt: "logo", className: "login-log" })),
            React.createElement("div", { className: "form-filed-wrapper" },
                React.createElement(formik_1.Formik, { initialValues: {
                        username: '',
                        password: '',
                        captchaCode: '',
                    }, validateOnBlur: false, validationSchema: schema_1.schemas.SignupSchema, onSubmit: submitHandler }, function (_a) {
                    var errors = _a.errors, touched = _a.touched, values = _a.values, setFieldValue = _a.setFieldValue, resetForm = _a.resetForm, setFieldTouched = _a.setFieldTouched;
                    return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper login-fieldsr" },
                        formFieldConstants_1.Login_Form_Fields.map(function (field) {
                            return (React.createElement("div", { className: "login-input-conatiner" },
                                React.createElement(field.inputComponent, { required: true, name: field.name, className: "", type: field.name === 'password' ? 'password' : 'text', placeholder: field.placeholder, label: field.label, value: values[field.fieldValue], defaultVal: field.defaultVal, onChange: function (val) {
                                        setFieldValue(field.fieldValue, val);
                                        setFieldTouched(field.fieldValue, true);
                                    } }),
                                errors[field.fieldValue] && touched[field.fieldValue] ? (React.createElement("div", { className: "error" }, errors[field.fieldValue])) : null));
                        }),
                        React.createElement("div", { className: "captcha-component" },
                            React.createElement("div", { className: "captcha-wrapper" },
                                React.createElement("div", { className: "captcha" },
                                    captchaImage && (React.createElement("img", { src: "data:image/jpeg;base64,".concat(captchaImage) })),
                                    React.createElement("div", { className: "captcha-reloader" },
                                        React.createElement("span", { className: "icon-refresh", onClick: function () { return setCaptchShouldBeReloaded(function (prev) { return !prev; }); } }))),
                                React.createElement("div", { className: "captcha-filed" },
                                    React.createElement(text_filed_component_1.default, { label: "Enter captha", required: true, type: 'text', value: values['captchaCode'], maxLength: 6, defaultVal: '', onChange: function (val) {
                                            setFieldValue('captchaCode', val);
                                            setFieldTouched('captchaCode', true);
                                        } }))),
                            errors['captchaCode'] && touched['captchaCode'] ? (React.createElement("div", { className: "error" }, errors['captchaCode'])) : null),
                        React.createElement("div", { className: "form-btns login-btns" },
                            React.createElement(button_component_1.default, { type: "button", buttonName: "forgot password?", color: "yellow-outline", onClickHandler: forgotPassword }),
                            React.createElement(button_component_1.default, { type: "submit", buttonName: "login", color: "yellow", isError: Object.keys(errors).length > 0 }))));
                })),
            apiErrorMessage && (React.createElement("div", { className: "error" },
                React.createElement("p", null, apiErrorMessage)))),
        React.createElement("h1", { className: "verison-number" }, "v0.0.120")));
};
exports.default = LoginComponent;
