"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var React = require("react");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var button_component_1 = require("../../../../common/buttons/button_component");
var multi_selector_1 = require("../../../../common/checkbox/multi_selector");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var react_paginate_1 = require("../../../../common/react_pagination/react_paginate");
var search_component_1 = require("../../../../common/search/search_component");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var text_filed_with_fromto_Daterange_1 = require("../../../../common/text_filed/text_filed_with_fromto_Daterange");
var appConstants_1 = require("../../../../constants/appConstants");
var route_path_1 = require("../../../../constants/route_path");
var table_data_1 = require("../../../../constants/table_data");
var menu_constants_1 = require("../../../../menuList/menu_constants");
var array_helpers_1 = require("../../../../utils/array_helpers");
var leavetracking_apicalls_1 = require("../../../field_exicutive/leave_tracking/leavetracking.apicalls");
var ETRF_apicalls_1 = require("../ETRF_apicalls");
var ETRFList = function () {
    var _a = React.useState(undefined), etrfList = _a[0], setETRFList = _a[1];
    var _b = React.useState(''), searchTerm = _b[0], setSearchTerm = _b[1];
    var _c = React.useState(new Date().toString()), filterFromDate = _c[0], setFromDateFilter = _c[1];
    var _d = React.useState(new Date().toString()), filterToDate = _d[0], setToDateFilter = _d[1];
    var _e = React.useState(appConstants_1.defaultPageLimit), PageLimit = _e[0], setPageLimit = _e[1];
    var stateList = (0, react_redux_1.useSelector)(function (store) { return store.appConfigList; }).stateList;
    var _f = React.useState(1), pageCount = _f[0], setpageCount = _f[1];
    var _g = React.useState([]), citiesData = _g[0], setCitiesData = _g[1];
    var _h = React.useState([]), filterSelectedCities = _h[0], setSelectedFilterCities = _h[1];
    var _j = React.useState([]), filterSelectedStates = _j[0], setSelectedFilterStates = _j[1];
    var _k = React.useState(0), totalPageCount = _k[0], setTotalPageCount = _k[1];
    var _l = React.useState(0), totalCount = _l[0], setTotalCount = _l[1];
    var _m = React.useState(''), errorMessage = _m[0], setErrorMessage = _m[1];
    var _o = React.useState(null), ETRFstatus = _o[0], setETRFStatus = _o[1];
    var etrfStatus = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.etrf_status; });
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.ETRFDocuments);
    var nav = (0, react_router_1.useNavigate)();
    var clickRow = function (event) {
        if (features[menu_constants_1.FEATURE_CONSTANTS.ETRFView]) {
            nav("/".concat(route_path_1.default.PATHS.ETRFList, "/").concat(route_path_1.default.PATHS.ETRF_Profile, "/").concat(event.data._id, "/etrfprofile"));
        }
    };
    var callBackOnDateRangeSelect = function (dateRangeArray) {
        if (dateRangeArray) {
            setFromDateFilter(moment(dateRangeArray.start).format('YYYY-MM-DD'));
            setToDateFilter(moment(dateRangeArray.end).format('YYYY-MM-DD'));
        }
        if (dateRangeArray === null) {
            setFromDateFilter(null);
            setToDateFilter(null);
        }
    };
    var searchingHandler = function (e) {
        setSearchTerm(e.target.value);
    };
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var visitTypeHandler = function (visitType) {
        setETRFStatus(visitType);
    };
    var callbackUpdateSelectedStates = function (value) {
        setCitiesData([]);
        setSelectedFilterCities([]);
        setSelectedFilterStates(value);
    };
    var callbackUpdateSelectedCities = function (value) {
        setSelectedFilterCities(value);
    };
    var fetchCities = function () { return __awaiter(void 0, void 0, void 0, function () {
        var stateIdArray, payloadToFetchCities;
        return __generator(this, function (_a) {
            stateIdArray = filterSelectedStates.map(function (state) { return state.id; });
            payloadToFetchCities = {
                state_id: stateIdArray,
            };
            (0, leavetracking_apicalls_1.fetchCitiesList)(payloadToFetchCities)
                .then(function (citiesListResponse) {
                var formattedCitiesList = (0, array_helpers_1.convertLabelAndValueCity)(citiesListResponse.cities);
                setCitiesData(formattedCitiesList);
            })
                .catch(function (e) {
                setCitiesData([]);
            });
            return [2];
        });
    }); };
    var refetch = (0, react_query_1.useQuery)(['active-fe', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if (filterSelectedCities.length > 0) {
            var states_1 = [];
            var cities_1 = [];
            filterSelectedCities.map(function (selectedCityObj) {
                cities_1.push(selectedCityObj.short_code);
            });
            filterSelectedStates.map(function (selectedStateObj) {
                states_1.push(selectedStateObj.label);
            });
            payload['state'] =
                filterSelectedStates.length >= 30 ? ['ALL'] : states_1;
            payload['city'] =
                filterSelectedCities.length >= 500 ? ['ALL'] : cities_1;
        }
        if ((searchTerm === null || searchTerm === void 0 ? void 0 : searchTerm.trim().length) >= 3) {
            payload['search_string'] = searchTerm.toLocaleLowerCase().trim();
        }
        if (filterFromDate && filterToDate !== null) {
            payload['start_date'] = moment(filterFromDate).format('YYYY-MM-DD');
            payload['end_date'] = moment(filterToDate).format('YYYY-MM-DD');
        }
        if (ETRFstatus !== null) {
            payload['status'] = ETRFstatus.id;
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        (0, ETRF_apicalls_1.getETRFList)(payload)
            .then(function (response) {
            console.log(response, 'iuhjgbv');
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.data.count / pageSize);
            setErrorMessage("");
            setTotalPageCount(count);
            setETRFList(response.data.result);
            setTotalCount(response.data.count);
        })
            .catch(function (err) {
            setErrorMessage(err.message);
            setETRFList([]);
        });
    }).refetch;
    var FilterHandler = function () {
        refetch();
    };
    React.useEffect(function () {
        if (filterSelectedStates.length >= 1) {
            fetchCities();
        }
    }, [filterSelectedStates]);
    return (React.createElement("div", { className: "page-wrapper etrf-docs-page-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "e-TRF Documents"))),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "search-filter search-lg" },
                React.createElement(search_component_1.default, { placeholder: "Search by Barcode", searchValue: searchTerm, searchHandler: searchingHandler })),
            React.createElement("div", { className: "filter-options" },
                React.createElement(text_filed_with_fromto_Daterange_1.default, { onChangeHandler: callBackOnDateRangeSelect, presentDate: true }),
                React.createElement(drop_down_component_1.default, { disableClearable: true, value: ETRFstatus || null, placeholder: "Select Status", dropDownList: etrfStatus, onChange: visitTypeHandler }),
                React.createElement(multi_selector_1.default, { label: "State", dropDownList: stateList &&
                        stateList.sort(function (a, b) {
                            return a.label.localeCompare(b.label);
                        }), onChangeHnadlre: callbackUpdateSelectedStates, value: filterSelectedStates }),
                React.createElement(multi_selector_1.default, { noOptions: 'No cities found', hasSelectAll: false, label: "City", dropDownList: citiesData &&
                        citiesData.sort(function (a, b) {
                            return a.label.localeCompare(b.label);
                        }), onChangeHnadlre: callbackUpdateSelectedCities, value: filterSelectedCities || [] }),
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
        React.createElement("div", { className: "scrollable-content ".concat(features[menu_constants_1.FEATURE_CONSTANTS.ETRFView] ? 'clickable-row' : 'unclickablerow') },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.ETRF_COLUMNS, rows: etrfList, errorMessage: errorMessage, multiSelector: "multiselect", onRowClicked: clickRow, pagination: false, className: 'etrf-table' }),
            React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: etrfList === null || etrfList === void 0 ? void 0 : etrfList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value }))));
};
exports.default = ETRFList;
