"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var maps_api_for_javascript_1 = require("@here/maps-api-for-javascript");
var material_1 = require("@mui/material");
var React = require("react");
var map_tool_tip_1 = require("../../../../common/tool_tip/map_tool_tip");
var images_1 = require("../../../../constants/images");
var map_constants_1 = require("../../../../constants/map_constants");
var array_helpers_1 = require("../../../../utils/array_helpers");
var heat_map_api_service_1 = require("../heat_map_api_service");
var visitCount = 0;
var fesCount = 0;
var ViewRouteMap = function (_a) {
    var assignedVisitData = _a.assignedVisitData, unassignedVisitData = _a.unassignedVisitData, errorMessage = _a.errorMessage, selectedFEId = _a.selectedFEId, setRoundColors = _a.setRoundColors;
    var mapRef = React.useRef(null);
    var _b = React.useState([]), roundinfoColors = _b[0], setRoundInfoColors = _b[1];
    var _c = React.useState(false), showToolTip = _c[0], setShowToolTip = _c[1];
    var _d = React.useState(), toolTipInfo = _d[0], setTooTipInfo = _d[1];
    var callAssignedVisits = function (map, ui, platform) {
        var onResult = function (result, routeObj, ui, color, startEndInfo, visitIndex) {
            var distase = result.routes[0].sections[0].summary.length / 1000;
            var time = result.routes[0].sections[0].summary.baseDuration / 60;
            if (result.routes.length) {
                result.routes[0].sections.forEach(function (section) {
                    var linestring = maps_api_for_javascript_1.default.geo.LineString.fromFlexiblePolyline(section.polyline);
                    setRoundInfoColors(function (prev) { return __spreadArray(__spreadArray([], prev, true), [
                        { color: "".concat(color), fe_code: routeObj.fe_code },
                    ], false); });
                    var styleLine = {
                        style: {
                            strokeColor: "".concat(color),
                            lineWidth: 6,
                            fillColor: 'white',
                            lineTailCap: 'arrow-tail',
                            lineDash: [1, 1],
                            lineHeadCap: 'arrow-head',
                        },
                        data: {
                            id: routeObj.user_id,
                            lat: visitIndex == 0
                                ? routeObj.fe_current_location.latitude
                                : routeObj.visites[visitIndex].latitude,
                            lng: visitIndex == 0
                                ? routeObj.fe_current_location.longitude
                                : routeObj.visites[visitIndex].longitude,
                            scheduled_time: routeObj.visites[visitIndex].scheduled_time,
                            fe_id: routeObj.fe_code,
                            visit_type: visitIndex == 0
                                ? routeObj.visit_type
                                : routeObj.visites[visitIndex].visit_type,
                            feName: routeObj.fe_name,
                            base_location_address: routeObj.base_location_address,
                            startLocation: startEndInfo.startPointInfo.center_name,
                            endLocation: startEndInfo.endPointInfo.center_name,
                        },
                    };
                    console.log(startEndInfo, 'startEndInfo');
                    var routeLine = new maps_api_for_javascript_1.default.map.Polyline(linestring, styleLine);
                    (0, heat_map_api_service_1.displayRouteInformation)(routeLine, map, ui, distase, time, setShowToolTip, setTooTipInfo);
                    var startMarker = new maps_api_for_javascript_1.default.map.Marker(section.departure.place.location);
                    startMarker.title = styleLine.data;
                    (0, heat_map_api_service_1.markerEvents)(startMarker, startEndInfo.startPointInfo, ui, false);
                    var endMarker = new maps_api_for_javascript_1.default.map.Marker(section.arrival.place.location);
                    endMarker.title = styleLine.data;
                    (0, heat_map_api_service_1.markerEvents)(endMarker, startEndInfo.endPointInfo, ui, false);
                    map.addObjects([routeLine, startMarker, endMarker]);
                    map.getViewModel().setLookAtData({ bounds: routeLine.getBoundingBox() });
                });
            }
        };
        var selectedVisitList = (0, array_helpers_1.filterSelectedVisitList)(assignedVisitData, selectedFEId);
        selectedVisitList.map(function (element, index) {
            fesCount = index >= 1 ? index - index : fesCount;
            element.visites &&
                element.visites.map(function (visitElementes, visitIndex) {
                    try {
                        var originCoordinates = fesCount >= 1
                            ? index - index
                            : fesCount == visitIndex
                                ? "".concat(selectedVisitList[index].fe_current_location.latitude, ",").concat(selectedVisitList[index].fe_current_location.longitude)
                                : "".concat(element.visites[visitIndex - 1].latitude, ",").concat(element.visites[visitIndex - 1].longitude);
                        var destinationCoordinates = "".concat(element.visites[visitIndex].latitude, ",").concat(element.visites[visitIndex].longitude);
                        var routingParameters = {
                            routingMode: 'fast',
                            transportMode: 'scooter',
                            origin: originCoordinates && originCoordinates,
                            destination: destinationCoordinates && destinationCoordinates,
                            return: ['polyline', 'summary'],
                        };
                        var startEndobj_1 = {
                            startPointInfo: fesCount >= 1
                                ? index - index
                                : fesCount == visitIndex
                                    ? selectedVisitList[index]
                                    : element.visites[visitIndex - 1],
                            endPointInfo: element.visites[visitIndex],
                        };
                        if (visitIndex == 0) {
                            visitCount = visitCount + 1;
                        }
                        var colorCode_1 = map_constants_1.colorCodes[visitCount];
                        var router = platform.getRoutingService(null, 8);
                        router.calculateRoute(routingParameters, function (result) {
                            onResult(result, element, ui, colorCode_1, startEndobj_1, visitIndex);
                        }, function (error) {
                            console.log(error.message);
                        });
                    }
                    catch (err) {
                        console.log(err, 'errorData');
                    }
                });
        });
    };
    var callUnassignedVists = function (map, ui) {
        var lineString = new maps_api_for_javascript_1.default.geo.LineString();
        var unAssignedIcon = new maps_api_for_javascript_1.default.map.Icon(images_1.default.unassignedIcon, map_constants_1.MPA_ICONS_SIZE);
        unassignedVisitData.map(function (element, index) {
            try {
                var lat = element.latitude;
                var lng = element.longitude;
                console.log(lat, lng, 'latlonginfo');
                var startEndInfo = {
                    startPointInfo: element,
                    endPointInfo: element,
                };
                console.log(startEndInfo, 'locationInfo');
                var myLocMarker = new maps_api_for_javascript_1.default.map.Marker({
                    lat: lat,
                    lng: lng,
                });
                myLocMarker.setIcon(unAssignedIcon);
                (0, heat_map_api_service_1.markerEvents)(myLocMarker, startEndInfo.startPointInfo, ui, true);
                map.addObject(myLocMarker);
                map.addObject(new maps_api_for_javascript_1.default.map.Polyline(lineString));
            }
            catch (e) {
                console.log(e);
            }
        });
    };
    var calculateRouteMethod = function (map, ui, platform) {
        setRoundInfoColors([]);
        if (selectedFEId.length >= 1) {
            callAssignedVisits(map, ui, platform);
        }
        if (unassignedVisitData.length >= 1) {
            callUnassignedVists(map, ui);
        }
    };
    React.useLayoutEffect(function () {
        if (!mapRef.current)
            return;
        var platform = new maps_api_for_javascript_1.default.service.Platform({
            apikey: process.env.HERE_MAPS_API_KEY,
        });
        var defaultLayers = platform.createDefaultLayers({ pois: true });
        var map = new maps_api_for_javascript_1.default.Map(mapRef.current, defaultLayers.vector.normal.map.setMin(2), {
            center: { lat: 23.6913, lng: 85.2722 },
            zoom: 5,
            pixelRatio: window.devicePixelRatio || 1,
        });
        map.addLayer(defaultLayers.raster.terrain.map);
        var behavior = new maps_api_for_javascript_1.default.mapevents.Behavior(new maps_api_for_javascript_1.default.mapevents.MapEvents(map));
        var ui = maps_api_for_javascript_1.default.ui.UI.createDefault(map, defaultLayers);
        ui.getElement().style.zIndex = 999;
        console.log(ui, behavior);
        if (assignedVisitData) {
            calculateRouteMethod(map, ui, platform);
        }
        return function () {
            map.dispose();
        };
    }, [mapRef, assignedVisitData, unassignedVisitData, selectedFEId]);
    React.useEffect(function () {
        setRoundColors(roundinfoColors);
    }, [roundinfoColors]);
    return !errorMessage ? (React.createElement("div", { ref: mapRef, className: "profile-map-container" },
        React.createElement(material_1.Modal, { open: showToolTip, onClose: function () { return setShowToolTip(false); }, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" },
            React.createElement(material_1.Box, { className: " modal-map-tooltip" },
                React.createElement(map_tool_tip_1.default, { tooTipInformation: toolTipInfo }))))) : (React.createElement("div", { className: "server-error" }, errorMessage && errorMessage.toString()));
};
exports.default = ViewRouteMap;
