"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getETRFDetails = exports.getETRFList = void 0;
var api_service_1 = require("../../../api/api_service");
var getETRFList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.ETRF_LIST,
            domain: process.env.SAMPLE_TRAKING_URL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res.data);
            }
            var erroMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (e) {
            return reject(e);
        });
    });
};
exports.getETRFList = getETRFList;
var getETRFDetails = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.ETRF_DETAILS,
            domain: process.env.SAMPLE_TRAKING_URL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res.data);
            }
            var erroMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (e) {
            return reject(e);
        });
    });
};
exports.getETRFDetails = getETRFDetails;
