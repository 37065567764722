"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
require("./dropdown.scss");
var DropDownComponent = function (_a) {
    var onChange = _a.onChange, dropDownList = _a.dropDownList, placeholder = _a.placeholder, isDisabled = _a.isDisabled, name = _a.name, value = _a.value, ontextChange = _a.ontextChange, inputValue = _a.inputValue, className = _a.className, required = _a.required, key = _a.key, loading = _a.loading, disableClearable = _a.disableClearable, displayRemark = _a.displayRemark, onClose = _a.onClose, noOptionsText = _a.noOptionsText;
    var _b = React.useState([]), filteredDropdownList = _b[0], setFilteredDropdownList = _b[1];
    var _c = React.useState(null), selectedValue = _c[0], setSelectedValue = _c[1];
    React.useEffect(function () {
        setFilteredDropdownList(dropDownList);
        var params = {};
        if (value) {
            params['defaultValue'] = dropDownList.find(function (v) { return v.label === value.label; });
        }
    }, [dropDownList]);
    var onTextChange = function (val) {
        if (ontextChange === undefined || ontextChange === null) {
            if (val) {
                setFilteredDropdownList(dropDownList.filter(function (listitem) { return listitem.label.toUpperCase().includes(val.trim().toUpperCase()); }));
            }
            else {
                setFilteredDropdownList(dropDownList);
            }
        }
        else {
            ontextChange(val);
        }
    };
    var onCloseOfAutoComplete = function () {
        if (onClose) {
            onClose();
        }
        else {
            setFilteredDropdownList(dropDownList);
        }
    };
    var shouldDisbaleOption = function (option) {
        if (name === 'auditiesList') {
            if (option.label == 'CC') {
                return false;
            }
            else {
                return true;
            }
        }
        if (name === 'auditorsList') {
            if (option.label == 'FE') {
                return false;
            }
            else {
                return true;
            }
        }
        return false;
    };
    return (React.createElement("div", { className: "dropdown-wrapper ".concat(className, " "), key: key },
        React.createElement(material_1.Autocomplete, { noOptionsText: noOptionsText, loading: loading, key: key, loadingText: 'Searching...', disabled: isDisabled, disablePortal: true, disableClearable: disableClearable ? false : true, id: "combo-box-demo", options: filteredDropdownList, getOptionLabel: function (option) { return option.label; }, getOptionDisabled: function (option) { return shouldDisbaleOption(option); }, value: value ? value : null, onChange: function (event, value) {
                setSelectedValue(value);
                onChange(value);
            }, onClose: onCloseOfAutoComplete, renderInput: function (params) { return (React.createElement("div", { className: "".concat(required ? 'input-wrapper validate-filed ' : 'input-wrapper') },
                React.createElement(material_1.TextField, __assign({}, params, { name: name, label: placeholder, placeholder: placeholder, variant: "filled", onChange: function (e) {
                        onTextChange(e.target.value);
                    } })))); } }),
        displayRemark && (selectedValue === null || selectedValue === void 0 ? void 0 : selectedValue.remarks) && (React.createElement("p", { className: "file-formate-label" }, selectedValue.remarks))));
};
exports.default = DropDownComponent;
