"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.appConfigReducer = void 0;
var config_action_1 = require("../actions/config_action");
var initialState = {
    fe_types: [],
    inventories: [],
    tenure: [],
    company: [],
    zone: [],
    fe_exit_reasons_list: [],
    fe_voluntorily_exit_reasons_list: [],
    fe_in_voluntorily_exit_reasons_list: [],
    fe_reject_reasons_list: [],
    on_boarding_list: [],
    countryList: [],
    stateList: [],
    presentCityList: [],
    permenentCityList: [],
    labList: [],
    vendor_type: [],
    vendor_duration: [],
    FEIdListTOReplace: [],
    vehicleTypeList: [],
    genderTypeList: [],
    zoneTypeList: [],
    fetch_exit_fe_id: [],
    EXIT_FE_LIST_TYPES: undefined,
    addressProofType: [],
    userTypeList: [],
    ccList: [],
    feList: [],
    auditCityList: [],
    HudIdList: [],
    auditStatusList: [],
    vendor_contract_types: [],
    entity_type: [],
    sample_status: [],
    user_type: [],
    routing_assignment_type: [],
    features: [],
    featureslist: [],
    reportStatusList: [],
    reportTypeList: [],
    cashStatus: [],
    center_type: [],
    route_plan_center_types: null,
    route_plan_data_types: null,
    visitStatusList: [],
    visitTypesList: [],
    route_planner_status_types: [],
    hub_type: [],
    currentMenu: {
        mainModule: 'Overview',
        subModule: '',
        subChaildModule: '',
    },
    masterbox_status: [],
    etrf_status: [],
    sso_user_details: null,
    shipment_status: [],
    notification_category: [],
    notification_occurrence: [],
    routing_assignments: [],
    audit_types: [],
    feWorkingCitiesList: []
};
var appConfigReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case config_action_1.CONFIG_ACTIONS.FE_TYPES:
            return __assign(__assign({}, state), { fe_types: action.payload });
        case config_action_1.CONFIG_ACTIONS.INVENTORIES:
            return __assign(__assign({}, state), { inventories: action.payload });
        case config_action_1.CONFIG_ACTIONS.FETCH_VENDOR:
            return __assign(__assign({}, state), { vendor_type: action.payload });
        case config_action_1.CONFIG_ACTIONS.FETCH_USER_TYPE:
            return __assign(__assign({}, state), { user_type: action.payload });
        case config_action_1.CONFIG_ACTIONS.FETCH_ENTITY:
            return __assign(__assign({}, state), { entity_type: action.payload });
        case config_action_1.CONFIG_ACTIONS.FETCH_VENDOR_DURATION:
            return __assign(__assign({}, state), { vendor_duration: action.payload });
        case config_action_1.CONFIG_ACTIONS.TENSURE:
            return __assign(__assign({}, state), { tenure: action.payload });
        case config_action_1.CONFIG_ACTIONS.COMPANY:
            return __assign(__assign({}, state), { company: action.payload });
        case config_action_1.CONFIG_ACTIONS.ZONE:
            return __assign(__assign({}, state), { zone: action.payload });
        case config_action_1.CONFIG_ACTIONS.FE_EXIT_RESON_LIST:
            return __assign(__assign({}, state), { fe_exit_reasons_list: action.payload });
        case config_action_1.CONFIG_ACTIONS.FE_EXIT_VOLUNTORLY_RESON_LIST:
            return __assign(__assign({}, state), { fe_voluntorily_exit_reasons_list: action.payload });
        case config_action_1.CONFIG_ACTIONS.FE_EXIT_INVOLUNTORLY_RESON_LIST:
            return __assign(__assign({}, state), { fe_in_voluntorily_exit_reasons_list: action.payload });
        case config_action_1.CONFIG_ACTIONS.FE_REJECT_RESON_LIST:
            return __assign(__assign({}, state), { fe_reject_reasons_list: action.payload });
        case config_action_1.CONFIG_ACTIONS.FE_REJECT_RESON_LIST:
            return __assign(__assign({}, state), { fe_reject_reasons_list: action.payload });
        case config_action_1.CONFIG_ACTIONS.FETCH_ONBAORDING_STATUS:
            return __assign(__assign({}, state), { on_boarding_list: action.payload });
        case config_action_1.CONFIG_ACTIONS.FETCH_COUNTRIES:
            return __assign(__assign({}, state), { countryList: action.payload });
        case config_action_1.CONFIG_ACTIONS.FETCH_STATES:
            return __assign(__assign({}, state), { stateList: action.payload });
        case config_action_1.CONFIG_ACTIONS.FETCH_PRESENTCITIES:
            return __assign(__assign({}, state), { presentCityList: action.payload });
        case config_action_1.CONFIG_ACTIONS.FETCH_PERMANENTCITIES:
            return __assign(__assign({}, state), { permenentCityList: action.payload });
        case config_action_1.CONFIG_ACTIONS.FETCH_PERMANENTCITIES:
            return __assign(__assign({}, state), { permenentCityList: action.payload });
        case config_action_1.CONFIG_ACTIONS.LAB_CODE:
            return __assign(__assign({}, state), { labList: action.payload });
        case config_action_1.CONFIG_ACTIONS.VEHICLE_TYPES:
            return __assign(__assign({}, state), { vehicleTypeList: action.payload });
        case config_action_1.CONFIG_ACTIONS.GENDER_TYPES:
            return __assign(__assign({}, state), { genderTypeList: action.payload });
        case config_action_1.CONFIG_ACTIONS.ZONE_TYPES:
            return __assign(__assign({}, state), { zoneTypeList: action.payload });
        case config_action_1.CONFIG_ACTIONS.EXIT_FE_LIST_TYPES:
            return __assign(__assign({}, state), { fetch_exit_fe_id: action.payload });
        case config_action_1.CONFIG_ACTIONS.FETCH_VENDOR_CONTRACT:
            return __assign(__assign({}, state), { vendor_contract_types: action.payload });
        case config_action_1.CONFIG_ACTIONS.ADDRESS_PROOF_TYPE_LIST:
            return __assign(__assign({}, state), { addressProofType: action.payload });
        case config_action_1.CONFIG_ACTIONS.EXIT_FE_LIST_TYPES:
            return __assign(__assign({}, state), { fetch_exit_fe_id: action.payload });
        case 'LOGOUT':
            return __assign({}, initialState);
        case config_action_1.CONFIG_ACTIONS.USER_TYPES:
            return __assign(__assign({}, state), { userTypeList: action.payload });
        case config_action_1.CONFIG_ACTIONS.CC_LIST:
            return __assign(__assign({}, state), { ccList: action.payload });
        case config_action_1.CONFIG_ACTIONS.FE_LIST:
            return __assign(__assign({}, state), { feList: action.payload });
        case config_action_1.CONFIG_ACTIONS.AUDIT_CITY_LIST:
            return __assign(__assign({}, state), { auditCityList: action.payload });
        case config_action_1.CONFIG_ACTIONS.HUBID_LIST:
            return __assign(__assign({}, state), { HudIdList: action.payload });
        case config_action_1.CONFIG_ACTIONS.AUDIT_STATUS_LIST:
            return __assign(__assign({}, state), { auditStatusList: action.payload });
        case config_action_1.CONFIG_ACTIONS.FETCH_SAMPLE_STATUS:
            return __assign(__assign({}, state), { sample_status: action.payload });
        case config_action_1.CONFIG_ACTIONS.FETCH_ROUTE_ASSIGNMENT_TYPE:
            return __assign(__assign({}, state), { routing_assignment_type: action.payload });
        case config_action_1.CONFIG_ACTIONS.MENU_LIST:
            return __assign(__assign({}, state), { features: action.payload });
        case config_action_1.CONFIG_ACTIONS.FEATURES_LIST:
            return __assign(__assign({}, state), { featureslist: action.payload });
        case 'LOGOUT':
            return __assign({}, initialState);
        case config_action_1.CONFIG_ACTIONS.REPORT_STATUS_TYPES:
            return __assign(__assign({}, state), { reportStatusList: action.payload });
        case config_action_1.CONFIG_ACTIONS.REPORT_TYPES:
            return __assign(__assign({}, state), { reportTypeList: action.payload });
        case config_action_1.CONFIG_ACTIONS.CASH_STATUS:
            return __assign(__assign({}, state), { cashStatus: action.payload });
        case config_action_1.CONFIG_ACTIONS.CENTRE_TYPES:
            return __assign(__assign({}, state), { center_type: action.payload });
        case config_action_1.CONFIG_ACTIONS.ROUTE_PLANNER_CENTER_TYPES:
            return __assign(__assign({}, state), { route_plan_center_types: action.payload });
        case config_action_1.CONFIG_ACTIONS.ROUTE_PLANNER_DATA_TYPES:
            return __assign(__assign({}, state), { route_plan_data_types: action.payload });
        case config_action_1.CONFIG_ACTIONS.CASH_STATUS:
            return __assign(__assign({}, state), { cashStatus: action.payload });
        case config_action_1.CONFIG_ACTIONS.CENTRE_TYPES:
            return __assign(__assign({}, state), { center_type: action.payload });
        case config_action_1.CONFIG_ACTIONS.VISIT_STATUS:
            return __assign(__assign({}, state), { visitStatusList: action.payload });
        case config_action_1.CONFIG_ACTIONS.VISIT_TYPES:
            return __assign(__assign({}, state), { visitTypesList: action.payload });
        case config_action_1.CONFIG_ACTIONS.ROUTE_PLANNER_STATUS_TYPES:
            return __assign(__assign({}, state), { route_planner_status_types: action.payload });
        case config_action_1.CONFIG_ACTIONS.HUB_TYPES:
            return __assign(__assign({}, state), { hub_type: action.payload });
        case config_action_1.CONFIG_ACTIONS.CURRENT_MENU_NAME:
            return __assign(__assign({}, state), { currentMenu: action.payload });
        case config_action_1.CONFIG_ACTIONS.MASTER_BOX_STATUS:
            return __assign(__assign({}, state), { masterbox_status: action.payload });
        case config_action_1.CONFIG_ACTIONS.ETRF_STATUS:
            return __assign(__assign({}, state), { etrf_status: action.payload });
        case config_action_1.CONFIG_ACTIONS.SSO_USER_DETAILS:
            return __assign(__assign({}, state), { sso_user_details: action.payload });
        case config_action_1.CONFIG_ACTIONS.SHIPMENT_STATUS:
            return __assign(__assign({}, state), { shipment_status: action.payload });
        case config_action_1.CONFIG_ACTIONS.NOTIFICATION_CATEGORY: return __assign(__assign({}, state), { notification_category: action.payload });
        case config_action_1.CONFIG_ACTIONS.NOTIFICATION_OCCURRENCE: return __assign(__assign({}, state), { notification_occurrence: action.payload });
        case config_action_1.CONFIG_ACTIONS.ROUTING_ASSIGNMENTS: return __assign(__assign({}, state), { routing_assignments: action.payload });
        case config_action_1.CONFIG_ACTIONS.FETCH_FE_WORKING_CITIES:
            return __assign(__assign({}, state), { feWorkingCitiesList: action.payload });
        case config_action_1.CONFIG_ACTIONS.AUDIT_TYPES: return __assign(__assign({}, state), { audit_types: action.payload });
        default:
            return state;
    }
};
exports.appConfigReducer = appConfigReducer;
