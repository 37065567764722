"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var maps_api_for_javascript_1 = require("@here/maps-api-for-javascript");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
require("./CC_Roster.scss");
var GetBaseLocationFromLatLong = function (_a) {
    var setEditLocation = _a.setEditLocation, lat = _a.lat, lng = _a.lng, setFieldValue = _a.setFieldValue;
    console.log(lat, lng, 'ioujhgbv');
    var mapRef = React.useRef(null);
    var mapCoordinates = {
        center: { lat: 23.6913, lng: 85.2722 },
        zoom: 5,
        pixelRatio: window.devicePixelRatio || 1,
    };
    var geBaseLocation = function (platform) {
        var onSuccess = function (result) {
            setFieldValue('base_location_address', result.items[0].address.label);
            setEditLocation(result.items[0].address.label);
        };
        var onError = function (error) {
            react_hot_toast_1.default.error('not able to find address of selected location');
        };
        var reverseGeocode = function (platform, lat, lng) {
            var geocoder = platform.getSearchService(), reverseGeocodingParameters = {
                at: "".concat(lat, ",").concat(lng),
                limit: '1',
            };
            geocoder.reverseGeocode(reverseGeocodingParameters, onSuccess, onError);
        };
        reverseGeocode(platform, lat, lng);
    };
    React.useLayoutEffect(function () {
        if (!mapRef.current)
            return;
        var platform = new maps_api_for_javascript_1.default.service.Platform({
            apikey: process.env.HERE_MAPS_API_KEY,
        });
        var defaultLayers = platform.createDefaultLayers({ pois: true });
        var map = new maps_api_for_javascript_1.default.Map(mapRef.current, defaultLayers.vector.normal.map.setMin(2), mapCoordinates);
        map.addLayer(defaultLayers.raster.terrain.map);
        var behavior = new maps_api_for_javascript_1.default.mapevents.Behavior(new maps_api_for_javascript_1.default.mapevents.MapEvents(map));
        var ui = maps_api_for_javascript_1.default.ui.UI.createDefault(map, defaultLayers);
        ui.getElement().style.zIndex = 999;
        console.log(ui, behavior);
        if (lat && lng) {
            geBaseLocation(platform);
        }
        console.log(lat, lng, '897iyughb');
        return function () {
            map.dispose();
        };
    }, [mapRef, lat, lng]);
    return (React.createElement("div", null,
        React.createElement("div", { ref: mapRef, className: "hide-map" })));
};
exports.default = GetBaseLocationFromLatLong;
