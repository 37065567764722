"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.schemas = exports.REJECT_ROUTE_SCHEMA = exports.ROUTE_PLANNER_SCHEAMA = exports.FE_InventoryDetailsSchema = void 0;
var Yup = require("yup");
require("yup-phone");
var dateUtils_1 = require("../utils/dateUtils");
var NACheck = function (value) {
    return 'NA'.toUpperCase() === value.toUpperCase();
};
var SignupSchema = Yup.object().shape({
    username: Yup.string()
        .trim('Starting and trailing spaces are not allowed')
        .strict()
        .required('Username is required')
        .min(2, 'Minimum 2 characters required')
        .max(50, 'Maximum 50 characters allowed'),
    password: Yup.string()
        .trim('Starting and trailing spaces are not allowed')
        .strict()
        .required('Password is required')
        .min(2, 'Minimum 2 characters required')
        .max(50, 'Maximum 50 characters allowed'),
    captchaCode: Yup.string()
        .trim('Starting and trailing spaces are not allowed')
        .strict()
        .required('Captcha is required')
        .matches(/^[A-Za-z0-9]+$/, 'Only alpha numerics are allowed for this field '),
});
var SignupSchemaForOTP = Yup.object().shape({
    user_name: Yup.string()
        .min(2, 'Minimum 2 characters required')
        .max(50, 'Maximum 50 characters allowed')
        .required('Required'),
});
var SignupSchemaForLoginByPassword1 = Yup.object().shape({
    user_name: Yup.string().test('empty-check', 'Password must be at least 8 characters', function (password) { return password.length === 0; }),
    password: Yup.string()
        .min(2, 'Minimum 2 characters required')
        .max(50, 'Maximum 50 characters allowed')
        .required('Required'),
});
var FE_PersonalDetails_Schema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    dob: Yup.string(),
    mobileNumber: Yup.string().required('Required'),
    email: Yup.string(),
    emergencyContactNo: Yup.string(),
    uploadPhoto: Yup.string(),
    referenceContactName1: Yup.string().required('Required'),
    referenceMobileNumber1: Yup.string().required('Required'),
    referenceContactName2: Yup.string().required('Required'),
    referenceMobileNumber2: Yup.string().required('Required'),
});
var FE_RegistrationSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('Firstname is required')
        .min(1, 'Minimum 1 characters  required')
        .max(50, 'Maximum 50 characters allowed')
        .matches(/^[A-Za-z_\s]+$/, 'Only alphabets  are allowed for this field '),
    lastName: Yup.string()
        .required('Lastname is required')
        .min(1, 'Minimum 1 character required')
        .max(50, 'Maximum 50 characters allowed')
        .matches(/^[A-Za-z_\s]+$/, 'Only alphabets  are allowed for this field '),
    dob: Yup.string()
        .nullable(true)
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Date of birth is required') : field.optional();
    })
        .test('dob', 'Invalid Date', function (value) {
        return value === 'Invalid Date' ? false : true;
    })
        .test('dob', 'Invalid Date', function (value) {
        return value && value.includes(' GMT+0553') ? false : true;
    })
        .test('dob', 'Age should be greater than or equal to 18', function (value) {
        if (!value) {
            console.log(value, 'if');
            return true;
        }
        else {
            console.log(value, 'else');
            return (0, dateUtils_1.differenceBetweenDatesMinValidation)(new Date(value), new Date(), 'years', 17);
        }
    })
        .test('dob', 'Age should not be greater than 60 years', function (value) {
        if (!value) {
            console.log(value, 'if');
            return true;
        }
        else {
            console.log(value, 'else');
            return (0, dateUtils_1.differenceBetweenDatesMaxValidation)(new Date(value), new Date(), 'years', 60);
        }
    }),
    mobileNumber: Yup.string()
        .required('Mobile Number is required')
        .matches(/([1-9]{1}[0-9]{3})/, 'Enter Valid Mobile Number ')
        .max(10, 'Mobile Number should contain 10 digits')
        .min(10, 'Mobile Number should contain 10 digits'),
    email: Yup.string()
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Email is required') : field.optional();
    })
        .email('Invalid Email')
        .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/, 'Invalid Email'),
    emergencyContactNo: Yup.string()
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE
            ? field.required('Emergency Contact Number is required')
            : field.optional();
    })
        .test({
        name: 'comparision',
        exclusive: false,
        params: {},
        message: 'Emergency contact number should not  match with mobile number.',
        test: function (value) {
            return value ? value != this.parent.mobileNumber : true;
        },
    })
        .matches(/([1-9]{1}[0-9]{3})/, 'Enter Valid Mobile Number ')
        .max(10, 'Mobile Number should contain 10 digits')
        .min(10, 'Mobile Number should contain 10 digits'),
    referenceContactName1: Yup.string()
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE
            ? field.required('Reference Contact Name1 is required')
            : field.optional();
    })
        .min(2, 'Minimum 2 characters required')
        .max(50, 'Maximum 50 characters allowed')
        .matches(/^[A-Za-z_\s]+$/, 'Only alphabets  are allowed for this field '),
    referenceMobileNumber1: Yup.string()
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE
            ? field.required('Reference Mobile Number1 is required')
            : field.optional();
    })
        .test({
        name: 'comparision',
        exclusive: false,
        params: {},
        message: 'Reference contact number should not match with your added contact number above.',
        test: function (value) {
            return value ? value != this.parent.mobileNumber : true;
        },
    })
        .test({
        name: 'comparision',
        exclusive: false,
        params: {},
        message: 'Reference contact number should not match with your added contact number above.',
        test: function (value) {
            return value ? value != this.parent.emergencyContactNo : true;
        },
    })
        .matches(/([1-9]{1}[0-9]{3})/, 'Enter Valid Mobile Number ')
        .max(10, 'Mobile Number should contain 10 digits')
        .min(10, 'Mobile Number should contain 10 digits'),
    referenceContactName2: Yup.string()
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE
            ? field.required('Reference Contact Name2 is required')
            : field.optional();
    })
        .min(2, 'Minimum 2 characters required')
        .max(50, 'Maximum 50 characters allowed')
        .matches(/^[A-Za-z_\s]+$/, 'Only alphabets  are allowed for this field '),
    referenceMobileNumber2: Yup.string()
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE
            ? field.required('Reference Mobile Number2 is required')
            : field.optional();
    })
        .test({
        name: 'comparision',
        exclusive: false,
        params: {},
        message: 'Reference contact number should not match with your added contact number above.',
        test: function (value) {
            return value ? value != this.parent.mobileNumber : true;
        },
    })
        .test({
        name: 'comparision',
        exclusive: false,
        params: {},
        message: 'Reference contact number should not match with your added contact number above.',
        test: function (value) {
            console.log('val...rtyrtytyuyu.', value, this.parent.referenceMobileNumber1);
            return value ? value != this.parent.referenceMobileNumber1 : true;
        },
    })
        .test({
        name: 'comparision',
        exclusive: false,
        params: {},
        message: 'Reference Mobile Number2 should not be same as Emergency Contact Number in contact details',
        test: function (value) {
            return value ? value != this.parent.emergencyContactNo : true;
        },
    })
        .matches(/([1-9]{1}[0-9]{3})/, 'Enter Valid Mobile Number ')
        .max(10, 'Mobile Number should contain 10 digits')
        .min(10, 'Mobile Number should contain 10 digits'),
    uploadPhoto: Yup.mixed().when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Profile Photo is required') : field.optional();
    }),
    gender: Yup.object()
        .nullable(true)
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Gender is required') : field.optional();
    }),
});
var FE_ContactDetailsSchema = Yup.object().shape({
    addresslane1: Yup.string()
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Address Lane1 is required') : field.optional();
    })
        .min(2, 'Minimum 2 characters required')
        .max(150, 'Maximum 150 characters allowed'),
    addresslane2: Yup.string()
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Address Lane2 is required') : field.optional();
    })
        .min(2, 'Minimum 2 characters required')
        .max(150, 'Maximum 150 characters allowed'),
    landmark: Yup.string()
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Landmark is required') : field.optional();
    })
        .min(2, 'Minimum 2 characters required')
        .max(150, 'Maximum 150 characters allowed'),
    state: Yup.object()
        .nullable(true)
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('State is required') : field.optional();
    }),
    city: Yup.object()
        .nullable(true)
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('City is required') : field.optional();
    }),
    country: Yup.object()
        .nullable(true)
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Country is required') : field.optional();
    }),
    pincode: Yup.string()
        .nullable()
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Pincode is required') : field.optional();
    })
        .matches(/([1-9]{1}[0-9]{5}|[1-9]{1}[0-9]{3}\\s[0-9]{3})/, 'Enter Valid pincode')
        .max(6, 'Pincode should contain 6 digits'),
    proofOfAddress: Yup.mixed().when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE
            ? field.required('Proof of current address document is required')
            : field.optional();
    }),
    addressProofType: Yup.object()
        .nullable(true)
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE
            ? field.required('Address proof type is required')
            : field.optional();
    }),
    permenentAddresslane1: Yup.string()
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Address Lane1 is required') : field.optional();
    })
        .min(2, 'Minimum 2 characters required')
        .max(150, 'Maximum 150 characters allowed'),
    permenentAddresslane2: Yup.string()
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Address Lane2 is required') : field.optional();
    })
        .min(2, 'Minimum 2 characters required')
        .max(150, 'Maximum 150 characters allowed'),
    permenentLandmark: Yup.string()
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Landmark is required') : field.optional();
    })
        .min(2, 'Minimum 2 characters required')
        .max(150, 'Maximum 150 characters allowed'),
    permenentState: Yup.object()
        .nullable(true)
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('State is required') : field.optional();
    }),
    permenentCity: Yup.object()
        .nullable(true)
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('City is required') : field.optional();
    }),
    permenentCountry: Yup.object()
        .nullable(true)
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Country is required') : field.optional();
    }),
    permenentPincode: Yup.string()
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Pincode is required') : field.optional();
    })
        .matches(/([1-9]{1}[0-9]{5}|[1-9]{1}[0-9]{3}\\s[0-9]{3})/, 'Enter Valid pincode')
        .max(6, 'Pincode should contain 6 digits'),
    permenentProofOfAddress: Yup.mixed().when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE
            ? field.required('Proof of permanent address document is required')
            : field.optional();
    }),
    permanentProofOfAddressType: Yup.object()
        .nullable(true)
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE
            ? field.required('permanent address proof type is required')
            : field.optional();
    }),
});
var FE_DrivingLicenceDetails_Schema = Yup.object().shape({
    drivingLicence: Yup.string()
        .when(['isActiveFE', 'vehicleType'], {
        is: function (isActiveFE, vehicleType) {
            return isActiveFE && vehicleType && vehicleType.id != 4;
        },
        then: Yup.string().required('Driving Licence is required'),
    })
        .matches(/^[A-Za-z0-9-]+$/, 'Invalid Driving liecence')
        .min(15, 'Min 15 characters required')
        .max(50, 'Maximum 50 characters allowed'),
    insuranceNumber: Yup.string()
        .when(['isActiveFE', 'vehicleType'], {
        is: function (isActiveFE, vehicleType) {
            return isActiveFE && vehicleType && vehicleType.id != 4;
        },
        then: Yup.string().required('Insurance Number is required'),
    })
        .matches(/^[A-Za-z0-9-/#]+$/, 'Invalid Insurance Number')
        .min(10, 'Min 10 characters required')
        .max(50, 'Maximum 50 characters allowed'),
    drivingLicenceExpirydate: Yup.string()
        .nullable(true)
        .when(['isActiveFE', 'vehicleType'], {
        is: function (isActiveFE, vehicleType) {
            return isActiveFE && vehicleType && vehicleType.id != 4;
        },
        then: Yup.string()
            .nullable(true)
            .required('Driving Licence Expiry Date is required'),
    })
        .test('drivingLicenceExpirydate', 'Invalid Date', function (value) {
        return value === 'Invalid Date' ? false : true;
    })
        .test('drivingLicenceExpirydate', 'Invalid Date', function (value) {
        return value && value.includes(' GMT+0553') ? false : true;
    })
        .test('drivingLicenceExpirydate', 'Expiry date should be greater than current date', function (value) {
        if (!value) {
            return true;
        }
        else {
            return (0, dateUtils_1.differenceBetweenDatesMinValidation)(new Date(), new Date(value), 'days', 0);
        }
    }),
    insuranceExpirydate: Yup.string()
        .nullable(true)
        .when(['isActiveFE', 'vehicleType'], {
        is: function (isActiveFE, vehicleType) {
            return isActiveFE && vehicleType && vehicleType.id != 4;
        },
        then: Yup.string()
            .nullable(true)
            .required('Insurance Expiry Date is required'),
    })
        .test('insuranceExpirydate', 'Invalid Date', function (value) {
        return value === 'Invalid Date' ? false : true;
    })
        .test('insuranceExpirydate', 'Invalid Date', function (value) {
        return value && value.includes(' GMT+0553') ? false : true;
    })
        .test('insuranceExpirydate', 'Expiry date should be greater than current date', function (value) {
        if (!value) {
            return true;
        }
        else {
            return (0, dateUtils_1.differenceBetweenDatesMinValidation)(new Date(), new Date(value), 'days', 0);
        }
    }),
    uploadInsuranceDoc: Yup.mixed().when(['isActiveFE', 'vehicleType'], {
        is: function (isActiveFE, vehicleType) {
            return isActiveFE && vehicleType && vehicleType.id != 4;
        },
        then: Yup.string().required('Upload Insurance document is required'),
    }),
    proofOfDrivingLicence: Yup.mixed().when(['isActiveFE', 'vehicleType'], {
        is: function (isActiveFE, vehicleType) {
            return isActiveFE && vehicleType && vehicleType.id != 4;
        },
        then: Yup.string().required('Proof Of Driving Licence document is required'),
    }),
});
var FE_EmployementDetailsSchema = Yup.object().shape({
    employee_vendor_id: Yup.string()
        .matches(/^[A-Za-z0-9-]+$/, 'Invalid Employee Vendor ID')
        .min(2, 'Min 2 characters required')
        .max(25, 'Maximum 25 characters allowed'),
    dateOfJoining: Yup.string()
        .nullable(true)
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE
            ? field.required('Date of joining  is required')
            : field.optional();
    })
        .test('dateOfJoining', 'Invalid Date', function (value) {
        return value === 'Invalid Date' ? false : true;
    })
        .test('dateOfJoining', 'Invalid Date', function (value) {
        return value && value.includes(' GMT+0553') ? false : true;
    }),
    fe_Type: Yup.object()
        .nullable(true)
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('FE Type is required') : field.optional();
    }),
    tenure: Yup.object()
        .nullable(true)
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Tenure is required') : field.optional();
    }),
    labCode: Yup.object()
        .nullable(true)
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Lab code is required') : field.optional();
    }),
    zone: Yup.object()
        .nullable(true)
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Zone is required') : field.optional();
    }),
    assign_route: Yup.object()
        .nullable(true)
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Assign Route is required') : field.optional();
    }),
    base_location_address: Yup.string()
        .nullable(true)
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE
            ? field.required('Base Location Address is required')
            : field.optional();
    }),
    baseLocation: Yup.string().nullable(true),
    fe_working_state: Yup.object()
        .nullable(true)
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('FE Working State is required') : field.optional();
    }),
    fe_working_city: Yup.object()
        .nullable(true)
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('FE Working City is required') : field.optional();
    }),
});
var FE_BankDetails_Schema = Yup.object().shape({
    AccountNumber: Yup.string()
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Account number is required') : field.optional();
    })
        .matches(/([1-9]{1}[0-9]{3})/, 'Invalid Account Number ')
        .nullable(true)
        .transform(function (v, o) { return (o === '' ? null : v); })
        .min(9, 'Minimum 9 characters required')
        .max(18, 'Maximum 18 characters allowed'),
    IFSC_Code: Yup.string()
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('IFSC code is required') : field.optional();
    })
        .matches(/^[A-Za-z]{4}[A-Za-z0-9]{7}$/, 'Invalid IFSC code'),
    Bank_Name: Yup.string()
        .min(2, 'Min 2 characters required')
        .max(50, 'Max 50 characters allowed')
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Bank Name  is required') : field.optional();
    }),
    Bank_Branch: Yup.string()
        .min(2, 'Min 2 characters required')
        .max(150, 'Max 150 characters allowed')
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Bank Branch  is required') : field.optional();
    }),
    UPI_ID: Yup.string()
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('UPI ID is required') : field.optional();
    })
        .min(2, 'Min 2 characters required')
        .max(50, 'Max 50 characters allowed')
        .test('UPI_ID', 'Invalid UPI', function (value, field) {
        if (!value) {
            console.log(value, 'if');
            return true;
        }
        else {
            console.log(value, 'else');
            return value.length > 2
                ? /([^\.][\.a-zA-Z0-9_\-])@[a-zA-Z]+$/.test(value)
                : value.length === 2
                    ? NACheck(value)
                    : true;
        }
    }),
    proofOfBankAccountDetails: Yup.mixed().when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE
            ? field.required('Proof of bank account details document is required')
            : field.optional();
    }),
});
var FE_Apply_Leave_Schema = Yup.object().shape({
    searchByFe: Yup.object().nullable(true).required('Please select the FE'),
    fromDate: Yup.date().nullable(true).required('From date cannot be empty'),
    toDate: Yup.date()
        .nullable(true)
        .required('To date cannot be empty')
        .when('fromDate', function (fromDate, schema) {
        return fromDate && schema.min(fromDate, 'To date cannot be greater than from date');
    }),
    comments: Yup.string()
        .trim()
        .min(2, 'Minimum 2 characters required')
        .max(1024, 'Maximum 1024 characters allowed')
        .required('Please provide comments'),
    reason: Yup.object().nullable(true).required('Please select a reason'),
});
exports.FE_InventoryDetailsSchema = Yup.object().shape({
    selectedInventoryList: Yup.array().min(1, 'At least one "Inventory" should be selected'),
});
var FE_ExitProcessSchema = Yup.object().shape({
    reason: Yup.object().nullable(true).required('Reason is required'),
    comments: Yup.string()
        .trim()
        .min(2, 'Minimum 2 characters required')
        .max(1024, 'Maximum 1024 characters allowed')
        .required('Please provide comments'),
    fromDate: Yup.string()
        .nullable(true)
        .required('This field is required')
        .test('fromDate', 'Invalid Date', function (value) {
        return value === 'Invalid Date' ? false : true;
    })
        .test('fromDate', 'Invalid Date', function (value) {
        return value && value.includes(' GMT+0553') ? false : true;
    })
        .test('fromDate', 'Date should be greater than or equal to Inactive date', function (value) {
        if (!value) {
            return true;
        }
        else {
        }
        return (0, dateUtils_1.differenceBetweenDatesMinValidation)(new Date(this.parent.inactivedate), new Date(value), 'days', -1);
    }),
});
var FE_ExitProcessSchemaAmount = Yup.object().shape({
    reason: Yup.object().nullable(true).required('Reason is required'),
    comments: Yup.string()
        .trim()
        .min(2, 'Minimum 2 characters required')
        .max(1024, 'Maximum 1024 characters allowed')
        .required('Please provide comments'),
    inventory_amount: Yup.number()
        .required('Please give certain  amount')
        .lessThan(10000),
    fromDate: Yup.string()
        .nullable(true)
        .required('This field is required')
        .test('fromDate', 'Invalid Date', function (value) {
        return value === 'Invalid Date' ? false : true;
    })
        .test('fromDate', 'Invalid Date', function (value) {
        return value && value.includes(' GMT+0553') ? false : true;
    })
        .test('fromDate', 'Date should be greater than or equal to Inactive date', function (value) {
        if (!value) {
            return true;
        }
        else {
        }
        return (0, dateUtils_1.differenceBetweenDatesMinValidation)(new Date(this.parent.inactivedate), new Date(value), 'days', -1);
    }),
});
var InactiveFeSchema = Yup.object().shape({
    reason: Yup.object().nullable(true).required('Reason is required'),
    comments: Yup.string()
        .trim()
        .min(2, 'Minimum 2 characters required')
        .max(1024, 'Maximum 1024 characters allowed')
        .required('Please provide comments'),
    fromDate: Yup.date().nullable(true).required('This field is required'),
});
var FE_ApproveLeavesSchema = Yup.object().shape({
    comments: Yup.string()
        .trim()
        .min(2, 'Minimum 2 characters required!')
        .max(150, 'Maximum 105 characters allowed!')
        .required('Please provide comments'),
});
var FE_RejectSchema = Yup.object().shape({
    rejectionType: Yup.object().required(),
    otherComments: Yup.string()
        .required()
        .matches(/^[A-Za-z_\s]+$/, 'Only alphabets  are allowed for this field ')
        .min(2, 'Minimum 2 characters required!')
        .max(150, 'Maximum 150 characters allowed!'),
});
var USER_ProfileDetails_Schema = Yup.object().shape({
    firstName: Yup.string()
        .required('First Name is required')
        .min(2, 'Minimum 2 characters are required!')
        .max(50, 'Maximum 50 characters allowed!')
        .matches(/^[A-Za-z_\s]+$/, 'Only alphabets  are allowed for this field '),
    lastName: Yup.string()
        .required('Last Name is required')
        .min(2, 'Minimum 2 characters required!')
        .max(50, 'Maximum 50 characters allowed!')
        .matches(/^[A-Za-z_\s]+$/, 'Only alphabets  are allowed for this field '),
    dob: Yup.string()
        .nullable(true)
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Date of birth is required') : field.optional();
    })
        .test('dob', 'Invalid Date', function (value) {
        return value === 'Invalid Date' ? false : true;
    })
        .test('dob', 'Invalid Date', function (value) {
        return value && value.includes(' GMT+0553') ? false : true;
    })
        .test('dob', 'Age should be greater than or equal to 18', function (value) {
        if (!value) {
            console.log(value, 'if');
            return true;
        }
        else {
            console.log(value, 'else');
            return (0, dateUtils_1.differenceBetweenDatesMinValidation)(new Date(value), new Date(), 'years', 17);
        }
    })
        .test('dob', 'Age should not be greater than 60 years', function (value) {
        if (!value) {
            console.log(value, 'if');
            return true;
        }
        else {
            console.log(value, 'else');
            return (0, dateUtils_1.differenceBetweenDatesMaxValidation)(new Date(value), new Date(), 'years', 60);
        }
    }),
    mobileNumber: Yup.string()
        .required('Mobile Number is required')
        .matches(/([1-9]{1}[0-9]{3})/, 'Enter Valid Mobile Number ')
        .max(10, 'emergencyContactNo should not contain 10 digits')
        .min(9, 'emergencyContactNo should contain 10 digits'),
    alternateContactNo: Yup.string()
        .test({
        name: 'comparision',
        exclusive: false,
        params: {},
        message: 'Alternate Number should not be same as Mobile Number',
        test: function (value) {
            return value ? value != this.parent.mobileNumber : true;
        },
    })
        .matches(/^[0-9]+$/, 'Only Numbers Allowed')
        .max(10, 'Alternate Number should not contain 10 digits')
        .min(10, 'Alternate Number should contain 10 digits'),
    email: Yup.string()
        .email('Not a proper email')
        .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/, 'Invalid Email')
        .required('Email is required'),
    alternateEmail: Yup.string()
        .test({
        name: 'comparision',
        exclusive: false,
        params: {},
        message: 'Alternate Email should not be same as Email',
        test: function (value) {
            return value ? value != this.parent.email : true;
        },
    })
        .email('Not a proper email'),
    uploadPhoto: Yup.string(),
    username: Yup.string()
        .required('UserName is required')
        .min(2, 'Minimum 2 characters required')
        .max(150, 'Maximum 150 characters allowed'),
    employeecode: Yup.string()
        .required('Employee Code is required')
        .min(2, 'Minimum 2 characters required')
        .max(150, 'Maximum 150 characters allowed'),
    user_Type: Yup.object().nullable(true).required('Select user_type'),
});
var USER_ContactDetailsSchema = Yup.object().shape({
    addresslane1: Yup.string()
        .required('address lane is required')
        .min(2, 'Minimum 2 characters required')
        .max(150, 'Maximum 150 characters allowed'),
    addresslane2: Yup.string()
        .required('address lane is required')
        .min(2, 'Minimum 2 characters required')
        .max(150, 'Maximum 150 characters allowed'),
    landmark: Yup.string()
        .required('landmark is required')
        .min(2, 'Minimum 2 characters required')
        .max(150, 'Maximum 150 characters allowed'),
    state: Yup.object().nullable(true).required('state is required'),
    city: Yup.object().nullable(true).required('city is required'),
    country: Yup.object().nullable(true).required('country is required'),
    pincode: Yup.string()
        .required('pincode is required')
        .matches(/([1-9]{1}[0-9]{3}|[1-9]{1}[0-9]{3}\\s[0-9]{3})/, 'Enter Valid pincode')
        .max(6, 'Pincode should contain 6 digits'),
    permenentAddresslane1: Yup.string()
        .required('permenent addresslane1 is required')
        .min(2, 'Minimum 2 characters required')
        .max(150, 'Maximum 150 characters allowed'),
    permenentAddresslane2: Yup.string()
        .required('permenant address lane 2 is required')
        .min(2, 'Minimum 2 characters required')
        .max(150, 'Maximum 150 characters allowed'),
    permenentLandmark: Yup.string()
        .required('permenent landmark is required')
        .min(2, 'Minimum 2 characters required')
        .max(150, 'Maximum 150 characters allowed'),
    permenentState: Yup.object()
        .nullable(true)
        .required('permenent state is required'),
    permenentCity: Yup.object()
        .nullable(true)
        .required('permenentcity is required'),
    permenentCountry: Yup.object()
        .nullable(true)
        .required('permenent country is required'),
    permenentPincode: Yup.string()
        .required('permenent pincode is required')
        .matches(/([1-9]{1}[0-9]{5}|[1-9]{1}[0-9]{3}\\s[0-9]{3})/, 'Enter Valid pincode')
        .max(6, 'Pincode should contain 6 digits'),
});
var CreateRoleSchema = Yup.object().shape({
    name: Yup.string()
        .strict()
        .trim('Starting and trailing spaces are not allowed')
        .min(5, 'Minimum 5 character(s) required!')
        .max(25, 'Maximum 25 characters allowed!')
        .required('Please provide role name'),
    description: Yup.string()
        .min(20, 'Minimum 20 characters required!')
        .max(200, 'Maximum 200 characters allowed!')
        .required('Please provide description'),
});
var FE_SlotBooking_Schema = Yup.object().shape({
    labcode: Yup.object().nullable(true).required('Please Select Lab Code'),
    selectedFes: Yup.object().nullable(true).required('Please Select Fe'),
});
var CC_RoasterManagement_Schema = Yup.object().shape({
    labcode: Yup.object().nullable(true).required('Please Select Lab Code'),
    warehouse_code: Yup.object().nullable(true).required('Please Ware House Code'),
    base_location_address: Yup.string().when('activate', function (val) {
        if (val === 'NON_ROUTE') {
            return Yup.string().notRequired();
        }
        else {
            return Yup.string()
                .required('Please select Base Location Address')
                .nullable(true);
        }
    }),
});
var CC_RoasterManagementWarehuse_Schema = Yup.object().shape({
    warehouse_codes: Yup.string()
        .required('Warehouse Code is required')
        .matches(/^[A-Za-z0-9_\s]+$/, 'Only alpha numerics are allowed for this field ')
        .min(3, 'Minimum 3 characters  required')
        .max(50, 'Maximum 50 characters allowed')
        .nullable(true),
});
var Lab_RoasterManagement_Schema = Yup.object().shape({
    labcode: Yup.object().nullable(true).required('Please Select Lab Code'),
    base_location_address: Yup.string()
        .required('Please select Base Location Address')
        .nullable(true),
    geofence_radius: Yup.number().test({
        name: 'geofence_radius',
        exclusive: false,
        params: {},
        message: 'Geofence radius should be greater than or equal to 50',
        test: function (value) {
            var isEnabled = this.parent.geofence_enabled;
            var isError = isEnabled && Number(value) < 50 ? false : true;
            return isError;
        },
    }),
});
var min = 2;
var VENDOR_PROFILE_DETAILS_SCHEMA = Yup.object().shape({
    vendor_id: Yup.mixed()
        .required('Vendor id required')
        .test({
        name: 'min',
        exclusive: true,
        params: { min: min },
        message: "Axapta code must be  ".concat(min, " characters"),
        test: function (value) {
            if (typeof value == 'object') {
                return true;
            }
            else {
                return value == null || value.length >= min;
            }
        },
    }),
    vendorName: Yup.string()
        .required('Vendor name is required')
        .min(1, 'Minimum 1 characters  required')
        .max(50, 'Maximum 50 characters allowed'),
    entityType: Yup.object().nullable(true).required('Entity Type is required'),
    contactperson: Yup.string()
        .required('Contact Person is required')
        .min(1, 'Minimum 1 characters  required')
        .max(50, 'Maximum 50 characters allowed')
        .matches(/^[A-Za-z_\s]+$/, 'Only alphabets  are allowed for this field '),
    designation: Yup.string()
        .required('Designation is required')
        .min(2, 'Minimum 1 characters  required')
        .max(50, 'Maximum 50 characters allowed')
        .matches(/^[A-Za-z_\s]+$/, 'Only alphabets  are allowed for this field '),
    mobilenumber: Yup.string()
        .required('Mobile Number is required')
        .matches(/([1-9]{1}[0-9]{3})/, 'Enter Valid Mobile Number')
        .max(10, 'A maximum of 10 digits allowed')
        .min(10, 'A minimum of 10 digits are required'),
    email: Yup.string()
        .email('Please enter a valid email')
        .required('Email is required'),
    alternateNumber: Yup.string()
        .required('Alternate Mobile Number is required')
        .test({
        name: 'comparision',
        exclusive: false,
        params: {},
        message: 'Alternate Number should not be same as Mobile Number',
        test: function (value) {
            return value ? value != this.parent.mobileNumber : true;
        },
    })
        .matches(/^[0-9]+$/, 'Only Numbers Allowed')
        .max(10, 'Alternate Number should not contain 10 digits')
        .min(10, 'Alternate Number should contain 10 digits'),
    website: Yup.string()
        .required('Website is required')
        .min(2, 'Minimum 2 character required')
        .max(250, 'Maximum 25 characters allowed'),
    uploadPhoto: Yup.mixed().required('Profile pic is required'),
});
var LEVEL_ONE_APPRVAL_SCHEMA = Yup.object().shape({
    reject_type: Yup.object().nullable(true).required('Reason is required'),
    reject_comment: Yup.string()
        .trim()
        .strict()
        .min(5, 'Minimum 5 characters required!')
        .max(200, 'Maximum 200 characters allowed!')
        .required('Please provide other comments'),
});
var USER_TERRITORY_DETAILS = Yup.object().shape({
    country: Yup.object().nullable(true).required('Country is required'),
    state: Yup.array()
        .of(Yup.object().shape({
        id: Yup.number().nullable(),
        label: Yup.string().nullable(),
        short_code: Yup.string().nullable(),
        value: Yup.number().nullable(),
    }))
        .nullable()
        .min(1, 'Atleast one  state should be selected')
        .required('State is required'),
    city: Yup.array()
        .of(Yup.object().shape({
        id: Yup.number().nullable(),
        label: Yup.string().nullable(),
        short_code: Yup.string().nullable(),
        value: Yup.number().nullable(),
    }))
        .nullable()
        .min(1, 'Atleast one  city should be selected')
        .required('City is required'),
    branch: Yup.array()
        .of(Yup.object().shape({
        id: Yup.number().nullable(),
        label: Yup.string().nullable(),
        short_code: Yup.string().nullable(),
        value: Yup.number().nullable(),
    }))
        .nullable()
        .min(1, 'Atleast one  branch should be selected'),
    pincode: Yup.string()
        .required('Pincode is required')
        .matches(/([1-9]{1}[0-9]{5}|[1-9]{1}[0-9]{3}\\s[0-9]{3})/, 'Enter Valid pincode')
        .max(6, 'Pincode should contain 6 digits'),
    address: Yup.string()
        .required('Address is required')
        .min(2, 'Minimum 2 characters required')
        .max(150, 'Maximum 150 characters allowed'),
    vehicleNumber: Yup.string()
        .min(2, 'Minimum 2 characters required')
        .max(150, 'Maximum 150 characters allowed'),
    geofence: Yup.string()
        .min(2, 'Minimum 2 characters required')
        .max(150, 'Maximum 150 characters allowed'),
    userskills: Yup.string()
        .min(2, 'Minimum 2 characters required')
        .max(150, 'Maximum 150 characters allowed'),
});
var VENDOR_CONTRACT_DETAILS = Yup.object().shape({
    contract_type: Yup.object().nullable(true),
    contract_start_date: Yup.string()
        .nullable(true)
        .test('contract_start_date', 'Invalid Date', function (value) {
        return value === 'Invalid Date' ? false : true;
    })
        .test('contract_start_date', 'Invalid Date', function (value) {
        return value && value.includes(' GMT+0553') ? false : true;
    })
        .required('Contract start Date is  required'),
    contractExpiry: Yup.string()
        .nullable(true)
        .test('contractExpiry', 'Invalid Date', function (value) {
        return value === 'Invalid Date' ? false : true;
    })
        .test('contractExpiry', 'Invalid Date', function (value) {
        return value && value.includes(' GMT+0553') ? false : true;
    })
        .test('contractExpiry', 'Contract Expiry date should be greater than current date', function (value) {
        if (!value) {
            return true;
        }
        else {
            return (0, dateUtils_1.differenceBetweenDatesMinValidation)(new Date(), new Date(value), 'days', 0);
        }
    })
        .required('Contract expiry date is  required')
        .test({
        name: 'comparision',
        exclusive: false,
        params: {},
        message: 'Contract start date should be selected first',
        test: function (value) {
            return this.parent.contract_start_date ? true : false;
        },
    })
        .test({
        name: 'comparision',
        exclusive: false,
        params: {},
        message: 'Contract expiry date should be greater than Contract start date',
        test: function (value) {
            return (0, dateUtils_1.differenceBetweenDatesMinValidation)(new Date(this.parent.contract_start_date), new Date(value), 'days', 0);
        },
    }),
    operatingState: Yup.array()
        .of(Yup.object().shape({
        id: Yup.number().nullable(),
        label: Yup.string().nullable(),
        short_code: Yup.string().nullable(),
        value: Yup.number().nullable(),
    }))
        .nullable()
        .min(1, 'Atleast one operating state should be selected')
        .required('Operating state is required'),
    operatingcity: Yup.array()
        .of(Yup.object().shape({
        id: Yup.number().nullable(),
        label: Yup.string().nullable(),
        short_code: Yup.string().nullable(),
        state_id: Yup.number().nullable(),
        state_name: Yup.string().nullable(),
        value: Yup.number().nullable(),
    }))
        .nullable()
        .min(1, 'Atleast one operating city should be selected')
        .required('Operating city is required'),
    parent_contract_id: Yup.string()
        .min(1, 'Minimum 1 number required')
        .max(20, 'Maximum 20 numbers allowed')
        .matches(/^[A-Za-z0-9-/]+$/, 'Invalid Parent Contract ID'),
    totalSourceFe: Yup.string()
        .min(1, 'Minimum 1 number required')
        .max(10, 'Maximum 10 numbers allowed')
        .matches(/^[0-9]+$/, 'Only Numbers Allowed'),
    contractAgreement: Yup.mixed().required('Contract agreement is required'),
});
var Update_Deductions_Schema = Yup.object().shape({
    deductionAmount: Yup.string()
        .trim()
        .min(1, 'Minimum 1 characters required')
        .max(500, 'Maximum 500 characters allowed')
        .required('Please enter deduction amount')
        .matches(/^[0-9_]+$/, 'Only numeric values  are allowed for this field '),
    deductionsDate: Yup.string()
        .nullable(true)
        .test('deductionsDate', 'Invalid Date', function (value) {
        return value === 'Invalid Date' ? false : true;
    })
        .test('deductionsDate', 'Invalid Date', function (value) {
        return value && value.includes(' GMT+0553') ? false : true;
    })
        .required('Deduction date cannot be empty'),
    comments: Yup.string()
        .trim()
        .min(1, 'Minimum 1 characters required')
        .max(500, 'Maximum 500 characters allowed')
        .required('Please provide comments'),
    reason: Yup.object().nullable(true).required('Please select a reason'),
});
var LEVEL_ONE_REJECT_SCHEMA = Yup.object().shape({
    reject_type: Yup.object().nullable(true).required('Reason is required'),
    reject_comment: Yup.string()
        .trim()
        .min(5, 'Minimum 5 characters required!')
        .max(200, 'Maximum 200 characters allowed!')
        .required('Please provide other comments'),
});
var AUDIT_CREATE_SCHEMA = Yup.object().shape({
    auditTitle: Yup.string()
        .trim('Starting and trailing spaces are not allowed')
        .strict()
        .required('Audit Title is required')
        .min(2, 'Minimum 5 characters required!')
        .max(50, 'Maximum 200 characters allowed!'),
    auditDescription: Yup.string()
        .trim('Starting and trailing spaces are not allowed')
        .strict()
        .required('Audit Description is required')
        .min(2, 'Minimum 5 characters required!')
        .max(250, 'Maximum 200 characters allowed!'),
    AuditPhoto: Yup.mixed(),
    totalNoOfQuestion: Yup.object()
        .nullable(true)
        .test({
        name: 'comparision',
        exclusive: false,
        params: {},
        message: 'Total Number of Questions required',
        test: function (value) {
            return value === null ? false : true;
        },
    }),
    questions: Yup.array()
        .of(Yup.object()
        .shape({
        qtitile: Yup.string().required('Question title is required'),
        qtype: Yup.object().required('Question Type is  required'),
        qoptions: Yup.array().of(Yup.object()
            .nullable(true)
            .test({
            name: 'comparision',
            exclusive: false,
            params: {},
            message: 'Option is required',
            test: function (value) {
                return value && value.option ? true : false;
            },
        })
            .test({
            name: 'unique',
            exclusive: false,
            params: {},
            message: 'Only unique values allowed.',
            test: function (value) {
                return value &&
                    this.parent.filter(function (singleOption) { return singleOption.option === value.option; }).length > 1
                    ? false
                    : true;
            },
        })),
        minrange: Yup.object()
            .nullable(true)
            .test({
            name: 'comparision',
            exclusive: false,
            params: {},
            message: 'Min  range is required',
            test: function (value) {
                return value === null ? false : true;
            },
        }),
        maxrange: Yup.object()
            .nullable(true)
            .test({
            name: 'comparision',
            exclusive: false,
            params: {},
            message: 'Max  range is required',
            test: function (value) {
                return value === null ? false : true;
            },
        })
            .test({
            name: 'comparision',
            exclusive: false,
            params: {},
            message: 'Max  range should be greater than Min range',
            test: function (value) {
                return value && this.parent.minrange
                    ? value.value <= this.parent.minrange.value
                        ? false
                        : true
                    : true;
            },
        }),
    })
        .test({
        name: 'unique',
        exclusive: false,
        params: {},
        message: 'Question title should be unique',
        test: function (value) {
            console.log('unique values testcase:', this.parent, value);
            return value &&
                this.parent &&
                this.parent.filter(function (singlequestion) {
                    return JSON.stringify(singlequestion) === JSON.stringify(value);
                }).length > 1
                ? false
                : true;
        },
    }))
        .test({
        name: 'comparision',
        exclusive: false,
        params: {},
        message: 'Please add the questions as per the total no of questions selected',
        test: function (questions) {
            return questions && this.parent.totalNoOfQuestion
                ? questions.length === this.parent.totalNoOfQuestion.value
                : true;
        },
    }),
});
var ASSIGN_USERS_TO_AUDIT_SCHEMA = Yup.object().shape({
    startDate: Yup.string()
        .nullable(true)
        .test('startDate', 'Invalid Date', function (value) {
        return value === 'Invalid Date' ? false : true;
    })
        .test('startDate', 'Invalid Date', function (value) {
        return value && value.includes(' GMT+0553') ? false : true;
    })
        .required('Start Date is  required')
        .test({
        name: 'comparision',
        exclusive: false,
        params: {},
        message: 'Start Date should be greater than Current Date',
        test: function (value) {
            return (0, dateUtils_1.differenceBetweenDatesMinValidation)(new Date(), new Date(this.parent.startDate), 'days', -1);
        },
    }),
    endDate: Yup.string()
        .nullable(true)
        .test('endDate', 'Invalid Date', function (value) {
        return value === 'Invalid Date' ? false : true;
    })
        .test('endDate', 'Invalid Date', function (value) {
        return value && value.includes(' GMT+0553') ? false : true;
    })
        .required('End Date is  required')
        .test({
        name: 'comparision',
        exclusive: false,
        params: {},
        message: 'Start Date should be selected first',
        test: function (value) {
            return this.parent.startDate ? true : false;
        },
    })
        .test({
        name: 'comparision',
        exclusive: false,
        params: {},
        message: 'End Date should be greater than Start Date',
        test: function (value) {
            return (0, dateUtils_1.differenceBetweenDatesMinValidation)(new Date(this.parent.startDate), new Date(value), 'days', -1);
        },
    }),
    country: Yup.array().test({
        message: 'Country is Required',
        test: function (arr) {
            console.log('Country arr:', arr);
            return arr && arr.length > 0;
        },
    }),
    state: Yup.array().test({
        message: 'State is Required',
        test: function (arr) {
            console.log('city arr:', arr);
            return arr && arr.length > 0;
        },
    }),
    city: Yup.array().test({
        message: 'City is Required',
        test: function (arr) {
            console.log('city arr:', arr);
            return arr && arr.length > 0;
        },
    }),
    auditees: Yup.array().min(1, 'Please Select Auditee'),
    auditors: Yup.array().min(1, 'Please Select Auditor'),
    selectedAuditee: Yup.object()
        .nullable(true)
        .test({
        name: 'comparision',
        exclusive: false,
        params: {},
        message: 'Target Auditees is required',
        test: function (value) {
            return value === null ? false : true;
        },
    }),
    selectedAuditors: Yup.object()
        .nullable(true)
        .test({
        name: 'comparision',
        exclusive: false,
        params: {},
        message: 'Target Auditors is required',
        test: function (value) {
            return value === null ? false : true;
        },
    }),
});
var VENDOR_ContactDetailsSchema = Yup.object().shape({
    addresslane1: Yup.string()
        .required('Address Lane1 is required')
        .min(2, 'Minimum 2 characters required')
        .max(150, 'Maximum 150 characters allowed'),
    addresslane2: Yup.string(),
    landmark: Yup.string()
        .required('Landmark is required')
        .min(2, 'Minimum 2 characters required')
        .max(150, 'Maximum 150 characters allowed'),
    state: Yup.object().nullable(true).required('State is required'),
    city: Yup.object().nullable(true).required('City is required'),
    country: Yup.object().nullable(true).required('Country is required'),
    pincode: Yup.string()
        .nullable()
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Pincode is required') : field.optional();
    })
        .matches(/([1-9]{1}[0-9]{5}|[1-9]{1}[0-9]{3}\\s[0-9]{3})/, 'Enter Valid pincode')
        .max(6, 'Pincode should contain 6 digits'),
    proofOfAddress: Yup.mixed(),
    permenentAddresslane1: Yup.string()
        .required('Address Lane1 is required')
        .min(2, 'Minimum 2 characters required')
        .max(150, 'Maximum 150 characters allowed'),
    permenentAddresslane2: Yup.string()
        .min(2, 'Minimum 2 characters required')
        .max(150, 'Maximum 150 characters allowed'),
    permenentLandmark: Yup.string()
        .required('Landmark is required')
        .min(2, 'Minimum 2 characters required')
        .max(150, 'Maximum 150 characters allowed'),
    permenentState: Yup.object().nullable(true).required('State is required'),
    permenentCity: Yup.object().nullable(true).required('City is required'),
    permenentCountry: Yup.object().nullable(true).required('Country is required'),
    permenentPincode: Yup.string()
        .when('isActiveFE', function (isActiveFE, field) {
        return isActiveFE ? field.required('Pincode is required') : field.optional();
    })
        .matches(/([1-9]{1}[0-9]{5}|[1-9]{1}[0-9]{3}\\s[0-9]{3})/, 'Enter Valid pincode')
        .max(6, 'Pincode should contain 6 digits'),
    permenentProofOfAddress: Yup.mixed(),
});
var PRECIOUS_SAMPLE_MASTER = Yup.object().shape({
    cpt_code: Yup.string()
        .trim()
        .min(2, 'Minimum 2 characters required')
        .max(1024, 'Maximum 1024 characters allowed')
        .required('Please provide CPT Code'),
    test_no: Yup.string()
        .trim()
        .min(2, 'Minimum 2 characters required')
        .max(1024, 'Maximum 1024 characters allowed')
        .required('Please provide Test No'),
    sample_type: Yup.string()
        .trim()
        .min(2, 'Minimum 2 characters required')
        .max(1024, 'Maximum 1024 characters allowed')
        .required('Please provide Sample Type'),
    container_mat_code: Yup.string()
        .trim()
        .min(2, 'Minimum 2 characters required')
        .max(1024, 'Maximum 1024 characters allowed')
        .required('Please provide Container Mat Code'),
    test_cat_code: Yup.string()
        .trim()
        .min(2, 'Minimum 2 characters required')
        .max(1024, 'Maximum 1024 characters allowed')
        .required('Please provide Test Cat Code'),
});
var ADD_VISITS = Yup.object().shape({
    uploadDoc: Yup.mixed().required('csv file is Required'),
});
var Apply_leave_schema = Yup.object().shape({
    searchByFe: Yup.object().required('Search by FE is required'),
    reason: Yup.object().required('Reason is required'),
    fromDate: Yup.string()
        .nullable(true)
        .required('This field is required')
        .test('fromDate', 'Invalid Date', function (value) {
        return value === 'Invalid Date' ? false : true;
    })
        .test('fromDate', 'Invalid Date', function (value) {
        return value && value.includes(' GMT+0553') ? false : true;
    })
        .test('fromDate', 'Date should be greater than or equal to current date', function (value) {
        if (!value) {
            console.log(value, 'if');
            return true;
        }
        else {
            console.log(value, 'else');
            return (0, dateUtils_1.differenceBetweenDatesMinValidation)(new Date(), new Date(value), 'days', 0);
        }
    }),
    toDate: Yup.string()
        .nullable(true)
        .required('This field is required')
        .test('toDate', 'Invalid Date', function (value) {
        return value === 'Invalid Date' ? false : true;
    })
        .test('toDate', 'Invalid Date', function (value) {
        return value && value.includes(' GMT+0553') ? false : true;
    })
        .test('toDate', 'Date should be greater than or equal to current date', function (value) {
        if (!value) {
            console.log(value, 'if');
            return true;
        }
        else {
            console.log(value, 'else');
            return (0, dateUtils_1.differenceBetweenDatesMinValidation)(new Date(), new Date(value), 'days', 0);
        }
    })
        .test({
        name: 'comparision',
        exclusive: false,
        params: {},
        message: 'ToDate should be greater than or equal to From date',
        test: function (value) {
            if (!value) {
                console.log(value, 'if');
                return true;
            }
            else {
                console.log(value, 'else');
                return (0, dateUtils_1.differenceBetweenDatesMinValidation)(new Date(this.parent.fromDate), new Date(value), 'days', -1);
            }
        },
    }),
    comments: Yup.string()
        .trim()
        .min(2, 'Minimum 2 characters required')
        .max(1024, 'Maximum 1024 characters allowed')
        .required('This field is required'),
});
var Add_cre_schema = Yup.object().shape({
    firstName: Yup.string()
        .nullable()
        .required('Firstname is required')
        .min(1, 'Minimum 1 characters  required')
        .max(50, 'Maximum 50 characters allowed')
        .matches(/^[A-Za-z_\s]+$/, 'Only alphabets  are allowed for this field '),
    lastName: Yup.string()
        .nullable()
        .required('Lastname is required')
        .min(1, 'Minimum 1 character required')
        .max(50, 'Maximum 50 characters allowed')
        .matches(/^[A-Za-z_\s]+$/, 'Only alphabets  are allowed for this field '),
    mobileNumber: Yup.string()
        .nullable()
        .required('Mobile Number is required')
        .matches(/([1-9]{1}[0-9]{3})/, 'Enter Valid Mobile Number ')
        .max(10, 'Mobile Number should contain 10 digits')
        .min(10, 'Mobile Number should contain 10 digits'),
    axcode: Yup.string()
        .nullable()
        .strict()
        .required('AX Code is required')
        .matches(/^[a-zA-Z0-9!@#$&()_`.+,/"-]*$/, 'Only alpha numerics are allowed for this field '),
    email: Yup.string()
        .nullable()
        .email('Please enter a valid email')
        .required('Email is required'),
    labcode: Yup.object().required('Lab Code is required'),
});
var Add_User_Logistics_schema = Yup.object().shape({
    firstName: Yup.string()
        .nullable()
        .required('Firstname is required')
        .min(1, 'Minimum 1 characters  required')
        .max(50, 'Maximum 50 characters allowed')
        .matches(/^[A-Za-z_\s]+$/, 'Only alphabets  are allowed for this field '),
    lastName: Yup.string()
        .nullable()
        .required('Lastname is required')
        .min(1, 'Minimum 1 character required')
        .max(50, 'Maximum 50 characters allowed')
        .matches(/^[A-Za-z_\s]+$/, 'Only alphabets  are allowed for this field '),
    phone_number: Yup.string()
        .required('Mobile Number is required')
        .matches(/([1-9]{1}[0-9]{3})/, 'Enter Valid Mobile Number ')
        .max(10, 'Mobile Number should contain 10 digits')
        .min(10, 'Mobile Number should contain 10 digits'),
    email: Yup.string()
        .nullable()
        .email('Please enter a valid email')
        .required('Email is required'),
    role_id: Yup.object().nullable(true).required('Role Id is required'),
    Admin_UserName: Yup.string()
        .nullable()
        .required('Username is required'),
    Admin_PassWord: Yup.string()
        .nullable()
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&^()_+=])[A-Za-z\d#@$!%*?&^()_+=]{8,64}$/, 'Sorry! Password should contain atleast one digit [0-9], one lowercase character [a-z], one uppercase character [A-Z], one special character, at least 8 characters and a maximum of 64 characters and should not contain white spaces.'),
    dob: Yup.string()
        .nullable(true)
        .test('dob', 'Invalid Date', function (value) {
        return value === 'Invalid Date' ? false : true;
    })
        .test('dob', 'Invalid Date', function (value) {
        return value && value.includes(' GMT+0553') ? false : true;
    })
        .test('dob', 'Age should be greater than or equal to 18', function (value) {
        if (!value) {
            console.log(value, 'if');
            return true;
        }
        else {
            console.log(value, 'else');
            return (0, dateUtils_1.differenceBetweenDatesMinValidation)(new Date(value), new Date(), 'years', 17);
        }
    })
        .test('dob', 'Age should not be greater than 60 years', function (value) {
        if (!value) {
            console.log(value, 'if');
            return true;
        }
        else {
            console.log(value, 'else');
            return (0, dateUtils_1.differenceBetweenDatesMaxValidation)(new Date(value), new Date(), 'years', 60);
        }
    }),
});
var OnDemand_Request_Schema = Yup.object().shape({
    labcode: Yup.object().nullable(true).required('Please select lab code'),
    selectedCC: Yup.object().nullable(true).required('Please select CC'),
    selectedSlots: Yup.string().nullable(true).required('Please select a slot'),
});
var USEREDITSCHEMA = Yup.object().shape({
    employeeName: Yup.string()
        .matches(/^[A-Za-z0-9_\s]+$/, 'Only alpha numerics are allowed for this field ')
        .required('Employee Name is required!'),
    userName: Yup.string()
        .matches(/^[A-Za-z0-9-_\s]+$/, 'Only alpha numerics are allowed for this field ')
        .required('User Name is required!'),
    mobile: Yup.string()
        .matches(/([1-9]{1}[0-9]{3})/, 'Enter Valid Mobile Number ')
        .required('Mobile Number is required!')
        .max(10, 'Mobile Number should contain 10 digits')
        .min(10, 'Mobile Number should contain 10 digits'),
    email: Yup.string()
        .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/, 'Invalid Email')
        .required('Email Id is required'),
});
var REJECT_ASSIGNING_SCHEMA = Yup.object().shape({
    reject_comment: Yup.string()
        .trim()
        .min(5, 'Minimum 5 characters required!')
        .max(200, 'Maximum 200 characters allowed!')
        .required('Please provide reason for rejection'),
});
var changePassword = Yup.object().shape({
    currentPassword: Yup.string()
        .min(8, 'Minimum 8 characters required')
        .max(64, 'Maximum 64 characters allowed')
        .required('Current password required'),
    newPassword: Yup.string()
        .min(8, 'Minimum 8 characters required')
        .max(64, 'Maximum 64 characters allowed')
        .required('New password cannot be blank')
        .matches(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,64}$/, 'Sorry! Password should contain atleast one digit [0-9], one lowercase character [a-z], one uppercase character [A-Z], one special character, at least 8 characters and a maximum of 64 characters and should not contain white spaces.'),
    confirmPassword: Yup.string()
        .min(8, 'Minimum 8 characters required')
        .max(64, 'Maximum 64 characters allowed')
        .required('Confirm password cannot be blank')
        .oneOf([Yup.ref('newPassword')], 'Confirm password and New password must be same.'),
});
var FORGOT_PASSWORD_SCHEMA = Yup.object().shape({
    email: Yup.string()
        .email('Invalid Email')
        .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/, 'Invalid Email')
        .required('Please enter Email'),
});
var RESET_PASSWORD_SCHEMA = Yup.object().shape({
    newPassword: Yup.string()
        .min(8, 'Minimum 8 characters required')
        .max(64, 'Maximum 64 characters allowed')
        .required('New password cannot be blank')
        .matches(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,64}$/, 'Sorry! Password should contain atleast one digit [0-9], one lowercase character [a-z], one uppercase character [A-Z], one special character, at least 8 characters and a maximum of 64 characters and should not contain white spaces.'),
    confirmPassword: Yup.string()
        .min(8, 'Minimum 8 characters required')
        .max(64, 'Maximum 64 characters allowed')
        .required('Confirm password cannot be blank')
        .oneOf([Yup.ref('newPassword')], 'Confirm password and New password must be same.'),
});
var SWAP_FE_SCHEMA = Yup.object().shape({
    labcode: Yup.object().nullable().required('Lab Code is required'),
    from_fe_id: Yup.object().nullable().required('Please select From Fe'),
    to_fe_id: Yup.object().nullable().required('Please select To Fe'),
});
exports.ROUTE_PLANNER_SCHEAMA = Yup.object().shape({
    lab_code: Yup.object().nullable(true).required('Lab Code is required'),
    route_assignment_type: Yup.object()
        .nullable(true)
        .required('Route Type is required'),
    speed: Yup.string()
        .nullable(true)
        .required('Speed is required')
        .min(2, 'Speed should be greater than or equal to 20 km')
        .max(3, 'Speed should be less than 1000 km')
        .matches(/^[0-9]+$/, 'Only Numbers Allowed')
        .test('Speed', 'Speed should be greater than or equal to 20 km', function (value) {
        var isError = Number(value) < 20 ? false : true;
        return isError;
    }),
    radius: Yup.string()
        .nullable(true)
        .required('Radius is required')
        .max(3, 'Radius should be less than 1000 km')
        .matches(/^[0-9]+$/, 'Only Numbers Allowed')
        .test({
        name: 'dynamic',
        exclusive: false,
        params: {},
        message: 'Radius should be greater than or equal to 3 km',
        test: function (value) {
            var routeType = this.parent.route_assignment_type &&
                this.parent.route_assignment_type.value == 1 &&
                3;
            var isError = Number(value) < routeType ? false : true;
            return isError;
        },
    })
        .test({
        name: 'static',
        exclusive: false,
        params: {},
        message: 'Radius should be greater than or equal to 10 km',
        test: function (value) {
            var routeType = this.parent.route_assignment_type &&
                this.parent.route_assignment_type.value == 2 &&
                10;
            var isError = Number(value) < routeType ? false : true;
            return isError;
        },
    }),
    cc_buffer_time: Yup.string()
        .nullable(true)
        .required('CC Buffer Time is required')
        .min(1, 'CC Buffer Time should be greater than or equal to 2 minutes')
        .matches(/^[0-9]+$/, 'Only Numbers Allowed')
        .test('CC Buffer Time', 'CC Buffer Time should be greater than or  equal to 2 minutes', function (value) {
        var isError = Number(value) < 2 ? false : true;
        return isError;
    })
        .test('CC Buffer Time', 'CC Buffer Time should be less than or equal to 60 minutes', function (value) {
        var isError = Number(value) > 60 ? false : true;
        return isError;
    }),
    psc_buffer_time: Yup.string()
        .nullable(true)
        .required('PSC Buffer Time is required')
        .min(1, 'PSC Buffer Time should be greater than or equal to 3 minutes')
        .matches(/^[0-9]+$/, 'Only Numbers Allowed')
        .test('PSC Buffer Time', 'PSC Buffer Time should be greater than or equal to 3 minutes', function (value) {
        var isError = Number(value) < 3 ? false : true;
        return isError;
    })
        .test('PSC Buffer Time', 'PSC Buffer Time should be less than or equal to 60 minutes', function (value) {
        var isError = Number(value) > 60 ? false : true;
        return isError;
    }),
    fpsc_buffer_time: Yup.string()
        .nullable(true)
        .required('FPSC Buffer Time is required')
        .min(1, 'FPSC Buffer Time should be greater than or equal to 3 minutes')
        .matches(/^[0-9]+$/, 'Only Numbers Allowed')
        .test('FPSC Buffer Time', 'FPSC Buffer Time should be greater than or equal to 3 minutes', function (value) {
        var isError = Number(value) < 3 ? false : true;
        return isError;
    })
        .test('FPSC Buffer Time', 'FPSC Buffer Time should be less than or equal to 60 minutes', function (value) {
        var isError = Number(value) > 60 ? false : true;
        return isError;
    }),
    pup_buffer_time: Yup.string()
        .nullable(true)
        .required('PUP Buffer Time is required')
        .min(1, 'PUP Buffer Time should be greater than or equal to 3 minutes')
        .matches(/^[0-9]+$/, 'Only Numbers Allowed')
        .test('PUP Buffer Time', 'PUP Buffer Time should be greater than or equal to 3 minutes', function (value) {
        var isError = Number(value) < 3 ? false : true;
        return isError;
    })
        .test('PUP Buffer Time', 'PUP Buffer Time should be less than or equal to 60 minutes', function (value) {
        var isError = Number(value) > 60 ? false : true;
        return isError;
    }),
    hub_buffer_time: Yup.string()
        .nullable(true)
        .required('HUB Buffer Time is required')
        .min(1, 'HUB Buffer Time should be greater than or equal to 5 minutes')
        .matches(/^[0-9]+$/, 'Only Numbers Allowed')
        .test('HUB Buffer Time', 'HUB Buffer Time should be greater than or equal to 5 minutes', function (value) {
        var isError = Number(value) < 5 ? false : true;
        return isError;
    })
        .test('HUB Buffer Time', 'HUB Buffer Time should be less than than or equal to 60 minutes', function (value) {
        var isError = Number(value) > 60 ? false : true;
        return isError;
    }),
    lab_buffer_time: Yup.string()
        .nullable(true)
        .required('LAB Buffer Time is required')
        .min(1, 'LAB Buffer Time should be greater than or equal to 5 minutes')
        .matches(/^[0-9]+$/, 'Only Numbers Allowed')
        .test('LAB Buffer Time', 'LAB Buffer Time should be greater than or equal to 5 minutes', function (value) {
        var isError = Number(value) < 5 ? false : true;
        return isError;
    })
        .test('LAB Buffer Time', 'LAB Buffer Time should be less than or equal to 60 minutes', function (value) {
        var isError = Number(value) > 60 ? false : true;
        return isError;
    }),
});
var WeekDaysSchema = Yup.object().shape({
    selectedWeeks: Yup.array().min(1, 'Please select atleast one day'),
});
var HUB_Master_Schema = Yup.object().shape({
    hubCode: Yup.string()
        .nullable(true)
        .required('Please Enter Hub Code')
        .matches(/^[A-Za-z0-9]+$/, 'Only alpha numerics are allowed for this field ')
        .min(1, 'Minimum 1 characters required')
        .max(50, 'Maximum 50 characters allowed'),
    hubName: Yup.string()
        .nullable(true)
        .required('Please Enter Hub Name')
        .min(1, 'Minimum 1 characters required')
        .max(100, 'Maximum 100 characters allowed'),
    invoice_code: Yup.string()
        .nullable(true)
        .required('Please Enter Invoice Code')
        .matches(/^[A-Za-z0-9]+$/, 'Only alpha numerics are allowed for this field ')
        .min(1, 'Minimum 1 characters required')
        .max(50, 'Maximum 50 characters allowed'),
    address: Yup.string()
        .nullable(true)
        .min(1, 'Minimum 1 characters required')
        .max(250, 'Maximum 250 characters allowed'),
    hubType: Yup.object().nullable(true).required('Please Select Hub Type'),
    labcode: Yup.object().nullable(true).required('Please Select Lab Code'),
    base_location_address: Yup.string()
        .required('Please select Base Location Address')
        .nullable(true),
    geofence_radius: Yup.number().test({
        name: 'geofence_radius',
        exclusive: false,
        params: {},
        message: 'Geofence radius should be greater than or equal to 50',
        test: function (value) {
            var isEnabled = this.parent.geofence_enabled;
            var isError = isEnabled && Number(value) < 50 ? false : true;
            return isError;
        },
    }),
});
var Notification_management_schema = Yup.object().shape({
    notificationCategory: Yup.object()
        .nullable(true)
        .required('Notification Category is required'),
    userGroups: Yup.array()
        .nullable()
        .min(1, 'UserGroup is required'),
    labCode: Yup.array().nullable(),
    occurrence: Yup.object().nullable(true).required('Occurrence is required'),
    startDate: Yup.string().when('occurrence', function (val) {
        console.log(val && val.value, 'kjhgf');
        if (val && val.value === 'specific_date_time') {
            return Yup.string().notRequired();
        }
        else {
            return Yup.string()
                .nullable(true)
                .test('startDate', 'Invalid Date', function (value) {
                return value === 'Invalid Date' ? false : true;
            })
                .test('startDate', 'Invalid Date', function (value) {
                return value && value.includes(' GMT+0553') ? false : true;
            })
                .required('Start Date is  required')
                .test({
                name: 'comparision',
                exclusive: false,
                params: {},
                message: 'Start Date should be greater than Current Date',
                test: function (value) {
                    return (0, dateUtils_1.differenceBetweenDatesMinValidation)(new Date(), new Date(this.parent.startDate), 'days', -1);
                },
            });
        }
    }),
    endDate: Yup.string().when('occurrence', function (val) {
        if (val && val.value === 'specific_date_time') {
            return Yup.string().notRequired();
        }
        else {
            return Yup.string()
                .nullable(true)
                .test('endDate', 'Invalid Date', function (value) {
                return value === 'Invalid Date' ? false : true;
            })
                .test('endDate', 'Invalid Date', function (value) {
                return value && value.includes(' GMT+0553') ? false : true;
            })
                .required('End Date is  required')
                .test({
                name: 'comparision',
                exclusive: false,
                params: {},
                message: 'Start Date should be selected first',
                test: function (value) {
                    return this.parent.startDate ? true : false;
                },
            })
                .test({
                name: 'comparision',
                exclusive: false,
                params: {},
                message: 'End Date should be greater than Start Date',
                test: function (value) {
                    return (0, dateUtils_1.differenceBetweenDatesMinValidation)(new Date(this.parent.startDate), new Date(value), 'days', -1);
                },
            });
        }
    }),
    executionDate: Yup.string().when('occurrence', function (val) {
        console.log(val && val.value, 'kjhgf');
        if ((val && val.value === 'specific_date_time') ||
            (val && val.value === 'Monthly') ||
            (val && val.value === 'yearly')) {
            return Yup.string().required('Execution Date is  required');
        }
        else {
            return Yup.string()
                .nullable(true)
                .notRequired();
        }
    }),
    notificationTitle: Yup.string()
        .nullable(true)
        .required('Notification Title is required'),
    notificationMessage: Yup.string()
        .nullable(true)
        .required('Notification Message is required'),
});
var CreateRegionSchema = Yup.object().shape({
    RegionName: Yup.string()
        .strict()
        .trim('Starting and trailing spaces are not allowed')
        .min(4, 'Minimum 4 character(s) required!')
        .max(25, 'Maximum 25 characters allowed!')
        .required('Please provide region name'),
});
var SHIPMENT_ROUTE_Schema = Yup.object().shape({
    rlab: Yup.object().nullable(true).required('Please Select R Lab'),
    plab: Yup.object()
        .nullable(true)
        .required('Please Select P Lab'),
});
var FE_ExitAmounSchemat = Yup.object().shape({
    reason: Yup.object().nullable(true).required('Reason is required'),
    comments: Yup.string()
        .trim()
        .min(2, 'Minimum 2 characters required')
        .max(1024, 'Maximum 1024 characters allowed')
        .test({
        name: 'comparision',
        exclusive: false,
        params: {},
        message: 'Please provide other comments',
        test: function (value) {
            var _a, _b;
            if ((_b = (_a = this.parent) === null || _a === void 0 ? void 0 : _a.reason) === null || _b === void 0 ? void 0 : _b.label) {
                if (this.parent.reason.label == "Others" && (value == "" || value == undefined)) {
                    return false;
                }
                else {
                    return true;
                }
            }
            else {
                return true;
            }
        },
    }),
    inventory_amount: Yup.number()
        .required('Please give certain  amount')
        .lessThan(10000),
    fromDate: Yup.string()
        .nullable(true)
        .required('This field is required')
        .test('fromDate', 'Invalid Date', function (value) {
        return value === 'Invalid Date' ? false : true;
    })
        .test('fromDate', 'Invalid Date', function (value) {
        return value && value.includes(' GMT+0553') ? false : true;
    })
});
var FE_ExitSchema = Yup.object().shape({
    reason: Yup.object().nullable(true).required('Reason is required'),
    comments: Yup.string()
        .trim()
        .min(2, 'Minimum 2 characters required')
        .max(1024, 'Maximum 1024 characters allowed')
        .test({
        name: 'comparision',
        exclusive: false,
        params: {},
        message: 'Please provide other comments',
        test: function (value) {
            var _a, _b;
            if ((_b = (_a = this.parent) === null || _a === void 0 ? void 0 : _a.reason) === null || _b === void 0 ? void 0 : _b.label) {
                if (this.parent.reason.label == "Others" && (value == "" || value == undefined)) {
                    return false;
                }
                else {
                    return true;
                }
            }
            else {
                return true;
            }
        },
    }),
    fromDate: Yup.string()
        .nullable(true)
        .required('This field is required')
        .test('fromDate', 'Invalid Date', function (value) {
        return value === 'Invalid Date' ? false : true;
    })
        .test('fromDate', 'Invalid Date', function (value) {
        return value && value.includes(' GMT+0553') ? false : true;
    })
});
exports.REJECT_ROUTE_SCHEMA = Yup.object().shape({
    comments: Yup.string()
        .trim()
        .min(2, 'Minimum 2 characters required')
        .max(1024, 'Maximum 1024 characters allowed')
        .required('Please provide comments'),
});
exports.schemas = {
    SignupSchemaForOTP: SignupSchemaForOTP,
    SignupSchema: SignupSchema,
    SignupSchemaForLoginByPassword1: SignupSchemaForLoginByPassword1,
    FE_ContactDetailsSchema: FE_ContactDetailsSchema,
    FE_PersonalDetails_Schema: FE_PersonalDetails_Schema,
    FE_RegistrationSchema: FE_RegistrationSchema,
    FE_DrivingLicenceDetails_Schema: FE_DrivingLicenceDetails_Schema,
    FE_EmployementDetailsSchema: FE_EmployementDetailsSchema,
    FE_BankDetails_Schema: FE_BankDetails_Schema,
    FE_ExitProcessSchema: FE_ExitProcessSchema,
    FE_Apply_Leave_Schema: FE_Apply_Leave_Schema,
    FE_ApproveLeavesSchema: FE_ApproveLeavesSchema,
    FE_InventoryDetailsSchema: exports.FE_InventoryDetailsSchema,
    USER_ProfileDetails_Schema: USER_ProfileDetails_Schema,
    USER_ContactDetailsSchema: USER_ContactDetailsSchema,
    FE_ExitProcessSchemaAmount: FE_ExitProcessSchemaAmount,
    CreateRoleSchema: CreateRoleSchema,
    FE_RejectSchema: FE_RejectSchema,
    FE_SlotBooking_Schema: FE_SlotBooking_Schema,
    AUDIT_CREATE_SCHEMA: AUDIT_CREATE_SCHEMA,
    LEVEL_ONE_REJECT_SCHEMA: LEVEL_ONE_REJECT_SCHEMA,
    Update_Deductions_Schema: Update_Deductions_Schema,
    ASSIGN_USERS_TO_AUDIT_SCHEMA: ASSIGN_USERS_TO_AUDIT_SCHEMA,
    LEVEL_ONE_APPRVAL_SCHEMA: LEVEL_ONE_APPRVAL_SCHEMA,
    VENDOR_PROFILE_DETAILS_SCHEMA: VENDOR_PROFILE_DETAILS_SCHEMA,
    USER_TERRITORY_DETAILS: USER_TERRITORY_DETAILS,
    VENDOR_CONTRACT_DETAILS: VENDOR_CONTRACT_DETAILS,
    VENDOR_ContactDetailsSchema: VENDOR_ContactDetailsSchema,
    InactiveFeSchema: InactiveFeSchema,
    PRECIOUS_SAMPLE_MASTER: PRECIOUS_SAMPLE_MASTER,
    ADD_VISITS: ADD_VISITS,
    Apply_leave_schema: Apply_leave_schema,
    FORGOT_PASSWORD_SCHEMA: FORGOT_PASSWORD_SCHEMA,
    RESET_PASSWORD_SCHEMA: RESET_PASSWORD_SCHEMA,
    Add_cre_schema: Add_cre_schema,
    OnDemand_Request_Schema: OnDemand_Request_Schema,
    USEREDITSCHEMA: USEREDITSCHEMA,
    REJECT_ASSIGNING_SCHEMA: REJECT_ASSIGNING_SCHEMA,
    CC_RoasterManagement_Schema: CC_RoasterManagement_Schema,
    changePassword: changePassword,
    SWAP_FE_SCHEMA: SWAP_FE_SCHEMA,
    WeekDaysSchema: WeekDaysSchema,
    Add_User_Logistics_schema: Add_User_Logistics_schema,
    HUB_Master_Schema: HUB_Master_Schema,
    Lab_RoasterManagement_Schema: Lab_RoasterManagement_Schema,
    Notification_management_schema: Notification_management_schema,
    CreateRegionSchema: CreateRegionSchema,
    CC_RoasterManagementWarehuse_Schema: CC_RoasterManagementWarehuse_Schema,
    SHIPMENT_ROUTE_Schema: SHIPMENT_ROUTE_Schema,
    FE_ExitAmounSchemat: FE_ExitAmounSchemat,
    FE_ExitSchema: FE_ExitSchema
};
