"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var formik_1 = require("formik");
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var api_service_1 = require("../../../api/api_service");
var button_component_1 = require("../../../common/buttons/button_component");
var multi_selector_1 = require("../../../common/checkbox/multi_selector");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var search_component_1 = require("../../../common/search/search_component");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var text_field_with_datepicker_1 = require("../../../common/text_filed/text_field_with_datepicker");
var appConstants_1 = require("../../../constants/appConstants");
var schema_1 = require("../../../constants/schema");
var table_data_1 = require("../../../constants/table_data");
var generic_api_calls_1 = require("../../generic_api_calls");
var AssignUsersToAudit1 = function (_a) {
    var initialvalues = _a.initialvalues;
    var _b = React.useState([]), cityList = _b[0], setCityList = _b[1];
    var _c = React.useState([]), ccList = _c[0], setCcList = _c[1];
    var _d = React.useState([]), feList = _d[0], setFeList = _d[1];
    var _e = React.useState(undefined), draftAuditList = _e[0], setDraftAuditList = _e[1];
    var _f = React.useState(), errorMessage = _f[0], setErrorMessage = _f[1];
    var _g = React.useState(), selectAuditRow = _g[0], setSelectedRow = _g[1];
    var _h = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList; }), stateList = _h.stateList, countryList = _h.countryList;
    var targetAuditeetypeList = React.useState(appConstants_1.targetAuditeeeDropdownList)[0];
    var targetAuditorsList = React.useState(appConstants_1.targetAuditorsDropdownList)[0];
    var navigate = (0, react_router_dom_1.useNavigate)();
    var saveSelectedRow = function (val) {
        setSelectedRow(val);
    };
    var formSubmitHnadler = function (values) {
        console.log('values inside formSubmitHnadler:', values);
        if (typeof selectAuditRow === 'undefined') {
            react_hot_toast_1.default.error('Please Select One Audit From Available Audits');
        }
        var payload = {};
        payload['audit_id'] = selectAuditRow.audit_id;
        payload['start_date'] = values.startDate
            ? moment(values.startDate).format('YYYY-MM-DD')
            : '';
        payload['end_date'] = values.endDate
            ? moment(values.endDate).format('YYYY-MM-DD')
            : '';
        var countries = [];
        values.country.map(function (country) {
            return country.label ? countries.push(country.label) : '';
        });
        payload['countries'] = countries;
        var states = [];
        values.state.map(function (state) {
            return state.label ? states.push(state.label) : '';
        });
        payload['states'] = states;
        var cities = [];
        values.city.map(function (city) {
            return city.label ? cities.push(city.label) : '';
        });
        payload['cities'] = cities;
        var audit_mapping_api = {};
        audit_mapping_api['auditor_type'] = values.selectedAuditors.label;
        audit_mapping_api['auditee_type'] = values.selectedAuditee.label;
        var auditees = [];
        values.auditees.map(function (auditee) {
            return auditee.short_code ? auditees.push(auditee.short_code) : '';
        });
        audit_mapping_api['auditees'] = auditees;
        var auditors = [];
        values.auditors.map(function (auditor) {
            return auditor.FE_id ? auditors.push(auditor.FE_id) : '';
        });
        audit_mapping_api['auditors'] = auditors;
        payload['audit_mappings'] = [audit_mapping_api];
        console.log('payload:', payload);
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.ASSIGN_AUDIT,
            payLoad: payload,
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                react_hot_toast_1.default.success(res.data.message);
                navigate(-1);
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.errors);
        });
    };
    var getDropdownList = function (match) {
        var matchString = match ? match.label : '';
        switch (matchString) {
            case 'CC':
                return ccList;
            case 'FE':
                return feList;
            default:
                return [];
        }
    };
    var searchHandler = function (e) {
        var payload = {};
        payload['name'] = e.target.value;
        var status = [];
        status.push(0);
        payload['status'] = status;
        getAuditList(payload);
    };
    var getAuditList = function (payload) {
        (0, generic_api_calls_1.fetchDraftedAuditList)(payload)
            .then(function (res) {
            setDraftAuditList(res);
            setErrorMessage("");
        })
            .catch(function (err) {
            console.log('error in the error', err);
            setErrorMessage(err);
            setDraftAuditList([]);
        });
    };
    React.useEffect(function () {
        var payload = {};
        var status = [];
        status.push(0);
        payload['status'] = status;
        getAuditList(payload);
    }, []);
    var cancelButtonHandler = function () {
        navigate(-1);
    };
    return (React.createElement("div", { className: "form-filed-wrapper p-24 assign-user-page" },
        React.createElement("div", { className: "form-heaidng" },
            React.createElement("h2", { className: "F-18" }, "Assign Audit"),
            React.createElement("p", { className: "sub-heaidng text-medium" }, "Please assign audit to Targeted Users here")),
        React.createElement("div", { className: "create-audit-form p-0" },
            React.createElement(formik_1.Formik, { enableReinitialize: false, validateOnBlur: false, initialValues: __assign({}, initialvalues), validationSchema: schema_1.schemas.ASSIGN_USERS_TO_AUDIT_SCHEMA, onSubmit: function (values, _a) {
                    var resetForm = _a.resetForm;
                    formSubmitHnadler(values);
                } }, function (_a) {
                var _b;
                var errors = _a.errors, setErrors = _a.setErrors, touched = _a.touched, values = _a.values, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched;
                return (React.createElement(formik_1.Form, null,
                    console.log('values,errors', values, errors),
                    React.createElement(material_1.Grid, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                        React.createElement(material_1.Grid, { item: true, xs: 5 },
                            React.createElement(text_field_with_datepicker_1.default, { required: true, name: 'startDate', placeholder: "Start Date", label: "Start Date", isPastDisable: true, isFutureDisable: false, value: values['startDate'], type: "date", onChange: function (val) {
                                    setFieldValue('startDate', val);
                                    setFieldTouched('startDate', true);
                                } }),
                            errors['startDate'] && touched['startDate'] ? (React.createElement("div", { className: "error" }, errors['startDate'])) : null),
                        React.createElement(material_1.Grid, { item: true, xs: 5 },
                            React.createElement(text_field_with_datepicker_1.default, { required: true, name: 'endDate', placeholder: "End Date", label: "End Date", isPastDisable: true, isFutureDisable: false, value: values['endDate'], onChange: function (val) {
                                    setFieldValue('endDate', val);
                                    setFieldTouched('endDate', true);
                                } }),
                            errors['endDate'] && touched['endDate'] ? (React.createElement("div", { className: "error" }, errors['endDate'])) : null),
                        React.createElement(material_1.Grid, { item: true, xs: 5 },
                            React.createElement(multi_selector_1.default, { key: 'country', requiredClass: 'validate-filed', size: "dropdown-lg", dropDownList: countryList, label: 'Country', value: values['country'], onChangeHnadlre: function (val) {
                                    console.log('selected value:', val);
                                    setFieldValue('city', []);
                                    setFieldValue('selectedAuditee', null);
                                    setFieldValue('selectedAuditors', null);
                                    setFieldValue('auditees', []);
                                    setFieldValue('auditors', []);
                                    setFieldValue('state', []);
                                    setFieldValue('country', val);
                                    setFieldTouched('country', true);
                                } }),
                            errors['country'] && touched['country'] ? (React.createElement("div", { className: "error" }, errors['country'])) : null),
                        React.createElement(material_1.Grid, { item: true, xs: 5 },
                            React.createElement(multi_selector_1.default, { size: "dropdown-lg", requiredClass: 'validate-filed', key: 'state', dropDownList: stateList, label: 'State', value: values['state'], onChangeHnadlre: function (val) {
                                    console.log('selected value:', val);
                                    if (val.length > 0) {
                                        var stateid = val.map(function (singleval) { return singleval.id; });
                                        (0, generic_api_calls_1.fetchCitiesUnderStates)(stateid).then(function (res) {
                                            setCityList(res);
                                        });
                                    }
                                    else {
                                        setCityList([]);
                                    }
                                    setFieldValue('city', []);
                                    setFieldValue('selectedAuditee', null);
                                    setFieldValue('selectedAuditors', null);
                                    setFieldValue('auditees', []);
                                    setFieldValue('auditors', []);
                                    setFieldValue('state', val);
                                    setFieldTouched('state', true);
                                } }),
                            errors['state'] && touched['state'] ? (React.createElement("div", { className: "error" }, errors['state'])) : null),
                        React.createElement(material_1.Grid, { item: true, xs: 5 },
                            React.createElement(multi_selector_1.default, { noOptions: 'No cities found', hasSelectAll: true, requiredClass: 'validate-filed', size: "dropdown-lg", key: 'city list', label: "City", dropDownList: cityList, value: values['city'], onChangeHnadlre: function (val) {
                                    console.log('selected value:', val);
                                    setFieldValue('selectedAuditee', null);
                                    setFieldValue('selectedAuditors', null);
                                    setFieldValue('auditees', []);
                                    setFieldValue('auditors', []);
                                    setFieldTouched('city', true);
                                    setFieldValue('city', val);
                                    setCcList([]);
                                    setFeList([]);
                                } }),
                            errors['city'] && touched['city'] ? (React.createElement("div", { className: "error" }, errors['city'])) : null),
                        React.createElement(material_1.Grid, { item: true, container: true, xs: 10, columnSpacing: 3 },
                            React.createElement(material_1.Grid, { item: true, xs: 6 },
                                React.createElement("div", { className: "gray-badge-lg" },
                                    React.createElement("p", null, "Targeted Auditees"))),
                            React.createElement(material_1.Grid, { item: true, xs: 6 },
                                React.createElement("div", { className: "gray-badge-lg" },
                                    React.createElement("p", null, "Targeted Auditors")))),
                        React.createElement(material_1.Grid, { item: true, xs: 5 },
                            React.createElement(drop_down_component_1.default, { required: true, name: "auditiesList", key: targetAuditeetypeList.toString(), placeholder: "Select Auditees", value: values['selectedAuditee'], dropDownList: targetAuditeetypeList, isDisabled: values.city.length <= 0, onChange: function (val) {
                                    console.log('selected value:', val);
                                    setFieldValue('selectedAuditee', val);
                                    setFieldTouched('selectedAuditee', true);
                                } }),
                            errors['selectedAuditee'] && touched['selectedAuditee'] ? (React.createElement("div", { className: "error" }, errors['selectedAuditee'])) : null),
                        React.createElement(material_1.Grid, { item: true, xs: 5 },
                            React.createElement(drop_down_component_1.default, { name: "auditorsList", required: true, key: (_b = values.selectedAuditee) === null || _b === void 0 ? void 0 : _b.label, placeholder: "Select Auditors", value: values['selectedAuditors'], isDisabled: values.city.length <= 0, dropDownList: targetAuditorsList, onChange: function (val) {
                                    console.log('selected value:', val);
                                    var targetAudience = [
                                        val.label,
                                        values.selectedAuditee.label,
                                    ];
                                    var citiesIDs = values.city.map(function (singleval) {
                                        return {
                                            id: singleval.id,
                                            city: singleval.label.split(',')[0],
                                        };
                                    });
                                    (0, generic_api_calls_1.fetchAudienceUnderCities)(targetAudience, citiesIDs)
                                        .then(function (res) {
                                        setCcList(res);
                                    })
                                        .catch(function (err) {
                                        console.log('error in the error', err);
                                        setCcList([]);
                                    });
                                    setFieldValue('selectedAuditors', val);
                                    setFieldTouched('selectedAuditors', true);
                                } }),
                            errors['selectedAuditors'] &&
                                touched['selectedAuditors'] ? (React.createElement("div", { className: "error" }, errors['selectedAuditors'])) : null),
                        React.createElement(material_1.Grid, { item: true, xs: 5 },
                            React.createElement(multi_selector_1.default, { size: "dropdown-lg", requiredClass: 'validate-filed', key: 'Auditees', label: "Auditee('s)", dropDownList: getDropdownList(values.selectedAuditee), value: values['auditees'], onChangeHnadlre: function (val) {
                                    console.log('selected value:', val);
                                    var selectedAudities = val.map(function (signleVal) { return signleVal.short_code; });
                                    setFieldValue('auditors', []);
                                    (0, generic_api_calls_1.fetchAuditorsUnderCC)(selectedAudities)
                                        .then(function (res) {
                                        setFeList(res);
                                    })
                                        .catch(function (err) {
                                        console.log('error in the error', err);
                                        setFeList([]);
                                    });
                                    setFieldValue('auditees', val);
                                    setFieldTouched('auditees', true);
                                } }),
                            errors['auditees'] && touched['auditees'] ? (React.createElement("div", { className: "error" }, errors['auditees'])) : null),
                        React.createElement(material_1.Grid, { item: true, xs: 5 },
                            React.createElement(multi_selector_1.default, { size: "dropdown-lg", requiredClass: 'validate-filed', key: 'Auditors', label: "Auditor('s)", value: values['auditors'], dropDownList: getDropdownList(values.selectedAuditors), onChangeHnadlre: function (val) {
                                    console.log('selected value:', val);
                                    setFieldValue('auditors', val);
                                    setFieldTouched('auditors', true);
                                } }),
                            errors['auditors'] && touched['auditors'] ? (React.createElement("div", { className: "error" }, errors['auditors'])) : null),
                        React.createElement(material_1.Grid, { item: true, xs: 10 },
                            React.createElement("div", { className: "assign-audit-search-wrapper" },
                                React.createElement("p", { className: "text-normal" }, "Available Audits"),
                                React.createElement(search_component_1.default, { placeholder: "Search Audit", searchHandler: searchHandler }))),
                        React.createElement(material_1.Grid, { item: true, xs: 10 },
                            React.createElement("div", { className: "checkbox-as-radio-btns assign-user-table nodata-alignment" },
                                React.createElement(AGgrid_table_component_1.default, { mutilselector: "single", isSortable: true, columnDefs: table_data_1.default.ASSIGN_USERSTO_AUDIT, rows: draftAuditList, errorMessage: errorMessage, onSelectionChange: saveSelectedRow }))),
                        React.createElement(material_1.Grid, { item: true, xs: 10 },
                            React.createElement("div", { className: "btns-wrapper form-btn-wrapper" },
                                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: cancelButtonHandler }),
                                React.createElement(button_component_1.default, { buttonName: "Publish", color: "yellow", type: "submit" }))))));
            }))));
};
exports.default = React.memo(AssignUsersToAudit1);
