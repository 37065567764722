"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var React = require("react");
var ProfileGrid = function (_a) {
    var lableName = _a.lableName, value = _a.value, className = _a.className;
    var type = 'string';
    return (React.createElement(Grid_1.default, { item: true, xs: 6 },
        React.createElement("div", { className: "user-profile-wrapper" },
            React.createElement("small", { className: "user-label" }, lableName),
            React.createElement("p", { className: "user-value ".concat(className ? className : '') }, typeof value === type
                ? value.charAt(0).toUpperCase() + value.slice(1)
                : value))));
};
exports.default = ProfileGrid;
