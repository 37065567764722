"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.displayAuditType = exports.convertAuditTypes = exports.AUDIT_TYPE = exports.auditGenerate = exports.AuditAnswerDetails = exports.convertAuditListFromRes = exports.pendingAuditDetailsInfo = exports.auditDetailsInfo = void 0;
var api_service_1 = require("../../../api/api_service");
var auditDetailsInfo = function (id) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: api_service_1.api_service.api_urls.AUDIT_DETAILS_INFO,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: {
                survey_id: id,
            },
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                console.log(res.data.data, 'response');
                return resolve(res.data.data);
            }
            throw new Error('Unable to fetch audit list!');
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.auditDetailsInfo = auditDetailsInfo;
var pendingAuditDetailsInfo = function (payLoad) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.AUDIT_PENDING_DETAILS,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: __assign({}, payLoad),
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                console.log(res, 'response');
                return resolve(res.data);
            }
            throw new Error('Unable to fetch audit list!');
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.pendingAuditDetailsInfo = pendingAuditDetailsInfo;
var convertAuditListFromRes = function (response) {
    var auditListRows = [];
    var data = response.result;
    data === null || data === void 0 ? void 0 : data.map(function (audit) { return __awaiter(void 0, void 0, void 0, function () {
        var auditRow;
        return __generator(this, function (_a) {
            auditRow = {
                audit_id: audit._id ? audit._id : '---',
                name: audit.name ? audit.name : '---',
                audit_type: audit.hasOwnProperty('audit_type') && audit.audit_type != null || audit.audit_type != '' || audit.audit_type != undefined ? (0, exports.displayAuditType)(audit.audit_type) : '---',
                audities: audit.assignments
                    ? audit.assignments.target_audience ? audit.assignments.target_audience : []
                    : [],
                status: audit.status.toString(),
                questions_count: audit.questions_count
                    ? audit.questions_count
                    : '---',
                start_date: audit.assignments
                    ? audit.assignments.start_date
                    : null,
                end_date: audit.assignments ? audit.assignments.end_date : null,
                created_by: audit.created_by ? audit.created_by : '---',
                conclusion: '---',
                is_favourite: audit.is_favourite,
                users: audit.assignments
                    ? audit.assignments.audit_mappings ? [audit.assignments.audit_mappings[0].auditee_type] : []
                    : [],
            };
            auditListRows.push(auditRow);
            return [2];
        });
    }); });
    return auditListRows;
};
exports.convertAuditListFromRes = convertAuditListFromRes;
var AuditAnswerDetails = function (payLoad) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: api_service_1.api_service.api_urls.AUDIT_ANSWER_DETAILS,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payLoad
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                console.log(res, 'response');
                return resolve(res.data);
            }
            throw new Error('Unable to fetch audit list!');
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.AuditAnswerDetails = AuditAnswerDetails;
var auditGenerate = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.AUDIT_GENERATE,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res);
            }
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.auditGenerate = auditGenerate;
exports.AUDIT_TYPE = {
    NORAM_AUDIT: 'NORMAL AUDIT',
    PHLEBO_AUDIT: 'PHLEBO AUDIT'
};
var convertAuditTypes = function (audit_type) {
    if (audit_type == 1) {
        return { label: exports.AUDIT_TYPE.PHLEBO_AUDIT, value: 1, id: 1 };
    }
    else {
        return { label: exports.AUDIT_TYPE.NORAM_AUDIT, value: 0, id: 0 };
    }
};
exports.convertAuditTypes = convertAuditTypes;
var displayAuditType = function (audit_type) {
    if (audit_type == 1) {
        return exports.AUDIT_TYPE.PHLEBO_AUDIT;
    }
    else if (audit_type == 0) {
        return exports.AUDIT_TYPE.NORAM_AUDIT;
    }
    else {
        return '';
    }
};
exports.displayAuditType = displayAuditType;
